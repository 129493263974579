import { styled } from "@mui/material";

const CardContent = styled("div")(({ theme }) => {
	return {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "stretch",
		gap: theme.spacing(2),
		padding: theme.spacing(2),
	};
});

export default CardContent;
