import { Button } from "@components/Button";
import CardContent from "@components/CardContent";
import GenericMedia from "@components/GenericMedia";
import LightboxDialog from "@components/Lightbox";
import SwiperContainer from "@components/Swiper/SwiperContainer";
import SwiperSlideZoomContainer from "@components/Swiper/SwiperSlideZoomContainer";
import SwiperZoomTarget from "@components/Swiper/SwiperZoomTarget";
import TapToUnblurButtonRebrand from "@components/TapToUnblurButtonRebrand";
import useIdify from "@hooks/useIdify";
import useIsBlurred from "@hooks/useIsBlurred";
import useNavModalState from "@hooks/useNavModalState";
import { InTheMomentStatus } from "@lib/models";
import { track } from "@lib/util/trackAnalytics";
import getClickedMediaInfo from "@lib/util/trackingHelpers/getClickedMediaInfo";
import { Box, Typography } from "@mui/material";
import palette from "@styles/palette";
import { useTranslation } from "next-i18next";
import { useCallback, useEffect, useState } from "react";

type ContentProps = {
	in_the_moment_status: InTheMomentStatus;
};

export default function Content({ in_the_moment_status }: ContentProps) {
	const { t } = useTranslation("common");

	const idify = useIdify();
	const lightboxId = idify(`lightbox-${in_the_moment_status.uuid}`);
	const { open: lightboxOpen, setOpen: setLightboxOpen } = useNavModalState(`lightbox-${lightboxId}`);

	const onOpen = useCallback(() => {
		track("media_tapped_to_enlarge", getClickedMediaInfo(in_the_moment_status, "feed"));
		setLightboxOpen(true);
	}, [setLightboxOpen, in_the_moment_status]);

	const hasMedia = in_the_moment_status.blurhash != null;

	const blurEnabled = useIsBlurred();
	const [isBlurred, setIsBlurred] = useState(blurEnabled);
	const showUnblur = isBlurred && hasMedia;

	useEffect(() => {
		setIsBlurred(blurEnabled);
	}, [blurEnabled]);

	return (
		<CardContent sx={{ gap: 1.5, p: 0, pl: 7.5 }}>
			{in_the_moment_status.message === "" ? null : (
				<Typography fontSize="14px" color={palette.onSurface}>
					{in_the_moment_status.message}
				</Typography>
			)}
			{hasMedia ? (
				<LightboxDialog
					open={lightboxOpen}
					setOpen={setLightboxOpen}
					renderContent={(isLightbox: boolean) => {
						const genericMedia = (
							<GenericMedia
								alt={
									in_the_moment_status.video != null
										? t("in_the_moment.current_video_alt")
										: t("in_the_moment.current_photo_alt")
								}
								media={{
									blurhash: in_the_moment_status.blurhash,
									videoUrl: showUnblur ? null : in_the_moment_status.video,
									imageUrl: showUnblur ? null : in_the_moment_status.full,
								}}
							/>
						);
						const tapToUnblurButton = showUnblur ? (
							<Box
								sx={{
									position: "absolute",
									top: "50%",
									left: "50%",
									transform: "translate(-50%, -50%)",
									backgroundColor: palette.surfaceContainer,
								}}
							>
								<TapToUnblurButtonRebrand
									onClick={() => {
										setIsBlurred(false);
									}}
								/>
							</Box>
						) : null;
						const tapToReblurButton = showUnblur ? null : (
							<Box
								sx={(theme) => ({
									position: "absolute",
									left: theme.spacing(2),
									bottom: theme.spacing(2),
									backgroundColor: palette.surfaceContainer,
								})}
							>
								<Button
									variant="secondary ghost"
									size="xxs"
									onClick={() => {
										setIsBlurred(true);
									}}
								>
									{t("profile.reblur_button_label")}
								</Button>
							</Box>
						);

						if (isLightbox) {
							return (
								<Box
									sx={{
										position: "relative",
										zIndex: 0,
										height: "100%",
										"& swiper-container": {
											height: "100%",
										},
									}}
								>
									<SwiperContainer zoom={true}>
										<SwiperSlideZoomContainer>
											<SwiperZoomTarget>{genericMedia}</SwiperZoomTarget>
										</SwiperSlideZoomContainer>
									</SwiperContainer>
									{tapToUnblurButton}
								</Box>
							);
						}

						return (
							<Box sx={{ position: "relative" }}>
								<Box
									component="button"
									type="button"
									onClick={showUnblur ? undefined : onOpen}
									tabIndex={showUnblur ? -1 : undefined}
									sx={{
										display: "block",
										width: "100%",
										border: "none",
										background: "none",
										padding: 0,
									}}
								>
									{genericMedia}
								</Box>
								{blurEnabled ? (showUnblur ? tapToUnblurButton : tapToReblurButton) : null}
							</Box>
						);
					}}
				/>
			) : null}
		</CardContent>
	);
}
