import useRebrandGate from "@lib/feature/landing/hooks/useRebrandGate";
import { BadgeProps, styled } from "@mui/material";
import palette from "@styles/palette";

const NoteBadgeOriginal = styled("span")(({ theme }) => ({
	position: "absolute",
	top: "0%",
	left: "0%",
	bottom: "0%",
	margin: "auto 0px",
	transform: "translateX(-50%)",
	height: theme.spacing(2.5),
	padding: theme.spacing(0, 0.75),
	borderRadius: theme.spacing(1.25),
	backgroundColor: theme.palette.common.black,
	display: "flex",
	alignItems: "center",
	fontSize: ".75rem",
	color: theme.palette.text.primary,
}));

const NoteBadgeRebrand = styled("span")(({ theme }) => ({
	position: "absolute",
	top: "0%",
	left: "0%",
	bottom: "0%",
	margin: "auto 0px",
	transform: "translateX(-50%)",
	height: theme.spacing(1.75),
	padding: "2px 6px 3px",
	backgroundColor: palette.surfaceContainer,
	display: "flex",
	alignItems: "center",
	fontSize: ".625rem",
	color: palette.onSurface,
	fontFamily: "var(--heading-font)",
}));

export default function NoteBadge(props: BadgeProps) {
	const rebrandEnabled = useRebrandGate();
	if (rebrandEnabled) {
		return <NoteBadgeRebrand {...props} />;
	}
	return <NoteBadgeOriginal {...props} />;
}
