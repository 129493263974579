import { ParsedUrlQuery } from "querystring";

export default function routerQueryToParams(query: ParsedUrlQuery): URLSearchParams {
	const params = new URLSearchParams();
	Object.entries(query).forEach(([paramName, values]) => {
		if (Array.isArray(values)) {
			values.forEach((value) => {
				params.append(paramName, value);
			});
		} else if (values != null) {
			params.append(paramName, values);
		}
	});
	return params;
}
