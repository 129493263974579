import { styled } from "@mui/material/styles";
import { useTranslation } from "next-i18next";
import { useTimeout } from "react-timers-hooks";

const maxRecordTime = 10; // record time in seconds

// how the SVG animation works:
// https://css-tricks.com/svg-line-animation-works/
// it's a dashed stroke with the dash size the length
// of the circumference

const Button = styled("button", {
	shouldForwardProp: (prop) => prop !== "recording",
})<{ recording: boolean }>(({ theme, recording }) => ({
	display: "block",
	width: theme.spacing(8.6),
	height: theme.spacing(8.6),
	border: "none",
	padding: "0px",
	position: "relative",
	background: "none",
	cursor: "pointer",
	"&::after": {
		position: "absolute",
		content: '" "',
		display: "block",
		top: theme.spacing(1),
		right: theme.spacing(1),
		bottom: theme.spacing(1),
		left: theme.spacing(1),
		borderRadius: "50%",
		backgroundColor: recording ? theme.palette.secondary.main : theme.palette.common.white,
	},
	"& circle.lower": {
		stroke: theme.palette.common.white,
	},
	"& .upper": {
		stroke: theme.palette.secondary.main,
		animation: recording ? `${maxRecordTime}s linear 0s backwards recordspin` : "none",
		strokeDashoffset: 289.03,
	},
	"@keyframes recordspin": {
		from: {
			strokeDashoffset: 289.03,
		},
		to: {
			strokeDashoffset: 0,
		},
	},
}));

type RecordButtonProps = {
	recording: boolean;
	onRecord: (recording: boolean) => void;
};

export default function RecordButton({ recording, onRecord }: RecordButtonProps) {
	const { t } = useTranslation("common");

	useTimeout(
		() => {
			onRecord(false);
		},
		recording ? maxRecordTime * 1000 : null,
	);

	return (
		<Button
			type="button"
			recording={recording}
			onClick={() => {
				onRecord(!recording);
			}}
		>
			<svg id="svg_css_ex1" viewBox="0 0 100 100" aria-hidden={true} xmlns="http://www.w3.org/2000/svg">
				<circle className="lower" fill="none" strokeWidth="7" cx="50" cy="50" r="46" />
				<path
					fill="none"
					className="upper"
					strokeWidth="7"
					strokeDasharray="289.03"
					d="M 50 4
						A 46 46 0 0 1 50 96
						A 46 46 0 0 1 50 4"
				/>
			</svg>
			<span className="sr-only">{recording ? t("video_capture.stop") : t("video_capture.record")}</span>
		</Button>
	);
}
