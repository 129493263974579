import { MaybeFieldErrors } from "../formErrors";

const validationExpression = /^[^,;@ \r\n]+@[^,@; \r\n]+\.[^.,@; \r\n]+$/;

function emailValidator(value: string | undefined): MaybeFieldErrors {
	if (typeof value !== "string" || value.length === 0 || validationExpression.test(value)) {
		return undefined;
	}
	return [{ t: "email.invalid_error" }];
}

export default emailValidator;
