import { ReactNode } from "react";

type InlineListItemProps = {
	label: string;
	value: ReactNode;
};

export default function InlineListItem({ label, value }: InlineListItemProps) {
	return (
		<>
			<span className="sr-only">{label}</span> {value}
		</>
	);
}
