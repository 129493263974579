import useBrazilReleaseGate from "@lib/feature/landing/hooks/useBrazilReleaseGate";
import useRebrandGate from "@lib/feature/landing/hooks/useRebrandGate";
import { MapboxOptions } from "mapbox-gl";
import { useMemo } from "react";
import { useConfig } from "statsig-react";
import mapConfig from "./mapConfig";

const brazilMapConfig = { ...mapConfig, style: process.env.NEXT_PUBLIC_MAPBOX_1_5_STYLE_LAYER };
const rebrandMapConfig = { ...mapConfig, style: process.env.NEXT_PUBLIC_MAPBOX_REBRAND_STYLE_LAYER };

export default function useMapboxConfig(): MapboxOptions & { zoom: number } {
	const statsigMapboxConfig = useConfig("mapbox_config").config.value ?? {};
	const useBrazilRelease = useBrazilReleaseGate();
	const rebrandEnabled = useRebrandGate();
	const gatedConfig = rebrandEnabled ? rebrandMapConfig : useBrazilRelease ? brazilMapConfig : mapConfig;
	const stringyConfig = JSON.stringify({ ...gatedConfig, ...statsigMapboxConfig });
	return useMemo(() => JSON.parse(stringyConfig), [stringyConfig]);
}
