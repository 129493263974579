import { AnyAccount, UserShort } from "@lib/models";
import { getOnlineStatus } from "../getOnlineStatus";

export type ProfileViewSource = "map" | "chat" | "inbox" | "feed";

type ProfileClickAccountInfoOptions = {
	source: ProfileViewSource;
	ownProfile?: boolean;
	zoomLevel?: number;
};

export default function profileClickAccountInfo(
	profile: AnyAccount | UserShort,
	options: ProfileClickAccountInfoOptions,
) {
	const inTheMomentStatus = profile.in_the_moment_status;

	const { source = "map", ownProfile = false, zoomLevel } = options;
	return {
		source,
		uuid: profile.uuid,
		profile_picture: profile.photos.length > 0,
		itm_status: inTheMomentStatus != null,
		...(inTheMomentStatus != null
			? {
					itm_photo: typeof inTheMomentStatus.full === "string",
					itm_text: inTheMomentStatus.message !== "",
				}
			: null),
		own_profile: ownProfile,
		is_match: profile.is_match,
		is_breadcrumb: profile.is_breadcrumb,
		online_status: getOnlineStatus(profile),
		has_pinned_photo: profile.photos.some((photo) => photo.pinned),
		zoom_level: zoomLevel,
	};
}
