import ChevronLeftIcon from "@components/icons/ChevronLeftIcon";
import { useTranslation } from "next-i18next";
import React from "react";
import { Button } from "./Button";

export default function BackButtonV2(props: Partial<React.ComponentProps<typeof Button>>) {
	const { t } = useTranslation("common");
	return (
		<Button icon variant="secondary ghost" aria-label={t("back_button")} {...props}>
			<ChevronLeftIcon />
		</Button>
	);
}
