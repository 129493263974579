import { SvgIcon, SvgIconProps } from "@mui/material";

export default function InformationSquareIcon(props: SvgIconProps) {
	return (
		<SvgIcon data-testid="BKDRInformationSquareIcon" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M22 2H2V22H22V2ZM13.01 6H11V8H13.01V6ZM13 18V10H11V18H13Z"
				fill="currentColor"
				data-testid="InformationSquareIcon"
			/>
		</SvgIcon>
	);
}
