import CloseButton from "@components/CloseButton";
import useIdify from "@hooks/useIdify";
import useUser from "@hooks/useUser";
import { track } from "@lib/util/trackAnalytics";
import {
	Box,
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { LngLat } from "mapbox-gl";
import { useTranslation } from "next-i18next";
import { useMemo, useRef, useState } from "react";
import { getMap } from "../map/mapbox";
import mapConfig from "../map/mapbox/mapConfig";
import popularCities from "./popularCities";

const MIN_DISTANCE_FROM_LOCATION = 100; //in meters

type CityBase = { cityKey: string; coordinates: LngLat };

type ExploreModeProps = {
	open: boolean;
	onClose: () => void;
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
	top: 0,
	pb: 0,
	"& .MuiModal-backdrop": {
		backdropFilter: "none",
		backgroundColor: "transparent",
	},
	"& .MuiDialog-container": {
		alignItems: "flex-start",
		marginTop: theme.spacing(7),
	},
}));

export default function ExploreMode({ open, onClose }: ExploreModeProps) {
	const { t } = useTranslation("common");
	const user = useUser();
	const selectRef = useRef<HTMLSelectElement>();
	const [selectValue, setSelectValue] = useState("my_neighborhood");
	const idify = useIdify();
	const selectId = idify("select-id");
	const labelId = idify("label-id");
	const mapbox = getMap();

	const cityMap = useMemo(() => {
		const localMap = new Map<string, CityBase>(popularCities.map((city) => [city.cityKey, city]));
		if (user?.lng != null && user?.lat != null) {
			localMap.set("my_neighborhood", { cityKey: "my_neighborhood", coordinates: new LngLat(user.lng, user.lat) });
		}
		return localMap;
	}, [user?.lng, user?.lat]);

	const cityCoords = cityMap.get(selectValue)?.coordinates;

	const locationMatchesOption =
		cityCoords == null || mapbox == null || cityCoords.distanceTo(mapbox.getCenter()) < MIN_DISTANCE_FROM_LOCATION;

	function onSubmit() {
		if (locationMatchesOption) {
			return;
		}
		onClose();
		track("explore_mode_go_button_tapped", { cityKey: selectValue });
		mapbox.setCenter(cityCoords);
		mapbox.setZoom(mapConfig.zoom ?? 12);
	}

	function onChange(e: SelectChangeEvent<string>) {
		setSelectValue(e.target.value);
		track("explore_mode_option_selected", { cityKey: e.target.value });
	}

	function handleClose() {
		track("explore_mode_exited");
		onClose();
	}

	return (
		<StyledDialog PaperProps={{ elevation: 0 }} open={open} onClose={handleClose}>
			<DialogTitle
				sx={{
					pb: 0,
				}}
			>
				{t("explore_popular_cities.explore_popular_cities_heading")}
			</DialogTitle>
			<CloseButton onClick={handleClose} />
			<DialogContent>
				<Box
					component="form"
					onSubmit={(e) => {
						e.preventDefault();
						onSubmit();
					}}
					sx={(theme) => ({ display: "flex", flexDirection: "column", gap: theme.spacing(2) })}
				>
					<FormControl fullWidth>
						<InputLabel id={labelId}>{t("explore_popular_cities.location_label")}</InputLabel>
						<Select
							ref={selectRef}
							labelId={labelId}
							id={selectId}
							label={t("explore_popular_cities.location_label")}
							onChange={onChange}
							value={selectValue}
						>
							<MenuItem key={"my_neighborhood"} value={"my_neighborhood"}>
								{t("explore_popular_cities.my_neighborhood")}
							</MenuItem>
							{popularCities
								.map((city) => ({ ...city, label: t(`explore_popular_cities.${city.cityKey}`) }))
								.sort((a, b) => a.label.localeCompare(b.label))
								.map(({ cityKey, label }) => (
									<MenuItem key={cityKey} value={cityKey}>
										{label}
									</MenuItem>
								))}
						</Select>
					</FormControl>
					<Button
						variant={locationMatchesOption ? "outlined" : "contained"}
						sx={{ width: "fit-content" }}
						disabled={locationMatchesOption}
						type="submit"
					>
						{t("explore_popular_cities.lets_go_button")}
					</Button>
				</Box>
			</DialogContent>
		</StyledDialog>
	);
}
