import { getOtherUser, otherUserPath } from "@api/users";
import useGlobalLoader from "@hooks/useGlobalLoader";
import { OtherUser } from "@lib/models";
import { ProfileViewSource } from "@lib/util/trackingHelpers/profileClickAccountInfo";
import useSWR from "swr";
import ProfileCard from "./ProfileCard";
import ProfileDialog, { RenderOpener } from "./ProfileDialog";

type FriendProfileCardProps = {
	uuid: string;
	hideChatButton: boolean;
	onBlock: (message: string) => void;
	onClose: () => void;
	source: ProfileViewSource;
};

function FriendProfileCard({ uuid, hideChatButton, onBlock, onClose, source }: FriendProfileCardProps) {
	const swr = useSWR(`${otherUserPath}/${uuid}`, getOtherUser);
	useGlobalLoader(swr.data == null, "friend profile card - getOtherUser");
	if (swr.data == null) {
		return null;
	}
	return (
		<ProfileCard
			profileType="friend"
			profile={swr.data}
			hideChatButton={hideChatButton}
			onBlock={onBlock}
			onClose={onClose}
			source={source}
		/>
	);
}

type FriendProfileDialogProps = {
	renderOpener: RenderOpener;
	profile?: OtherUser;
	uuid?: string;
	hideChatButton: boolean;
	onBlock: () => void;
	source: ProfileViewSource;
} & (
	| {
			uuid?: never;
			profile: OtherUser;
	  }
	| {
			uuid: string;
			profile?: never;
	  }
);

export default function FriendProfileDialog({
	hideChatButton,
	uuid,
	profile,
	renderOpener,
	onBlock,
	source,
}: FriendProfileDialogProps) {
	return (
		<ProfileDialog uuid={uuid ?? profile.uuid} renderOpener={renderOpener} onBlock={onBlock}>
			{({ onBlock, onClose }) => {
				if (typeof uuid === "string") {
					return (
						<FriendProfileCard
							{...{
								uuid,
								hideChatButton,
								onBlock,
								onClose,
								source,
							}}
						/>
					);
				}
				return (
					<ProfileCard
						profileType="friend"
						profile={profile}
						hideChatButton={hideChatButton}
						onBlock={onBlock}
						onClose={onClose}
						source={source}
					/>
				);
			}}
		</ProfileDialog>
	);
}
