import BoltIcon from "@components/icons/BoltIcon";
import useNavModalState from "@hooks/useNavModalState";
import useUser from "@hooks/useUser";
import InTheMoment from "@lib/feature/inTheMoment";
import useRebrandGate from "@lib/feature/landing/hooks/useRebrandGate";
import { track } from "@lib/util/trackAnalytics";
import MuiBoltIcon from "@mui/icons-material/Bolt";
import { IconButton, Theme, Tooltip } from "@mui/material";
import palette from "@styles/palette";
import { useTranslation } from "next-i18next";
import { useCallback } from "react";

type InTheMomentControlSource = "map" | "feed";
type InTheMomentControlType = {
	source: InTheMomentControlSource;
	animate?: boolean;
	useRebrand?: boolean;
};

function addPulseAnimation(theme: Theme) {
	return {
		"&::after": {
			position: "absolute",
			top: 0,
			left: 0,
			width: "100%",
			height: "100%",
			borderRadius: "50%",
			animation: "ripple 1.2s infinite ease-in-out",
			border: `2px solid ${theme.palette.secondary.main}`,
			boxShadow: `0px 0px 4px ${theme.palette.secondary.dark}, inset 0px 0px 4px ${theme.palette.secondary.light}`,
			content: '""',
			pointerEvents: "none",
		},
		"@keyframes ripple": {
			"0%": {
				transform: "scale(.8)",
				opacity: 1,
			},
			"100%": {
				transform: "scale(2.4)",
				opacity: 0,
			},
		},
	};
}

export default function InTheMomentControl({ source, animate = false, useRebrand = false }: InTheMomentControlType) {
	const { t } = useTranslation("common");
	const { open: dialogOpen, setOpen: setDialogOpen } = useNavModalState("itmc-modal");
	const ownUser = useUser();
	const hasITMC = ownUser?.in_the_moment_status != null;
	const rebrandEnabled = useRebrandGate();
	const labelTitle = hasITMC
		? rebrandEnabled
			? t("in_the_moment_view_button_screen_reader_label_rebrand")
			: t("in_the_moment_view_button_screenreader_label")
		: rebrandEnabled
			? t("in_the_moment_button_screen_reader_label_rebrand")
			: t("in_the_moment_button_screenreader_label");

	const onClick = useCallback(() => {
		track("itm_button_tapped", { source });
		setDialogOpen(true);
	}, [setDialogOpen, source]);

	return (
		<>
			<Tooltip arrow placement="left" title={labelTitle}>
				<IconButton
					onClick={onClick}
					color="secondary"
					sx={(theme) => ({
						width: theme.spacing(7),
						height: theme.spacing(7),
						color: "common.black",
						...(animate ? addPulseAnimation(theme) : {}),
						...(useRebrand
							? { borderRadius: 0, width: theme.spacing(8), height: theme.spacing(8), color: palette.onSurface }
							: {}),
					})}
					aria-label={labelTitle}
				>
					{useRebrand ? (
						<BoltIcon fontSize="large" sx={(theme) => ({ width: theme.spacing(4), height: theme.spacing(4) })} />
					) : (
						<MuiBoltIcon fontSize="medium" />
					)}
				</IconButton>
			</Tooltip>
			<InTheMoment
				open={dialogOpen}
				onClose={() => {
					setDialogOpen(false);
				}}
			/>
		</>
	);
}
