import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "next-i18next";

export default function RecentVideoButton({ videoUrl, onClick }: { videoUrl: string | null; onClick: () => void }) {
	const { t } = useTranslation("common");
	return (
		<Box
			sx={{
				ml: "20px",
				justifySelf: "flex-start",
			}}
		>
			{videoUrl == null ? (
				<Box sx={(theme) => ({ width: theme.spacing(9), height: "90px" })} />
			) : (
				<>
					<Button
						sx={(theme) => ({
							width: theme.spacing(9),
							height: theme.spacing(9),
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						})}
						onClick={onClick}
					>
						<Typography sx={{ fontSize: "12px" }}>{t("video_capture.recent")}</Typography>
						<Box
							data-testid="recent-video"
							component="video"
							src={videoUrl}
							disablePictureInPicture
							muted
							playsInline
							autoPlay
							loop
							sx={{
								aspectRatio: "1/1",
								width: "100%",
							}}
						/>
					</Button>
				</>
			)}
		</Box>
	);
}
