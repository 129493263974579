import { ProfilePictureKind, ProfilePicturesListType } from "@lib/util/getProfilePictures";
import { useEffect, useState } from "react";

export type PhotoRotatorState = {
	forwardMediaIndex: number;
	forwardMediaType: ProfilePictureKind;
	setForwardMediaIndex: (value: number | ((current: number) => number)) => void;
} | null;

export default function usePhotoRotatorState(images: ProfilePicturesListType) {
	const [forwardMediaIndex, setForwardMediaIndex] = useState(0);
	// if the images array updates with fewer items
	// then we need to make sure the current is equal to or less than the highest
	useEffect(() => {
		if (forwardMediaIndex >= images.length) {
			setForwardMediaIndex(Math.max(0, images.length - 1));
		}
	}, [forwardMediaIndex, images]);

	return images.length === 0
		? null
		: {
				forwardMediaIndex,
				setForwardMediaIndex,
				forwardMediaType: images[forwardMediaIndex].type,
			};
}
