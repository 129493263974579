import assertType from "@lib/util/assertType";
import { Consumer } from "@rails/actioncable";
import { ReactNode, createContext, useContext, useEffect, useState } from "react";

export const ActionCableContext = createContext<Consumer | null>(null);

export const ActionCableProvider = ({ children }: { children: ReactNode }) => {
	const [cable, setCable] = useState<Consumer | null>(null);

	const loadConsumer = async () => {
		const { createConsumer } = await import("@rails/actioncable");
		return createConsumer;
	};

	useEffect(() => {
		if (cable == undefined) {
			loadConsumer().then((createConsumer) => {
				setCable(createConsumer(assertType(process.env.NEXT_PUBLIC_ACTION_CABLE_URL)));
			});
		}
	}, [cable]);

	return <ActionCableContext.Provider value={cable}>{children}</ActionCableContext.Provider>;
};

export default function useActionCable() {
	return useContext(ActionCableContext);
}
