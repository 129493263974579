import useIdify from "@hooks/useIdify";
import SnackbarProvider from "@lib/feature/alert/SnackbarProvider";
import { ReportSource, UserShort } from "@lib/models";
import { Dialog } from "@mui/material";
import ReportFriendForm from "./ReportFriendForm";

type ReportFriendDialogProps = {
	open: boolean;
	onClose: () => void;
	profile: UserShort;
	onBlock: (successMessage: string) => void;
	source: ReportSource;
};

export default function ReportFriendDialog({ open, onClose, profile, onBlock, source }: ReportFriendDialogProps) {
	const idify = useIdify();

	return (
		<SnackbarProvider>
			<Dialog
				open={open}
				sx={{
					"& .MuiDialog-paper": {
						width: "100%",
					},
				}}
				aria-labelledby={idify("dialog_heading")}
			>
				<ReportFriendForm
					dialogHeadingId={idify("dialog_heading")}
					onClose={onClose}
					profile={profile}
					onBlock={onBlock}
					source={source}
				/>
			</Dialog>
		</SnackbarProvider>
	);
}
