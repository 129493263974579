import { accountRegisterPath } from "@api/account";
import { useUpdateAlert } from "@lib/feature/alert/notificationContext";
import { Severity } from "@lib/models";
import { useSWRConfig } from "swr";
import { EventBase } from "./realtimeTypes";
import useChannel from "./useChannel";

type RealtimeHandler = {
	event: string;
	handler: (data: EventBase) => void;
};

function makeHandler<EventData>(event: string, handler: (data: EventBase & EventData) => void): RealtimeHandler {
	return { event, handler } as unknown as { event: string; handler: (data: EventBase) => void };
}

function useWireHandlers(handlers: RealtimeHandler[]) {
	useChannel("ProfileChannel", (data: EventBase) => {
		handlers.forEach(({ event, handler }) => {
			if (event === data.event) {
				handler(data);
			}
		});
	});
}

// handlers don't need and shouldn't use useCallback or useEventCallback.
// the most recent array of handlers is always iterated and called for each WS event.

export default function useRealtimeHandlers() {
	// Add new handler dependencies here
	const { mutate } = useSWRConfig();
	const updateAlert = useUpdateAlert();

	// Add new handlers here.
	const handlers = [
		makeHandler("profile_updated", async () => {
			await mutate(accountRegisterPath);
		}),
		makeHandler<{ severity: Severity; message: string }>("message", async ({ severity, message }) => {
			updateAlert(message, severity);
		}),
	];

	useWireHandlers(handlers);
}
