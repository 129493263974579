import { UnitsOfMeasurement } from "@lib/models";
import reportError from "@lib/util/reportError";

export type RadiusUnitOption = { value: number; label: number };
export const imperialRadiusOptions = [
	{ value: 50, label: 30 },
	{ value: 40, label: 25 },
	{ value: 32, label: 20 },
	{ value: 24, label: 15 },
	{ value: 16, label: 10 },
	{ value: 8, label: 5 },
	{ value: 2, label: 1 },
];

export const metricRadiusOptions = [
	{ value: 50, label: 50 },
	{ value: 40, label: 40 },
	{ value: 30, label: 30 },
	{ value: 20, label: 20 },
	{ value: 10, label: 10 },
	{ value: 5, label: 5 },
	{ value: 1, label: 1 },
];

export class RadiusOptionError extends Error {}

function compareUnitOption(a: RadiusUnitOption, b: RadiusUnitOption) {
	return a.value - b.value;
}

export function findClosestUnitOption(radius: number, units: UnitsOfMeasurement): RadiusUnitOption {
	const newOption: RadiusUnitOption = { value: radius, label: radius };
	const options = units === "imperial" ? imperialRadiusOptions : metricRadiusOptions;

	if (Number.isNaN(radius)) {
		reportError(new RadiusOptionError("the radius was NaN"));
		return options[0];
	}

	const exactMatch = options.find(({ value }) => radius === value);
	if (exactMatch != null) {
		return exactMatch;
	}

	const newOptions = options.concat(newOption).sort(compareUnitOption);
	const valueIndex = newOptions.indexOf(newOption);
	const prevVal = newOptions[valueIndex - 1];
	const nextVal = newOptions[valueIndex + 1];

	if (prevVal == null) {
		return nextVal;
	}
	if (nextVal == null) {
		return prevVal;
	}

	return compareUnitOption(newOption, prevVal) < compareUnitOption(nextVal, newOption) ? prevVal : nextVal;
}
