import useNavModalState from "@hooks/useNavModalState";
import { Button, ButtonProps, IconButton } from "@mui/material";
import { ReactNode, useCallback } from "react";

type DialogButtonProps = {
	children?: ReactNode;
	iconButton?: boolean;
	renderDialog: (open: boolean, onClose: () => void) => ReactNode;
	renderButton?: (onOpen: () => void) => ReactNode;
	dialogRoutingName: string;
	onClose?: () => void;
} & ButtonProps;

export default function DialogButton({
	children,
	iconButton = false,
	renderDialog,
	dialogRoutingName,
	onClose,
	renderButton,
	...buttonProps
}: DialogButtonProps) {
	const { open, setOpen } = useNavModalState(dialogRoutingName);
	const onOpen = useCallback(() => {
		setOpen(true);
	}, [setOpen]);
	const handleClose = useCallback(() => {
		if (onClose != null) {
			onClose();
		}
		setOpen(false);
	}, [setOpen, onClose]);

	return (
		<>
			{(() => {
				if (renderButton != null) {
					return renderButton(onOpen);
				}
				if (iconButton) {
					return (
						<IconButton {...buttonProps} type="button" onClick={onOpen}>
							{children}
						</IconButton>
					);
				}
				return (
					<Button {...buttonProps} type="button" onClick={onOpen}>
						{children}
					</Button>
				);
			})()}
			{renderDialog(open, handleClose)}
		</>
	);
}
