import { locationAssign, locationReplace } from "@lib/util/locationWrapper";
import Link from "next/link";
import { useEffect, useRef } from "react";

type DirectProps = {
	href: string;
	replace?: boolean;
};

export default function Direct({ href, replace = false }: DirectProps) {
	const linkRef = useRef<HTMLAnchorElement>(null);
	useEffect(() => {
		if (typeof linkRef.current?.href === "string") {
			const fullPath = linkRef.current?.href;
			if (replace) {
				locationReplace(fullPath);
			}
			locationAssign(fullPath);
		}
	}, [replace]);
	return (
		<Link href={href} ref={linkRef} style={{ display: "none" }}>
			{href}
		</Link>
	);
}
