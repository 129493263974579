import { Typography } from "@mui/material";
import { useTranslation } from "next-i18next";

export default function InTheMomentContentMessage({ inTheMomentStatus }: { inTheMomentStatus: string }) {
	const { t } = useTranslation("common");

	return (
		<Typography
			variant="body2"
			sx={(theme) => ({
				overflowWrap: "anywhere",
				backgroundColor: theme.palette.common.black,
				color: theme.palette.common.white,
				borderRadius: theme.spacing(2),
				px: theme.spacing(2),
				py: theme.spacing(0.5),
				minHeight: theme.spacing(4),
				display: "flex",
				alignItems: "center",
			})}
		>
			<span className="sr-only">{t("profile.in_the_moment_status_screen_reader_label")}</span> {inTheMomentStatus}
		</Typography>
	);
}
