import { UserShort } from "@lib/models";
import { useTranslation } from "next-i18next";

export default function useDisplayName(profile: UserShort) {
	const { t } = useTranslation("common");

	if (profile.is_anon) {
		return t("anon_display_name");
	}
	if (profile.display_name.length === 0) {
		return t("no_username_placeholder");
	}
	return profile.display_name;
}
