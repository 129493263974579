import { Box } from "@mui/material";
import React from "react";

export default function SwiperSlideZoomContainer({ children, ...props }: React.JSX.IntrinsicElements["swiper-slide"]) {
	return (
		<swiper-slide {...props}>
			<Box className="swiper-zoom-container" sx={{ width: "100%", height: "100%" }}>
				{children}
			</Box>
		</swiper-slide>
	);
}
