import { createContext, useContext } from "react";
import { updateAlertType } from "./types";

function noProviderHandler() {
	throw new Error("notificationContext requests outside of a provider!");
}

const notificationContext = createContext<updateAlertType>(noProviderHandler);

export function useUpdateAlert() {
	return useContext(notificationContext);
}

export const NotificationProvider = notificationContext.Provider;
