import { AccountRegister } from "@lib/models";
import { AxiosError } from "axios";
import { get, post } from "../httpClient";
import { InputInvalid, ServerFormError } from "./commonErrors";
import { GenericSuccessResponse } from "./commonTypes";

export class EmailNotUnique extends Error {}
export class EmailNotValid extends Error {}
export class PasswordNotValid extends Error {}
export class CaptchaFailure extends Error {}

type SignupRequest = {
	login?: string;
	password?: string;
	birthdate: string;
	token: string;
	anon?: boolean;
};

export async function createAccount(body: SignupRequest) {
	return post<SignupRequest, GenericSuccessResponse>("/create-account", body).catch((error) => {
		if (error.response?.status === 422) {
			if (error.response?.data["field-error"]?.[0] === "recaptcha") {
				throw new CaptchaFailure(error.response?.data["field-error"]?.[1]);
			}
			if (error.response?.data["field-error"]?.[1] === "invalid login, already an account with this login") {
				throw new EmailNotUnique();
			}
			throw new InputInvalid();
		}
		throw error;
	});
}

export const accountRegisterPath = "/user/account_register";

export async function getAccountRegister() {
	return get<unknown, AccountRegister>(accountRegisterPath);
}

type ChatTokenResponse = {
	token: string;
};

export async function chatToken() {
	return await post<unknown, ChatTokenResponse>("/chat_tokens");
}

type EditPasswordRequest = { password: string; "new-password": string };

const editPasswordPath = "/change-password";
export async function editPassword(body: EditPasswordRequest): Promise<GenericSuccessResponse> {
	return post<EditPasswordRequest, GenericSuccessResponse>(editPasswordPath, body).catch((error) => {
		if (error instanceof AxiosError && error.response?.status === 422) {
			if (typeof error.response.data === "object") {
				throw new ServerFormError(error.response.data);
			}
		}
		throw error;
	});
}

type EditEmailRequest = { login: string; "login-confirm": string; password: string };

const editEmailPath = "/change-login";
export async function editEmail(body: EditEmailRequest): Promise<GenericSuccessResponse> {
	return post<EditEmailRequest, GenericSuccessResponse>(editEmailPath, body).catch((error) => {
		if (error.response?.status === 401) {
			if (error.response?.data["field-error"]?.[1] === "invalid password") {
				throw new PasswordNotValid();
			}
		}
		if (error.response?.status === 422) {
			if (
				error.response?.data["field-error"]?.[1] === "invalid login, already an account with this login" ||
				error.response?.data["field-error"]?.[1] === "invalid login, same as current login"
			) {
				throw new EmailNotUnique();
			}
		}
		throw error;
	});
}

type DeleteAccountBody = {
	password: string;
};

export const deleteAccountPath = "/close-account";

export async function deleteAccount(body: DeleteAccountBody): Promise<GenericSuccessResponse> {
	return post<DeleteAccountBody, GenericSuccessResponse>(deleteAccountPath, body).catch((error) => {
		if (error.response?.status === 401) {
			if (error.response?.data["field-error"]?.[1] === "invalid password") {
				throw new PasswordNotValid();
			}
		}
		throw error;
	});
}

type RegisterAnonRequest = {
	login: string;
	password: string;
};

export async function registerAnonAccount(body: RegisterAnonRequest) {
	return post<RegisterAnonRequest, GenericSuccessResponse>("/register-anon-account", body).catch((error) => {
		if (error.response?.status === 422) {
			if (error.response?.data["field-error"]?.[1] === "invalid login, already an account with this login") {
				throw new EmailNotUnique();
			} else if (error.response?.data["field-error"]?.[1] === "invalid email") {
				throw new EmailNotValid();
			}
			throw new InputInvalid();
		}
		throw error;
	});
}
