const MINUTES_IN_AN_HOUR = 60;
const ONE_DAY_IN_MINUTES = MINUTES_IN_AN_HOUR * 24;

export default function getTimeDistance(differenceInMinutes: number, locale: string) {
	if (differenceInMinutes == null) {
		return null;
	}
	const formatter = new Intl.RelativeTimeFormat(locale);
	if (differenceInMinutes >= ONE_DAY_IN_MINUTES) {
		return formatter.format(-Math.floor(differenceInMinutes / ONE_DAY_IN_MINUTES), "days");
	}
	if (differenceInMinutes >= MINUTES_IN_AN_HOUR) {
		return formatter.format(-Math.floor(differenceInMinutes / MINUTES_IN_AN_HOUR), "hours");
	}
	return new Intl.RelativeTimeFormat(locale, { style: "short" }).format(-differenceInMinutes, "minutes");
}
