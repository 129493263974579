import reportError from "./reportError";

function makeSessionStorageHelper<T>(sessionStorageKey: string) {
	const sessionStorageHelperFactoryObject = {
		set(value: T) {
			window.sessionStorage.setItem(sessionStorageKey, JSON.stringify(value));
		},
		get() {
			const stringyValue = window.sessionStorage.getItem(sessionStorageKey);
			if (stringyValue == null) {
				return null;
			}
			try {
				return JSON.parse(stringyValue);
			} catch {
				reportError(new Error(`sessionStorage parse error: ${sessionStorageKey}: '${stringyValue}'`));
				sessionStorageHelperFactoryObject.remove();
				return null;
			}
		},
		remove() {
			window.sessionStorage.removeItem(sessionStorageKey);
		},
	};
	return sessionStorageHelperFactoryObject;
}

export default makeSessionStorageHelper;
