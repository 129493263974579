import routerQueryToParams from "@lib/util/routerQueryToParams";
import { useEventCallback } from "@mui/material";
import { useRouter } from "next/router";

export default function useNavModalState(queryPropertyName: string) {
	const router = useRouter();
	const open = queryPropertyName in router.query && router.query[queryPropertyName] === "true";

	const setOpen = useEventCallback(function setOpen(nextState: boolean) {
		if (!open && nextState) {
			const params = routerQueryToParams(router.query);
			params.append(queryPropertyName, "true");
			router.push(`?${params}`, undefined, { shallow: true });
		} else if (open && !nextState) {
			router.back();
		}
	});

	return {
		open,
		setOpen,
	};
}
