import { accountRegisterPath, getAccountRegister } from "@api/account";
import { AccountRegister } from "@lib/models";
import reportError from "@lib/util/reportError";
import { ReactNode, useEffect, useRef, useState } from "react";
import { SWRConfig } from "swr";

export default function InitialDataLoader({ children }: { children: ReactNode }) {
	const initialized = useRef(false);
	const [initialData, setInitialData] = useState<AccountRegister | null>(null);

	useEffect(() => {
		if (initialized.current) {
			return;
		}
		initialized.current = true;
		getAccountRegister()
			.then((accountRegister) => {
				setInitialData(accountRegister);
			})
			.catch((error) => {
				reportError(error);
				return null;
			});
	}, []);

	if (initialData == null) {
		return null;
	}

	return (
		<SWRConfig
			value={{
				fallback: {
					[accountRegisterPath]: initialData,
				},
			}}
		>
			{children}
		</SWRConfig>
	);
}
