import { UserAccount } from "@lib/models";
import { useRouter } from "next/router";
import { useEffect } from "react";
import useAuth from "./useAuth";
import useUser from "./useUser";

export default function useAccount(redirectTo = "/login"): UserAccount | undefined {
	const { onRedirectToLogin } = useAuth();
	const user = useUser();
	const { push, asPath } = useRouter();

	useEffect(() => {
		if (user == null) {
			onRedirectToLogin(asPath);
			push(redirectTo);
		}
	}, [redirectTo, push, asPath, user, onRedirectToLogin]);

	return user;
}
