import { EmailNotUnique, EmailNotValid, registerAnonAccount } from "@api/account";
import CloseButton from "@components/CloseButton";
import EmailField from "@components/EmailField";
import PasswordField from "@components/PasswordField";
import { makeHandleFormSubmit } from "@lib/util/makeHandleFormSubmit";
import { track } from "@lib/util/trackAnalytics";
import { Box, Button, Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import { FORM_ERROR } from "final-form";
import createDecorator from "final-form-focus";
import { useTranslation } from "next-i18next";
import { ReactNode, useState } from "react";
import { Form } from "react-final-form";
import { useSWRConfig } from "swr";
import { ShinyAlert } from "../alert/ShinyAlert";
import useAlertState from "../alert/useAlertState";
import AnonFormLinks from "./AnonFormLinks";

type RegisterAnonFormValues = {
	email: string;
	password: string;
};

const focusOnError = createDecorator<RegisterAnonFormValues>();
const formDecorators = [focusOnError];

type RegisterAnonModalProps = {
	renderButton: (onOpen: () => void) => ReactNode;
};

export default function RegisterAnonModal({ renderButton }: RegisterAnonModalProps) {
	const { t } = useTranslation("common");

	const [open, setOpen] = useState(false);

	function onClose() {
		setOpen(false);
	}

	const { mutate } = useSWRConfig();
	const [alert, updateAlert] = useAlertState();

	const onSubmit = makeHandleFormSubmit<RegisterAnonFormValues>({
		submitter: async (values: RegisterAnonFormValues) => {
			await registerAnonAccount({
				login: values.email,
				password: values.password,
			});
			// mark all swr cached endpoints stale
			await mutate(() => true);
			track("anon_account_registered");
			onClose();
			return undefined;
		},
		updateAlert,
		successMessage: null,
		unknownErrorMessage: t("unknown_error"),
		errorHandlers: [
			{
				errorType: EmailNotUnique,
				result: t("email.not_unique"),
			},
			{
				errorType: EmailNotValid,
				result: t("email.invalid_error"),
			},
		],
	});

	return (
		<>
			{renderButton(() => {
				setOpen(true);
			})}
			<Dialog open={open} onClose={onClose} PaperProps={{ elevation: 0 }}>
				<CloseButton onClick={onClose} />
				<DialogTitle>{t("anon_mode.account_registration_header")}</DialogTitle>
				<DialogContent>
					<Typography fontSize={14}>{t("anon_mode.account_registration_body")}</Typography>
					<Form
						onSubmit={onSubmit}
						decorators={formDecorators}
						validate={(values) => (values.email == null ? { [FORM_ERROR]: true } : undefined)}
						render={({ handleSubmit, hasValidationErrors, submitting }) => {
							return (
								<Box
									component="form"
									onSubmit={handleSubmit}
									noValidate={true}
									sx={(theme) => ({
										my: "auto",
										display: "flex",
										flexDirection: "column",
										alignItems: "stretch",
										overflow: "visible",
										gap: theme.spacing(2),
										padding: theme.spacing(2, 0),
									})}
								>
									<EmailField />
									<PasswordField />
									{alert == null ? null : (
										<ShinyAlert
											key={alert.key}
											severity={alert.severity}
											sx={{ margin: (theme) => theme.spacing(2, 0), backgroundColor: "background.default" }}
											variant="outlined"
											role="alert"
										>
											{alert.children}
										</ShinyAlert>
									)}
									<Button
										type="submit"
										disabled={submitting}
										fullWidth
										variant={hasValidationErrors ? "outlined" : "contained"}
									>
										{t("register_account_button")}
									</Button>
									<AnonFormLinks forgotPasswordLink />
								</Box>
							);
						}}
					/>
				</DialogContent>
			</Dialog>
		</>
	);
}
