import { Channel, Event } from "stream-chat";

export default function shouldDeleteUpdateCount(event: Event, channel: Channel) {
	if (event.type !== "message.deleted" || event.message == null) {
		return false;
	}
	const messageCreateDate = event.message.created_at == null ? null : event.message.created_at;
	const lastReadDate = channel.lastRead() == null ? null : channel.lastRead()?.toISOString();
	if (
		messageCreateDate == null ||
		lastReadDate == null ||
		messageCreateDate < lastReadDate ||
		channel.countUnread() === 0
	) {
		return false;
	}
	return true;
}
