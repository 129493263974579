import { curryN } from "ramda";
import { Observable } from "rxjs";

// delay = --
// -a-b-----c--d------e- input
// -a-b--b--c--d--d--de- output

interface RepeatAfter {
	<T>(delay: number, observable: Observable<T>): Observable<T>;
	<T>(delay: number): (observable: Observable<T>) => Observable<T>;
}

const repeatAfter: RepeatAfter = curryN(2, function repeatAfter<T>(delay: number, observable: Observable<T>) {
	return new Observable<T>((subscriber) => {
		let timeoutId: NodeJS.Timeout | undefined;
		const subscription = observable.subscribe({
			next(value) {
				clearTimeout(timeoutId);
				function repeat() {
					timeoutId = setTimeout(() => {
						subscriber.next(value);
						repeat();
					}, delay);
				}
				repeat();
				subscriber.next(value);
			},
			error(err) {
				subscriber.error(err);
			},
			complete() {
				subscriber.complete();
			},
		});

		return () => {
			subscription.unsubscribe();
			clearTimeout(timeoutId);
		};
	});
});
export default repeatAfter;
