import { Box, BoxProps } from "@mui/material";

const MediaButton: typeof Box = function MediaButton(props: BoxProps) {
	const disabled = "disabled" in props ? props.disabled : false;
	return (
		<Box
			{...props}
			sx={[
				(theme) => ({
					aspectRatio: "1/1",
					borderRadius: "4px",
					border: "1px dashed gray",
					borderColor: disabled ? theme.palette.text.disabled : "#C4C4C4",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					position: "relative",
					overflow: "hidden",
					"&:hover": {
						borderColor: disabled ? null : theme.palette.text.primary,
					},
					"&:focus-within": {
						borderWidth: "2px",
						borderColor: disabled ? null : theme.palette.primary.main,
					},
				}),
				...(Array.isArray(props.sx) ? props.sx : props.sx ? [props.sx] : []),
			]}
		/>
	);
};

export default MediaButton;
