import useObjectUrl from "@hooks/useObjectUrl";
import assertType from "@lib/util/assertType";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import { Box, IconButton } from "@mui/material";
import { useTranslation } from "next-i18next";
import { forwardRef, useMemo, useState } from "react";
import Capture from "./Capture";
import Previewer from "./Previewer";

type RecorderProps = {
	onClose: () => void;
	onFinish: (file: Blob) => void;
};

type States =
	| {
			view: "capture";
			blob: null;
	  }
	| {
			view: "capture";
			blob: Blob;
	  }
	| {
			view: "preview";
			blob: Blob;
	  };

export default forwardRef(function Recorder({ onClose, onFinish }: RecorderProps, ref) {
	const { t } = useTranslation("common");
	const [{ view, blob: mediaBlob }, setState] = useState<States>({ view: "capture", blob: null });
	const [onCaptureFinish, onTryAgain, onUsePrevious] = useMemo(() => {
		return [
			function onCaptureFinish(blob: Blob) {
				setState({ view: "preview", blob });
			},
			function onTryAgain() {
				setState(({ blob }) => ({ view: "capture", blob }));
			},
			function onUsePrevious() {
				setState((state) => (state.blob == null ? state : { view: "preview", blob: state.blob }));
			},
		];
	}, []);

	const videoUrl = useObjectUrl(mediaBlob);

	return (
		<Box
			ref={ref}
			tabIndex={-1}
			role="dialog"
			sx={{
				backgroundColor: (theme) => theme.palette.background.default,
				height: "100%",
				width: "100%",
				maxHeight: "100%",
				pt: "env(safe-area-inset-top, 0)",
				position: "relative",
			}}
		>
			<IconButton
				onClick={() => {
					if (view === "capture") {
						onClose();
					} else {
						onTryAgain();
					}
				}}
				aria-label={view === "capture" ? t("close_button") : t("back_button")}
				size="large"
				sx={{
					fontSize: "3.5rem",
					zIndex: "10",
				}}
			>
				<ChevronLeft fontSize="inherit" />
			</IconButton>
			{view === "capture" ? (
				<Capture
					onRecorded={(blob) => {
						onCaptureFinish(blob);
					}}
					videoUrl={videoUrl}
					onUsePrevious={onUsePrevious}
				/>
			) : null}
			{view === "preview" && videoUrl != null ? (
				<Previewer
					videoUrl={videoUrl}
					onFinish={() => {
						// mediaBlob can't be null when videoUrl is not null
						onFinish(assertType(mediaBlob));
						onClose();
					}}
				/>
			) : null}
		</Box>
	);
});
