import { useEffect } from "react";
import { RenderableAlertType, updateAlertType } from "./types";

const successDismissTime = 3000;

export default function useAutoDismissValid([alert, updateAlert]: [RenderableAlertType | null, updateAlertType]): [
	RenderableAlertType | null,
	updateAlertType,
] {
	useEffect(() => {
		if (alert?.severity === "success" || alert?.severity === "info") {
			const timeoutId = setTimeout(() => {
				updateAlert();
			}, successDismissTime);
			return () => {
				clearTimeout(timeoutId);
			};
		}
	}, [alert, updateAlert]);
	return [alert, updateAlert];
}
