import useUser from "@hooks/useUser";
import assertType from "@lib/util/assertType";
import { ReactNode, useMemo } from "react";
import { StatsigProvider } from "statsig-react";
import useFeatureFlags from "./featureFlags/useFeatureFlags";

export default function StatsigWrapper({ children }: { children: ReactNode }) {
	const user = useUser();
	const flags = useFeatureFlags();

	const statsigUser = useMemo(
		() => ({ userID: user?.uuid, country: flags?.derived_country_code ?? undefined }),
		[user?.uuid, flags?.derived_country_code],
	);

	return (
		<StatsigProvider
			sdkKey={assertType(process.env.NEXT_PUBLIC_STATSIG_SDK_KEY)}
			user={statsigUser}
			waitForInitialization={true}
			options={{
				api: process.env.NEXT_PUBLIC_STATSIG_PROXY,
				environment: { tier: process.env.NEXT_PUBLIC_ENVIRONMENT },
			}}
		>
			{children}
		</StatsigProvider>
	);
}
