import { useEffect, useState } from "react";

export const imageLoadTransitionTime = 500;

export default function useImageLoadTransition(url: string | null): {
	hideBlurhash: boolean;
	imageProps: {
		style: { opacity: number };
		onLoad: () => void;
		src: string | undefined;
	};
} {
	const [loadedUrl, setLoadedUrl] = useState<string | null>(null);
	const [animatedTo, setAnimatedTo] = useState(loadedUrl);

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setAnimatedTo(loadedUrl);
		}, imageLoadTransitionTime);
		return () => {
			clearTimeout(timeoutId);
		};
	}, [loadedUrl]);

	return {
		hideBlurhash: animatedTo === url && typeof url === "string",
		imageProps: {
			style: {
				opacity: typeof url === "string" && loadedUrl === url ? 100 : 0,
			},
			onLoad() {
				setLoadedUrl(url);
			},
			src: url ?? undefined,
		},
	};
}
