import { enableTrackingPersistence, hasOptedIn } from "@lib/util/trackAnalytics";
import { Backdrop, Drawer } from "@mui/material";
import { memo, useEffect, useState } from "react";
import { useGate } from "statsig-react";
import DrawerContent from "./DrawerContent";

const WAIT_TO_SHOW_DURATION = 5000; // 5 seconds

export default memo(function PrivacyCheck() {
	const needsConsentNotice = useGate("use_privacy_consent").value;
	const [show, setShow] = useState(false);
	const [drawerOpen, setDrawerOpen] = useState(false);

	useEffect(() => {
		if (!hasOptedIn()) {
			setShow(true);
		}
	}, []);

	function onClose() {
		setShow(false);
		enableTrackingPersistence({ optedIn: true });
	}

	useEffect(() => {
		if (!needsConsentNotice) {
			enableTrackingPersistence({ optedIn: false });
		}
	}, [needsConsentNotice]);

	useEffect(() => {
		if (show && !drawerOpen) {
			const timeout = setTimeout(() => {
				setDrawerOpen(true);
			}, WAIT_TO_SHOW_DURATION);
			return () => {
				clearTimeout(timeout);
			};
		}
	}, [show, drawerOpen]);

	if (!needsConsentNotice) {
		return null;
	}

	return (
		<Backdrop
			open={show}
			invisible={!drawerOpen}
			sx={{
				zIndex: "modal",
			}}
			unmountOnExit
			onClick={() => {
				if (!drawerOpen) {
					setDrawerOpen(true);
				}
			}}
		>
			<Drawer
				hideBackdrop
				anchor="bottom"
				open={drawerOpen}
				PaperProps={{
					sx: (theme) => ({
						backgroundColor: theme.palette.grey[300],
						color: theme.palette.common.black,
					}),
				}}
			>
				<DrawerContent
					onClose={() => {
						setDrawerOpen(false);
						onClose();
					}}
				/>
			</Drawer>
		</Backdrop>
	);
});
