import { PresenceType } from "@lib/models";
import { Theme } from "@mui/material";

const colorMap: Record<PresenceType, (theme: Theme) => string> = {
	online: (theme: Theme) => theme.palette.success.main,
	idle: (theme: Theme) => theme.palette.warning.main,
	away: (theme: Theme) => theme.palette.info.main,
	offline: (theme: Theme) => theme.palette.common.white,
};

export default function getStatusColor(onlineStatus: PresenceType): (theme: Theme) => string {
	return colorMap[onlineStatus];
}
