import FieldSubmitError from "@components/FieldSubmitError";
import useIdify from "@hooks/useIdify";
import errorHelper from "@lib/util/errorHelper";
import { FormControl, FormHelperText, InputLabel, OutlinedInput } from "@mui/material";
import { useTranslation } from "next-i18next";
import { Field } from "react-final-form";

export default function StatusMessageField() {
	const { t } = useTranslation("common");
	const idify = useIdify();
	return (
		<Field
			name="message"
			type="text"
			render={({ input, meta }) => {
				const { error, helperText } = errorHelper(t, t("in_the_moment.status_message_input_helper"), meta);
				const inputId = idify("status_input");
				const helperId = idify("status_helper");
				const submitErrorId = idify("status_submit_error");
				return (
					<FormControl disabled={meta.submitting} variant="outlined" fullWidth error={error}>
						<InputLabel variant="outlined" htmlFor={inputId}>
							{t("in_the_moment.status_message_input_label")}
						</InputLabel>
						<OutlinedInput
							autoComplete="off"
							id={inputId}
							aria-describedby={`${helperId} ${submitErrorId}`}
							label={t("in_the_moment.status_message_input_label")}
							error={error}
							disabled={meta.submitting}
							inputProps={{
								maxLength: "200",
							}}
							{...input}
						/>
						<FormHelperText id={helperId}>{helperText}</FormHelperText>
						<FieldSubmitError id={submitErrorId} meta={meta} />
					</FormControl>
				);
			}}
		/>
	);
}
