export function createObjectURL(file: File | Blob) {
	if (process.env.NODE_ENV === "test") {
		return "name" in file ? file.name : `mock-object-url-${Math.random()}`;
	}
	return window.URL.createObjectURL(file);
}

export function revokeObjectURL(url: string) {
	if (process.env.NODE_ENV === "test") {
		return;
	}
	return window.URL.revokeObjectURL(url);
}
