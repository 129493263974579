import useRebrandGate from "@lib/feature/landing/hooks/useRebrandGate";
import { Box, useTheme } from "@mui/material";
import { useTranslation } from "next-i18next";

export default function BKDRHeaderLogo() {
	const { t } = useTranslation("common");
	const useRebrand = useRebrandGate();
	const theme = useTheme();
	const imgAttributes = useRebrand
		? {
				src: "/imgs/BKDR_.svg",
				"data-testid": "rebrand",
				height: theme.spacing(5.5),
				sx: { ml: "-1px" },
			}
		: {
				src: "/imgs/bkdr-header-logo.svg",
				"data-testid": "default",
				height: theme.spacing(4),
			};
	// eslint-disable-next-line @next/next/no-img-element
	return <Box component="img" {...imgAttributes} alt={t("brand_name")} />;
}
