import { getLocaleFont, initialFontHeadStyleProps } from "@lib/fonts";
import { DefaultLocaleCode } from "@lib/models";
import { useEffect, useState } from "react";

export default function useFontHeadStyleProps(locale: DefaultLocaleCode | undefined) {
	const [styles, setStyles] = useState(initialFontHeadStyleProps);
	useEffect(() => {
		getLocaleFont(locale)
			.then((result) => {
				setStyles(result);
			})
			.catch((error) => {
				reportError(error);
			});
	}, [locale]);
	return styles;
}
