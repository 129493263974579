const locationStorageKey = "/hooks/useLocation/state";

export function clearLocationShownStorage() {
	localStorage.removeItem(locationStorageKey);
}

const shown = "shown";

export function getLocationShownFromStorage() {
	const stored = localStorage.getItem(locationStorageKey);
	const valid = stored === shown;
	if (stored !== shown) {
		clearLocationShownStorage();
	}
	return valid;
}

export function saveLocationShownToStorage() {
	localStorage.setItem(locationStorageKey, shown);
}
