import useRebrandGate from "@lib/feature/landing/hooks/useRebrandGate";
import { ReactNode } from "react";
import BottomNav from "./BottomNav";
import OldTopNav from "./OldTopNav";
import TopNav from "./TopNav";
import { AppLayoutAlert } from "./appAlert";

type HomeButtonTarget = "back" | "map";

type AppLayoutProps = {
	children: ReactNode | null;
	homeButtonTarget?: HomeButtonTarget;
	pageHeading?: ReactNode | undefined;
	toolbarHead?: ReactNode | undefined;
	noBottomNavigation?: boolean;
};

export default function AppLayout({
	children,
	homeButtonTarget,
	pageHeading,
	toolbarHead,
	noBottomNavigation,
}: AppLayoutProps) {
	const useRebrand = useRebrandGate();
	return (
		<>
			{useRebrand ? (
				<TopNav homeButtonTarget={homeButtonTarget} pageHeading={pageHeading} toolbarHead={toolbarHead} />
			) : (
				<OldTopNav homeButtonTarget={homeButtonTarget} pageHeading={pageHeading} toolbarHead={toolbarHead} />
			)}
			<AppLayoutAlert />
			{children}
			{noBottomNavigation === true ? null : <BottomNav />}
		</>
	);
}
