import { Box, Chip } from "@mui/material";
import { Trans, useTranslation } from "next-i18next";
import { FieldTag } from "./configTypes";

function DefaultChip({ value: { translatedValue, isMatch } }: { value: FieldTag }) {
	const { t } = useTranslation("common");
	return (
		<>
			<Chip
				component="li"
				sx={{
					listStyle: "none",
					backgroundColor: isMatch ? "common.white" : "unset",
					color: isMatch ? "common.black" : "common.white",
				}}
				variant={isMatch ? "filled" : "outlined"}
				label={
					isMatch ? (
						<Trans
							t={t}
							values={{ tag: translatedValue }}
							i18nKey={"profile.matches"}
							components={[<span key="0" className="sr-only" />]}
						/>
					) : (
						translatedValue
					)
				}
				size="small"
			/>
		</>
	);
}

export default function renderValueDefaultChips(values: FieldTag[], describedby: string) {
	return (
		<Box
			component="ul"
			aria-describedby={describedby}
			sx={{
				margin: 0,
				display: "flex",
				padding: 0,
				gap: "8px",
				flexWrap: "wrap",
			}}
		>
			{values.map((value) => {
				return <DefaultChip key={value.tag} value={value} />;
			})}
		</Box>
	);
}
