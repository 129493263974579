import { SvgIcon, SvgIconProps } from "@mui/material";

export default function GlobeIcon(props: SvgIconProps) {
	return (
		<SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" data-testid="BKDRGlobeIcon" {...props}>
			<path
				d="M6.22118 15.0255C6.07653 14.0609 6 13.0451 6 12C6 11.3401 6.03051 10.6918 6.08942 10.0608C4.82895 9.48941 3.66589 8.74103 2.63243 7.84762C2.62537 7.84153 2.6184 7.83533 2.61152 7.82904L2.04614 7.31195C1.37517 8.7341 1 10.3232 1 12C1 12.2526 1.00851 12.5032 1.02527 12.7516L1.02999 12.7542C1.66946 13.1154 2.59068 13.5987 3.70862 14.0821C4.47049 14.4116 5.31651 14.7379 6.22118 15.0255Z"
				fill="currentColor"
			/>
			<path
				d="M3.08634 5.55297L3.95078 6.34358C4.69401 6.98465 5.5144 7.53847 6.39617 7.98919C6.66559 6.6823 7.0648 5.49267 7.57016 4.48195C8.52812 2.56603 10.0365 1 12 1C13.9634 1 15.4719 2.56603 16.4298 4.48195C16.9354 5.49299 17.3347 6.68304 17.6041 7.99042C18.4766 7.54502 19.2987 6.99375 20.0504 6.34402L20.9141 5.55355C18.9154 2.79462 15.6673 1 12 1C8.33297 1 5.08504 2.79437 3.08634 5.55297Z"
				fill="currentColor"
			/>
			<path
				d="M21.9542 7.31277L21.3904 7.82879C21.3872 7.83174 21.384 7.83468 21.3807 7.83759C21.3772 7.84075 21.3736 7.84388 21.37 7.84699C20.3215 8.75517 19.155 9.49843 17.9107 10.0618C17.9695 10.6925 18 11.3404 18 12C18 13.0287 17.9259 14.0289 17.7856 14.9798C18.5944 14.7058 19.3454 14.3994 20.0209 14.0905C21.0729 13.6096 21.9288 13.129 22.519 12.7704C22.7028 12.6587 22.8605 12.5591 22.9899 12.4752C22.9966 12.3177 23 12.1592 23 12C23 10.3236 22.625 8.73471 21.9542 7.31277Z"
				fill="currentColor"
			/>
			<path
				d="M15.7169 8.76371C15.4799 7.45954 15.106 6.3064 14.641 5.37638C13.7892 3.67286 12.7977 3 12 3C11.2023 3 10.2108 3.67286 9.35901 5.37638C8.89406 6.30629 8.52021 7.45926 8.2832 8.76323C9.45622 9.13362 10.7051 9.33334 12.0008 9.33334L12.0023 9.33334C13.2724 9.33517 14.5245 9.14013 15.7169 8.76371Z"
				fill="currentColor"
			/>
			<path
				d="M8.03888 10.7783C9.29721 11.1396 10.6263 11.3333 12.0001 11.3333C13.3503 11.3352 14.683 11.1458 15.9611 10.7788C15.9867 11.1774 16 11.585 16 12C16 13.2788 15.8739 14.4874 15.6504 15.5806C14.4933 15.8385 13.2635 16 11.9992 16C10.7562 16 9.52579 15.8439 8.35218 15.5934C8.12708 14.4968 8 13.2838 8 12C8 11.5849 8.01329 11.1771 8.03888 10.7783Z"
				fill="currentColor"
			/>
			<path
				d="M12 23C17.0179 23 21.251 19.6401 22.5724 15.0474C22.0757 15.3191 21.4981 15.6143 20.8525 15.9095C19.8331 16.3755 18.6334 16.8463 17.3074 17.2228C17.0691 18.0579 16.7741 18.8295 16.4298 19.518C15.4719 21.434 13.9634 23 12 23Z"
				fill="currentColor"
			/>
			<path
				d="M2.9148 15.9179C4.01058 16.3917 5.296 16.8713 6.70057 17.2506C6.93744 18.0751 7.22971 18.8372 7.57016 19.518C8.52718 21.4321 10.0336 22.9969 11.9942 23C7.05609 22.9975 2.87891 19.741 1.49063 15.2585C1.92156 15.4714 2.39862 15.6946 2.9148 15.9179Z"
				fill="currentColor"
			/>
			<path
				d="M8.97282 17.7451C9.9532 17.9053 10.9684 18 11.9992 18C13.042 18 14.0575 17.9032 15.0294 17.7395C14.9087 18.0525 14.7789 18.3479 14.641 18.6236C13.7892 20.3271 12.7977 21 12 21C11.2023 21 10.2108 20.3271 9.35901 18.6236C9.22197 18.3496 9.09285 18.0561 8.97282 17.7451Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
}
