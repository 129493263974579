import { captureException } from "@sentry/nextjs";

export default function reportError(error: Error) {
	if (process.env.NODE_ENV === "test") {
		return;
	}

	// eslint-disable-next-line no-console
	console.error(error);

	captureException(error);
}
