import { useTranslation } from "next-i18next";
import { CurrentVideoElement } from "./CurrentMediaElement";

export default function CurrentVideo({ videoUrl }: { videoUrl: string }) {
	const { t } = useTranslation("common");

	return (
		<>
			<CurrentVideoElement
				sx={{ mx: "auto" }}
				src={videoUrl}
				autoPlay
				muted
				loop
				aria-hidden
				disablePictureInPicture
				playsInline
			/>
			<p className="sr-only">{t("in_the_moment.current_video_alt")}</p>
		</>
	);
}
