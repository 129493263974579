import { ReactNode, useEffect, useState } from "react";

export default function Delayed({ children }: { children: ReactNode }) {
	const [hidden, setHidden] = useState(true);
	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setHidden(false);
		}, 1000);
		return () => {
			clearTimeout(timeoutId);
		};
	}, []);
	if (hidden) {
		return null;
	}
	return <>{children}</>;
}
