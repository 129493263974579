import useUser from "@hooks/useUser";
import useExploreCitiesGate from "@lib/feature/exploreMode/useExploreCitiesGate";
import useRebrandGate from "@lib/feature/landing/hooks/useRebrandGate";
import Box from "@mui/material/Box";
import { BottomRightMapControlsProps } from ".";
import CenterControl from "../CenterControl";
import ExplorePopularCitiesControl from "../ExplorePopularCitiesControl";
import InTheMomentControl from "../InTheMomentControl";

export default function MapControls({ mapbox, center }: BottomRightMapControlsProps) {
	const useExploreCitiesGateEnabled = useExploreCitiesGate();
	const rebrandEnabled = useRebrandGate();

	const user = useUser();
	if (user == null) {
		return null;
	}
	return (
		<>
			<Box sx={{ gap: 0 }}>
				{useExploreCitiesGateEnabled ? <ExplorePopularCitiesControl source="map" useRebrand={rebrandEnabled} /> : null}
				<CenterControl mapbox={mapbox} center={center} useRebrand={rebrandEnabled} />
			</Box>
			<InTheMomentControl source="map" useRebrand={rebrandEnabled} />
		</>
	);
}
