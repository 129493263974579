import { Box, Slider, SliderProps, Typography } from "@mui/material";
import palette from "@styles/palette";
import { useTranslation } from "next-i18next";

export default function LocationRandomizationSlider(props: SliderProps) {
	const { t } = useTranslation("common");

	return (
		<Box>
			<Slider
				aria-label={t("app_bar.location_privacy")}
				step={0.01}
				min={0}
				max={1}
				track={false}
				sx={{
					color: palette.OnSurfaceSecondary,
					"& .MuiSlider-thumb": {
						borderRadius: "1px",
						color: palette.Primary,
					},
					"& .MuiSlider-rail": {
						color: palette.OnSurfaceSecondary,
					},
				}}
				{...props}
			/>
			<Box sx={{ display: "flex", justifyContent: "space-between" }}>
				<Typography variant="body2" sx={{ color: palette.OnSurfaceSecondary }}>
					{t("slider.less")}
				</Typography>
				<Typography variant="body2" sx={{ color: palette.OnSurfaceSecondary }}>
					{t("slider.more")}
				</Typography>
			</Box>
		</Box>
	);
}
