import { FriendPointFeature } from "@lib/feature/map/clustering/getClustersFromPoints";
import { UserShort } from "@lib/models";

export default function getTopFriend(leaves: FriendPointFeature[]): UserShort {
	const sortedLeaves = leaves
		.slice()
		.sort((a, b) => b.properties.friendPin.friend.last_active.localeCompare(a.properties.friendPin.friend.last_active));
	let firstRecentProfileImage: UserShort | undefined;
	const topFriend = sortedLeaves.find((leaf) => {
		if (leaf.properties.friendPin.friend.in_the_moment_status?.thumb != null) {
			return true;
		}
		if (firstRecentProfileImage == null && leaf.properties.friendPin.friend.photos[0]?.thumb != null) {
			firstRecentProfileImage = leaf.properties.friendPin.friend;
		}
		return false;
	});
	return (
		topFriend?.properties.friendPin.friend ?? firstRecentProfileImage ?? sortedLeaves[0].properties.friendPin.friend
	);
}
