import { ArrowBackIos } from "@mui/icons-material";
import { IconButton, IconButtonProps } from "@mui/material";
import { useTranslation } from "next-i18next";

/** @deprecated use BackButtonV2 component instead */
export default function BackButton(props: IconButtonProps) {
	const { t } = useTranslation("common");
	return (
		<IconButton aria-label={t("back_button")} {...props}>
			<ArrowBackIos color="inherit" />
		</IconButton>
	);
}
