import checkForMobile from "@lib/util/checkForMobile";
import { styled } from "@mui/material";
import palette from "@styles/palette";
import UserIcon from "./icons/UserIcon";

export const UserIconOnMobileMap = styled(UserIcon)(({ theme }) => ({
	color: palette.Primary,
	width: theme.spacing(2),
	height: theme.spacing(2),
}));

export const UserIconOnDesktopMap = styled(UserIcon)(({ theme }) => ({
	color: palette.Primary,
	width: theme.spacing(2.5),
	height: theme.spacing(2.5),
}));

export default function UserIconOnMap() {
	const isMobile = checkForMobile();
	return isMobile ? <UserIconOnMobileMap /> : <UserIconOnDesktopMap />;
}
