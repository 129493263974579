import { chatToken } from "@api/account";
import { LocaleCode } from "@lib/models";
import { getLocationPathname } from "@lib/util/locationWrapper";
import { StreamChat, TranslationLanguages } from "stream-chat";
import { Streami18n } from "stream-chat-react";
import shouldDeleteUpdateCount from "./shouldDeleteUpdateCount";

if (process.env.NODE_ENV === "development") {
	// @ts-expect-error webpackhot stuff isn't enabled in types
	if (module.hot) {
		// @ts-expect-error webpackhot stuff isn't enabled in types
		module.hot.decline();
	}
}

type ChatClientState = { streamClient: StreamChat; i18nInstance: Streami18n } | null;

const streamKey = process.env.NEXT_PUBLIC_STREAM_KEY ?? "";
if (streamKey === "") {
	throw new Error("Missing required env var NEXT_PUBLIC_STREAM_KEY");
}

type GlobalState = {
	chatClientState: ChatClientState;
	unreadCount: number;
	connecting: Promise<undefined>;
	initialized: boolean;
	connected: boolean;
	destroy: () => void | Promise<void>;
};

const initialGlobalState = {
	chatClientState: null,
	unreadCount: 0,
	connecting: Promise.resolve(undefined),
	initialized: false,
	connected: false,
	destroy: () => {
		Object.assign(globalState, initialGlobalState);
	},
};

const globalState: GlobalState = { ...initialGlobalState };

const globalEvents = new EventTarget();

function triggerUpdate() {
	globalEvents.dispatchEvent(new Event("change"));
}

async function initializeClient(userUuid: string, locale: LocaleCode) {
	if (globalState.initialized) {
		return;
	}
	globalState.initialized = true;
	const i18nInstance = new Streami18n({ language: locale as TranslationLanguages });
	const client = StreamChat.getInstance(streamKey);
	await client.connectUser({ id: userUuid }, async () => {
		return (await chatToken()).token;
	});
	globalState.connected = true;
	globalState.chatClientState = {
		streamClient: client,
		i18nInstance,
	};
	globalState.unreadCount = (client.user?.unread_count as number | undefined) ?? 0;

	const connectionSubscription = client.on("connection.changed", (event) => {
		if (event.online) {
			globalState.connected = true;
		} else {
			globalState.connected = false;
		}
		triggerUpdate();
	});

	// In theory we only care about "notification.mark_read" events, however,
	// those don't get sent for additional unreads, only when things are marked read.
	// Instead we're just grabbing the unread count from any event. An update with the
	// same value is a no-op. Then we're updating and replacing the user object on the
	// client since stream doesn't.
	const markReadSubscriber = client.on((event) => {
		if (event?.unread_count != null) {
			if (event?.user?.id && getLocationPathname().includes(event.user.id)) {
				return;
			}
			globalState.unreadCount = event.unread_count ?? 0;
			triggerUpdate();
		}
	});

	const deletionSubscriber = client.on((event) => {
		if (event.type !== "message.deleted" || event.message == null || event.channel_id == null) {
			return;
		}
		const channel = client.getChannelById("messaging", event.channel_id, {});
		if (shouldDeleteUpdateCount(event, channel)) {
			globalState.unreadCount = globalState.unreadCount - 1;
			triggerUpdate();
		}
	});

	globalState.destroy = async () => {
		markReadSubscriber.unsubscribe();
		deletionSubscriber.unsubscribe();
		connectionSubscription.unsubscribe();
		await client.disconnectUser();
		Object.assign(globalState, initialGlobalState);
		triggerUpdate();
	};
	triggerUpdate();
}

export function subscribe(onChange: () => void) {
	globalEvents.addEventListener("change", onChange);
	return () => {
		globalEvents.removeEventListener("change", onChange);
	};
}

export function getChatClient(userUuid: string, locale: LocaleCode): ChatClientState {
	initializeClient(userUuid, locale);
	if (globalState.connected) {
		return globalState.chatClientState;
	}
	return null;
}

export function getUnreadCount(userUuid: string, locale: LocaleCode): number {
	initializeClient(userUuid, locale);
	if (globalState.connected) {
		return globalState.unreadCount;
	}
	return 0;
}

export async function destroy() {
	return globalState.destroy();
}
