import { SvgIcon, SvgIconProps } from "@mui/material";

export default function CheckSquareIcon(props: SvgIconProps) {
	return (
		<SvgIcon data-testid="BKDRCheckSquareIcon" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M22 2H2V22H22V2ZM10.4998 16.4799L17.4127 8.93858L15.9384 7.58713L10.4998 13.5201L8.06122 10.8599L6.58691 12.2113L10.4998 16.4799Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
}
