import { AnyAccount, UserShort } from "@lib/models";
import AvatarPin from "./AvatarPin";
import useAvatarState from "./useAvatarState";

type MapProfileAvatarProps = {
	account: AnyAccount | UserShort;
	ownProfile?: boolean;
	onClick?: () => void;
};

export default function MapProfileAvatar({ account, ownProfile = false, onClick }: MapProfileAvatarProps) {
	const pinProps = useAvatarState(account, ownProfile);

	return <AvatarPin {...pinProps} onClick={onClick} />;
}
