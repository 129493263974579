export default {
	Primary: "#EB0F00",
	PrimaryOpacity25: "#eb0f0040",
	onPrimary: "#E9E9E9",
	Secondary: "#E9E9E9",
	Secondary25: "#E9E9E940",
	Error: "#CB0D00",
	Success: "#34C759",
	onSecondary: "#000000",
	Destructive: "#EB0F00",
	onSurface: "#E9E9E9",
	Surface: "#000000",
	surfaceContainer: "#000000",
	SurfaceContainerOpacity15: "#00000026",
	SurfaceContainerMid: "#141414",
	SurfaceContainerMidOpacity15: "#14141426",
	OnSurfaceSecondary: "#7A7A7A",
	OnSurfaceSecondary25: "#7A7A7A40",
	OnSurfaceTertiary: "#2A2A2A",
	indicatorActive: "#63E100",
	Scrim: "#00000099",
} as const;
