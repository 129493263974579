// This is the central mockable location to handle the properties blocked by this rule.
// If a location feature isn't covered, prefer adding it here instead of adding an exception.
/* eslint-disable no-restricted-properties */

export function locationReload() {
	window.location.reload();
}

export function locationAssign(href: string | URL) {
	window.location.assign(href);
}

export function locationReplace(href: string | URL) {
	window.location.replace(href);
}

export function getLocationHash() {
	return window.location.hash;
}

export function getLocationPathname() {
	return window.location.pathname;
}
