import { Authentication } from "@lib/models";
import { createContext } from "react";

const authContext = createContext<Authentication>({
	isUser: false,
	onLogin: () => undefined,
	onRedirectToLogin: () => undefined,
	onLogout: () => Promise.resolve(),
});
const AuthProvider = authContext.Provider;

export { AuthProvider, authContext };
