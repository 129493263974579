import { accountRegisterPath } from "@api/account";
import { deleteInTheMomentStatus } from "@api/profile";
import useGlobalLoader from "@hooks/useGlobalLoader";
import useUser from "@hooks/useUser";
import * as feedModel from "@lib/feature/Feed/feedModel";
import { ShinyAlert } from "@lib/feature/alert/ShinyAlert";
import { useUpdateAlert } from "@lib/feature/alert/notificationContext";
import useAlertState from "@lib/feature/alert/useAlertState";
import { UserAccount } from "@lib/models";
import assertType from "@lib/util/assertType";
import { Button, DialogActions, DialogContent, DialogContentText, Typography } from "@mui/material";
import { useTranslation } from "next-i18next";
import { useState } from "react";
import { useSWRConfig } from "swr";
import CurrentStatus from "./CurrentStatus";
import InTheMomentForm from "./InTheMomentForm";

type ManagerProps = {
	onClose: () => void;
	inTheMoment: NonNullable<UserAccount["in_the_moment_status"]>;
	onOpenAnonRegister: () => void;
};

export default function Manager({ onClose, inTheMoment, onOpenAnonRegister }: ManagerProps) {
	const { t } = useTranslation("common");
	const user = assertType(useUser());
	const { mutate } = useSWRConfig();
	const [replaceActive, setReplaceActive] = useState(false);
	const [alert, localUpdateAlert] = useAlertState();
	const updateAlert = useUpdateAlert();
	const [deleting, setDeleting] = useState(false);
	useGlobalLoader(deleting, "ITMManager deleting");
	if (replaceActive) {
		return (
			<InTheMomentForm
				onClose={(reason) => {
					setReplaceActive(false);
					if (reason === "success") {
						onClose();
					}
				}}
				onOpenAnonRegister={onOpenAnonRegister}
			/>
		);
	}
	return (
		<>
			<DialogContent
				sx={{
					pb: 0,
					overflowY: "hidden",
				}}
			>
				<Typography component="h2" variant="h5">
					{user.is_anon ? t("anon_mode.info_modal_header") : t("in_the_moment.dialog_title")}
				</Typography>
			</DialogContent>
			<DialogContent sx={{ px: 2, pt: 0 }}>
				<DialogContentText variant="body2">{t("in_the_moment.current_dialog_subtitle")}</DialogContentText>
				<CurrentStatus
					inTheMoment={inTheMoment}
					deleting={deleting}
					onDelete={async () => {
						setDeleting(true);
						deleteInTheMomentStatus()
							.then(
								() => {
									mutate(accountRegisterPath);
									updateAlert(t("in_the_moment.status_deleted_message"), "success");
									onClose();
									feedModel.removeFeedItem(user.uuid);
								},
								() => {
									localUpdateAlert(t("unknown_error"));
								},
							)
							.finally(() => {
								setDeleting(false);
							});
					}}
				/>
			</DialogContent>
			{alert == null ? null : (
				<ShinyAlert
					key={alert.key}
					severity={alert.severity}
					variant="filled"
					sx={{
						mx: 2,
					}}
					onClose={() => {
						localUpdateAlert();
					}}
				>
					{alert.children}
				</ShinyAlert>
			)}
			<DialogActions
				sx={{
					px: 2,
					justifyContent: "flex-start",
				}}
			>
				<Button
					size="small"
					variant="contained"
					type="button"
					onClick={() => {
						setReplaceActive(true);
					}}
				>
					{t("replace_button")}
				</Button>
			</DialogActions>
		</>
	);
}
