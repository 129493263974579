import { Box, styled } from "@mui/material";

const ProfilePictureGradientOverlay = styled(Box)(({ theme }) => {
	return {
		position: "absolute",
		top: 0,
		height: theme.spacing(8),
		width: "100%",
		background: "linear-gradient(0, rgba(18, 18, 18, 0) 0%, rgba(18, 18, 18, 0.6) 100%)",
	};
});

export default ProfilePictureGradientOverlay;
