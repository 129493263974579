import useLocation from "@hooks/useLocation";
import useFeatureFlags from "@lib/feature/featureFlags/useFeatureFlags";
import { Location } from "@lib/models";
import mapboxgl from "mapbox-gl";
import { useEffect, useRef } from "react";
import { SAO_PAULO_LNG_LAT } from "./useMapLocation";

export default function useEaseToPrecisePosition(position: Location, mapbox: mapboxgl.Map | undefined) {
	const [locationStatus] = useLocation();
	const { ip_geolocated_location, usertesting_subject } = useFeatureFlags() ?? {
		ip_geolocated_location: null,
		usertesting_subject: false,
	};

	const prevPosition = useRef<Location>();
	const hasNotEased = useRef<boolean>(true);

	useEffect(() => {
		function locationsMatch(position: Location | null, prev: Location | undefined) {
			if (prev == null || position == null) {
				return false;
			}
			return position.lng === prev.lng && position.lat === prev.lat;
		}

		if (
			hasNotEased.current === true &&
			mapbox != null &&
			locationStatus === "success" &&
			!usertesting_subject &&
			(locationsMatch(SAO_PAULO_LNG_LAT, prevPosition.current) ||
				locationsMatch(ip_geolocated_location, prevPosition.current))
		) {
			mapbox.easeTo({
				center: position,
			});
			hasNotEased.current = false;
		}
		prevPosition.current = position;
	}, [position, mapbox, locationStatus, ip_geolocated_location, usertesting_subject]);
}
