import { InTheMomentStatus } from "@lib/models";
import { track } from "@lib/util/trackAnalytics";
import ReplyIcon from "@mui/icons-material/Reply";
import { Button } from "@mui/material";
import { useTranslation } from "next-i18next";

type ReplyButtonProps = {
	inTheMomentContent: InTheMomentStatus;
	onOpen: () => void;
};

export default function ReplyButton({ inTheMomentContent, onOpen }: ReplyButtonProps) {
	const { t } = useTranslation("common");
	const photo = inTheMomentContent?.full;
	const video = inTheMomentContent?.video;
	const message = inTheMomentContent?.message ?? "";

	function onClick() {
		track("reply_to_ITMC_button_tapped", {
			photo: photo != null,
			video: video != null,
			message: message.length > 0,
		});
		onOpen();
	}

	function getAriaLabel() {
		if (message.length === 0) {
			if (video != null) {
				return t("profile.reply_to_this_video");
			}
			if (photo != null) {
				return t("profile.reply_to_this_photo");
			}
		}
		if (video != null) {
			return t("profile.reply_to_this_status_and_video");
		}
		if (photo != null) {
			return t("profile.reply_to_this_status_and_photo");
		}
		return t("profile.reply_to_this_status");
	}

	if (inTheMomentContent == null) {
		return null;
	}

	return (
		<Button
			aria-label={getAriaLabel()}
			onClick={onClick}
			size="medium"
			variant="contained"
			sx={(theme) => ({
				padding: theme.spacing(1),
				height: "auto",
				width: "auto",
				minWidth: "0px",
				borderRadius: "50%",
				ml: "8px",
				"&:hover": {
					"@media (hover: none)": {
						backgroundColor: "common.white",
					},
				},
			})}
		>
			<ReplyIcon sx={{ color: "common.black" }} />
		</Button>
	);
}
