import useIsImperial from "@hooks/useIsImperial";
import { mToFeetIn } from "@lib/util/conversionHelpers";
import { useTranslation } from "next-i18next";
import { useMemo } from "react";
import InlineListItem from "./InlineListItem";

type HeightProps = {
	height: number;
};

export default function Height({ height }: HeightProps) {
	const { t } = useTranslation("common");
	const isImperial = useIsImperial();
	const heightObj = useMemo(() => {
		return isImperial ? mToFeetIn(height) : { height: Math.round(height * 100) };
	}, [isImperial, height]);

	return (
		<InlineListItem
			label={t("profile.height_label")}
			value={isImperial ? t("profile.height_imperial", heightObj) : t("profile.height_metric", heightObj)}
		/>
	);
}
