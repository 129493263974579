import useIsImperial from "@hooks/useIsImperial";
import useLocation, { UserLocationState } from "@hooks/useLocation";
import useUser from "@hooks/useUser";
import useRebrandGate from "@lib/feature/landing/hooks/useRebrandGate";
import { getMap } from "@lib/feature/map/mapbox";
import mapConfig from "@lib/feature/map/mapbox/mapConfig";
import { AnyAccount, OtherUser, PresenceType, UserAccount, UserShort } from "@lib/models";
import assertType from "@lib/util/assertType";
import getStatusColor from "@lib/util/getStatusColor";
import { track } from "@lib/util/trackAnalytics";
import { LocationOn } from "@mui/icons-material";
import { Box, Button as MuiButton } from "@mui/material";
import palette from "@styles/palette";
import distanceFrom from "distance-from";
import { Trans, useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { Button } from "./Button";
import LocationPinIcon from "./icons/LocationPinIcon";

type DistanceProps = {
	account: AnyAccount | UserShort;
	presence: PresenceType;
};

type GetDistance = {
	positionStatus: UserLocationState;
	activeUser: UserAccount | undefined;
	account: AnyAccount | UserShort;
	position: GeolocationPosition | null;
	isImperial: boolean;
};

function isOtherAccount(account: AnyAccount | UserShort, activeUser: UserAccount): account is OtherUser {
	return account.uuid !== activeUser.uuid;
}

function isVisibleOnMap(account: UserShort | AnyAccount) {
	return account.is_active || account.is_breadcrumb;
}

function getDistance({ positionStatus, activeUser, account, position, isImperial }: GetDistance) {
	if (
		positionStatus !== "success" ||
		activeUser == null ||
		!isOtherAccount(account, activeUser) ||
		account.lat == null ||
		account.lng == null
	) {
		return null;
	}

	// assertType call here is assured by positionStatus === "success"
	const nonNullPosition = assertType(position);
	const userPosition: [number, number] = [nonNullPosition.coords.latitude, nonNullPosition.coords.longitude];
	return (
		Math.round(
			distanceFrom(userPosition)
				.to([account.lat, account.lng])
				.in(isImperial ? "miles" : "kilometers") * 10,
		) / 10
	);
}

export default function Distance({ account, presence }: DistanceProps) {
	const router = useRouter();
	const { t } = useTranslation("common");
	const rebrandEnabled = useRebrandGate();
	const [positionStatus, position] = useLocation();
	const isImperial = useIsImperial();
	const activeUser = useUser();
	const distance = getDistance({ positionStatus, position, activeUser, account, isImperial });
	if (distance == null) {
		return null;
	}

	const goToLocation = (() => {
		const map = getMap();
		const { lng, lat } = account;
		if (map == null || lng == null || lat == null) {
			return null;
		}
		return () => {
			track("user_location_tapped");
			router.push("/");
			map.easeTo({ center: [lng, lat], zoom: mapConfig.maxZoom });
		};
	})();

	if (rebrandEnabled) {
		const text = (
			<Trans
				t={t}
				i18nKey={isImperial ? "profile.distance_imperial_rebrand" : "profile.distance_metric_rebrand"}
				components={[<span key="0" className="sr-only" />]}
				tOptions={{ distance }}
			/>
		);
		if (goToLocation == null || !isVisibleOnMap(account)) {
			return (
				<Box
					component="span"
					sx={(theme) => ({
						display: "flex",
						alignItems: "center",
						gap: theme.spacing(0.5),
						fontSize: "12px",
						fontFamily: "var(--heading-font)",
						color: palette.OnSurfaceSecondary,
					})}
				>
					<LocationPinIcon sx={{ width: "12px", height: "12px" }} />
					{text}
				</Box>
			);
		}

		return (
			<Button size="xxxs" variant="primary ghost" onClick={goToLocation}>
				<LocationPinIcon />
				{text}
			</Button>
		);
	}

	return (
		<MuiButton
			size="small"
			variant="outlined"
			disabled={goToLocation == null || (presence === "offline" && account.in_the_moment_status == null)}
			startIcon={<LocationOn />}
			sx={(theme) => {
				const color = getStatusColor(presence)(theme);
				return {
					"&.MuiButton-outlined": {
						color,
						borderColor: color,
					},
				};
			}}
			onClick={goToLocation ?? undefined}
		>
			<Trans
				t={t}
				i18nKey={isImperial ? "profile.distance_imperial" : "profile.distance_metric"}
				components={[<span key="0" className="sr-only" />]}
				tOptions={{ distance }}
			/>
		</MuiButton>
	);
}
