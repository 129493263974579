import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { styled } from "@mui/material";
import { useTranslation } from "next-i18next";

const Button = styled("button")(({ theme }) => ({
	display: "block",
	width: theme.spacing(8.6),
	height: theme.spacing(8.6),
	border: "none",
	padding: "0px",
	position: "relative",
	background: "none",
	cursor: "pointer",
	"& circle.lower": {
		stroke: theme.palette.common.white,
	},
}));

type CircleArrowNextButtonProps = {
	onClick: () => void;
};

export default function CircleArrowNextButton({ onClick }: CircleArrowNextButtonProps) {
	const { t } = useTranslation("common");

	return (
		<Button type="button" onClick={onClick} aria-label={t("next_button")}>
			<svg id="svg_css_ex1" viewBox="0 0 100 100" aria-hidden={true} xmlns="http://www.w3.org/2000/svg">
				<circle className="lower" fill="none" stroke="white" strokeWidth="7" cx="50" cy="50" r="46" />
			</svg>
			<ArrowForwardIcon
				color="inherit"
				sx={{
					position: "absolute",
					top: "0px",
					bottom: "0px",
					left: "0px",
					right: "0px",
					margin: "auto",
					color: (theme) => theme.palette.common.white,
					fontSize: "3rem",
				}}
			/>
		</Button>
	);
}
