import AvatarThumbnailMedia from "@components/AvatarThumbnailMedia";
import useDisplayName from "@hooks/useDisplayName";
import { useLocaleWithCountry } from "@hooks/useLocale";
import useFeatureFlags from "@lib/feature/featureFlags/useFeatureFlags";
import useRebrandGate from "@lib/feature/landing/hooks/useRebrandGate";
import { AnyAccount, UserShort } from "@lib/models";
import { getOnlineStatus } from "@lib/util/getOnlineStatus";
import { useTranslation } from "next-i18next";
import { useMemo } from "react";
import { AvatarPinBadgeOption, AvatarPinProps, AvatarPinSizeOption } from "./AvatarPin";

function hasInTheMomentStatus(account: AnyAccount | UserShort) {
	return account?.in_the_moment_status != null;
}

function hasProfilePic(account: AnyAccount | UserShort) {
	return account?.photos.at(0) != null;
}

function hasPhoto(account: AnyAccount | UserShort) {
	return account?.in_the_moment_status?.full != null || hasProfilePic(account);
}

function hasVideo(account: AnyAccount | UserShort) {
	return account?.in_the_moment_status?.video != null;
}

function hasInTheMomentStatusMessage(account: AnyAccount | UserShort) {
	return (account?.in_the_moment_status?.message ?? "").length > 0;
}

function hasInTheMomentStatusMedia(account: AnyAccount | UserShort) {
	return account?.in_the_moment_status?.full != null || account?.in_the_moment_status?.video != null;
}

function getSize(account: AnyAccount | UserShort): AvatarPinSizeOption {
	const isBreadcrumb = account.is_breadcrumb;
	if (hasInTheMomentStatusMedia(account)) {
		return isBreadcrumb ? "medium" : "large";
	}
	if (hasProfilePic(account)) {
		return isBreadcrumb ? "small" : "medium";
	}
	return isBreadcrumb ? "x-small" : "small";
}

function getSizeRebrand(account: AnyAccount | UserShort): AvatarPinSizeOption {
	const isBreadcrumb = account.is_breadcrumb;
	const hasPic = hasProfilePic(account);
	const hasStatus = hasInTheMomentStatusMessage(account);
	const hasITMMedia = hasInTheMomentStatusMedia(account);
	if (hasITMMedia) {
		return "large";
	}
	if (hasStatus) {
		if (hasPic) {
			return "medium";
		}
		return "small";
	}
	if (isBreadcrumb) {
		return "x-small";
	}

	return hasPic ? "medium" : "small";
}

function getBadge(account: AnyAccount | UserShort): AvatarPinBadgeOption {
	const presence = getOnlineStatus(account);

	if (presence === "away") {
		return "breadcrumb";
	}
	if (presence === "offline") {
		return "none";
	}
	return presence;
}

function getBadgeRebrand(account: AnyAccount | UserShort): AvatarPinBadgeOption {
	return getOnlineStatus(account) === "online" ? "online" : "none";
}

function hasDesaturation(account: AnyAccount | UserShort) {
	if (hasInTheMomentStatus(account)) {
		return false;
	}
	return account.is_breadcrumb ? true : false;
}

export default function useAvatarState(
	account: AnyAccount | UserShort,
	ownProfile: boolean,
): Omit<AvatarPinProps, "onClick"> {
	const { t } = useTranslation("common");
	const locale = useLocaleWithCountry();
	const displayName = useDisplayName(account);
	const blurMapPins = useFeatureFlags()?.blur_map_pins ?? false;
	const rebrandEnabled = useRebrandGate();

	return useMemo(() => {
		const formatter = new Intl.ListFormat(locale, { style: "short", type: "unit" });
		// TODO NO display name for blurred only
		const SRLabel = formatter.format(
			[
				displayName,
				hasVideo(account) ? t("map_profile_avatar.video") : hasPhoto(account) ? t("map_profile_avatar.photo") : false,
				hasInTheMomentStatusMessage(account) ? t("map_profile_avatar.status") : false,
				t(`presence.presence_${getOnlineStatus(account)}`),
				account.is_match ? t("map_profile_avatar.closeMatch") : false,
			].filter((item) => item !== false),
		);

		if (rebrandEnabled) {
			return {
				size: getSizeRebrand(account),
				badge: getBadgeRebrand(account),
				videoBadge: account.in_the_moment_status?.video != null,
				highlight: hasInTheMomentStatus(account),
				desaturate: hasDesaturation(account),
				note: ownProfile ? t("map_profile_avatar.self_label_rebrand") : null,
				SRLabel,
				media: <AvatarThumbnailMedia profile={account} alt={SRLabel} stayBlurred={blurMapPins} />,
			};
		}

		return {
			size: getSize(account),
			badge: getBadge(account),
			videoBadge: account.in_the_moment_status?.video != null,
			highlight: hasInTheMomentStatus(account),
			desaturate: account.is_breadcrumb,
			note: ownProfile ? t("map_profile_avatar.self_label") : null,
			SRLabel,
			media: <AvatarThumbnailMedia profile={account} alt={SRLabel} stayBlurred={blurMapPins} />,
		};
	}, [t, locale, account, ownProfile, displayName, blurMapPins, rebrandEnabled]);
}
