import Link from "@components/Link";
import { Box, Button, DialogActions, DialogContent, DialogTitle, styled } from "@mui/material";
import { Trans, useTranslation } from "next-i18next";

const CustomLink = styled(Link)({
	color: "inherit",
	textDecorationColor: "inherit",
});

const externalLinks = [
	// it's not that kind of array
	// eslint-disable-next-line react/jsx-key
	<CustomLink href="https://www.perrystreet.com/privacy" />,
	// eslint-disable-next-line react/jsx-key
	<CustomLink href="https://www.perrystreet.com/tos" />,
];

type DrawerContentProps = {
	onClose: () => void;
};

export default function DrawerContent({ onClose }: DrawerContentProps) {
	const { t } = useTranslation("common");

	return (
		<Box
			sx={{
				width: "100%",
				maxWidth: "800px",
				margin: "auto",
			}}
		>
			<DialogTitle>{t("privacy_check.drawer_heading")}</DialogTitle>
			<DialogContent>{t("privacy_check.drawer_body")}</DialogContent>
			<DialogActions>
				<Button size="medium" variant="contained" onClick={onClose}>
					{t("accept_button")}
				</Button>
			</DialogActions>
			<DialogContent>
				<Trans t={t} i18nKey="landing.links" components={externalLinks} />
			</DialogContent>
		</Box>
	);
}
