import BackButton from "@components/BackButton";
import Link from "@components/Link";
import PinDropFilled from "@mui/icons-material/PinDrop";
import { AppBar, Box, IconButton, Toolbar, Typography } from "@mui/material";
import { useTranslation } from "next-i18next";
import { default as NextLink } from "next/link";
import { useRouter } from "next/router";
import { ReactNode } from "react";
import AvatarMenu from "./AvatarMenu";
import BKDRHeaderLogo from "./BKDRHeaderLogo";

type HomeButtonTarget = "back" | "map";

type OldTopNavProps = {
	homeButtonTarget?: HomeButtonTarget;
	pageHeading?: ReactNode | undefined;
	toolbarHead?: ReactNode | undefined;
};

export default function OldTopNav({ homeButtonTarget, pageHeading, toolbarHead }: OldTopNavProps) {
	const { t } = useTranslation("common");
	const router = useRouter();
	const rootRoute = router.pathname === "/";

	return (
		<AppBar
			position="relative"
			sx={{
				backgroundImage: "none",
				pt: "env(safe-area-inset-top, 0)",
			}}
		>
			<Toolbar>
				{(() => {
					if (homeButtonTarget === "back") {
						return (
							<BackButton
								onClick={() => {
									router.back();
								}}
							/>
						);
					}
					if (homeButtonTarget === "map") {
						return (
							<IconButton
								LinkComponent={Link}
								sx={{ marginLeft: "-10px", marginRight: "10px" }}
								aria-label={homeButtonTarget === "map" ? t("nav.map_link") : t("nav.inbox_link")}
								href="/"
							>
								<PinDropFilled color="inherit" />
							</IconButton>
						);
					}
					return null;
				})()}
				{toolbarHead != null ? (
					toolbarHead
				) : (
					<>
						<Typography
							variant="h6"
							noWrap
							component="h1"
							sx={{
								flexGrow: 1,
								a: {
									color: "inherit",
									textDecoration: "none",
								},
							}}
						>
							{pageHeading ?? (
								<>
									{rootRoute ? (
										<Box sx={{ display: "flex", alignItems: "end" }}>
											<BKDRHeaderLogo />
										</Box>
									) : (
										<NextLink href="/" style={{ display: "flex", alignItems: "end" }}>
											<BKDRHeaderLogo />
										</NextLink>
									)}
								</>
							)}
						</Typography>
						<AvatarMenu />
					</>
				)}
			</Toolbar>
		</AppBar>
	);
}
