import { get } from "@lib/httpClient";

const imagesPresignedPath = "/images/presign";

type GetPresignedPhotoUploadURLResponse = {
	fields: Record<string, string>;
	headers: Record<string, string>;
	method: string;
	url: string;
};

export class MediaUploadError extends Error {}

export async function uploadMedia(file: Blob) {
	const presignedRequestOptions = await get<unknown, GetPresignedPhotoUploadURLResponse>(imagesPresignedPath, {
		params: { type: file.type },
	});

	const uploadFormData = new FormData();
	Object.entries(presignedRequestOptions.fields).forEach(([key, value]) => {
		uploadFormData.append(key, value);
	});

	uploadFormData.set("key", presignedRequestOptions.fields.key);
	uploadFormData.append("file", file);

	const response = await fetch(presignedRequestOptions.url, {
		method: presignedRequestOptions.method,
		body: uploadFormData,
		mode: "cors",
		headers: presignedRequestOptions.headers,
	});

	if (!response.ok) {
		throw new MediaUploadError(`${response.status}: ${await response.text()}`);
	}

	return presignedRequestOptions.fields.key;
}
