import { postUserSettings, userSettingsPath } from "@api/profile";
import useGlobalLoader from "@hooks/useGlobalLoader";
import useUserSettings from "@hooks/useUserSettings";
import { useUpdateAlert } from "@lib/feature/alert/notificationContext";
import { UserSettings } from "@lib/models";
import reportError from "@lib/util/reportError";
import { Switch } from "@mui/material";
import { useTranslation } from "next-i18next";
import { useState } from "react";
import { useSWRConfig } from "swr";

// PropsOfTypePOnO takes an object and type, and returns an object type with properties from
// the object that have the matching type.
// PropsOfTypePOnO<{ a: string; b: number; c: string; d: boolean }, string> -> { a: string; c: string }
type PropsCoercedToPOrNeverOnO<O, P> = { [k in keyof O]: O[k] extends P ? k : never }[keyof O];
type PropsOfTypePOnO<P, O> = { [k in PropsCoercedToPOrNeverOnO<P, O>]: O };

export type SaveSwitchProps = {
	labelId: string;
	// only the boolean properties of UserSettings
	accountSetting: keyof PropsOfTypePOnO<UserSettings, boolean>;
	extraMutationKeys?: readonly string[];
	onChangeFinished?: (on: boolean) => void;
};

export default function SaveSwitch({
	labelId,
	accountSetting,
	extraMutationKeys = [],
	onChangeFinished,
}: SaveSwitchProps) {
	const { t } = useTranslation("common");
	const { mutate } = useSWRConfig();
	const updateAlert = useUpdateAlert();
	const userSettings = useUserSettings();
	const [optimisticValue, setOptimisticValue] = useState<boolean | null>(null);
	const loading = optimisticValue != null;
	useGlobalLoader(loading, "SaveSwitch loading");

	async function onChange(event: React.ChangeEvent<HTMLInputElement>) {
		const newValue = event.target.checked;
		setOptimisticValue(newValue);
		try {
			await postUserSettings({ account_settings: { [accountSetting]: newValue } });
			extraMutationKeys.forEach((mutationKey) => {
				mutate(mutationKey);
			});
			await mutate(userSettingsPath);
			onChangeFinished?.(newValue);
		} catch (error) {
			reportError(error as Error);
			updateAlert(t("unknown_error"));
		} finally {
			setOptimisticValue(null);
		}
	}

	return (
		<Switch
			color="secondary"
			inputProps={{ "aria-labelledby": labelId }}
			onChange={onChange}
			checked={optimisticValue ?? userSettings?.[accountSetting] ?? false}
			disabled={loading}
		/>
	);
}
