import PresenceBadge from "@components/PresenceBadge";
import ProfileAvatar from "@components/ProfileAvatar";
import FriendProfileDialog from "@components/ProfileDialog/FriendProfileDialog";
import UserProfileDialog from "@components/ProfileDialog/UserProfileDialog";
import useIdify from "@hooks/useIdify";
import useUser from "@hooks/useUser";
import { UserShort } from "@lib/models";
import { IconButton, Tooltip } from "@mui/material";
import { useTranslation } from "next-i18next";

type FeedAvatarProps = {
	user_short: UserShort;
};

export default function FeedAvatar({ user_short }: FeedAvatarProps) {
	const { t } = useTranslation("common");
	const idify = useIdify();
	const displayNameId = idify("display_name");
	const hasThumbnail = typeof (user_short.in_the_moment_status?.thumb ?? user_short.photos.at(0)?.thumb) === "string";
	const ownUser = useUser();

	function renderOpener(onOpen: () => void) {
		return (
			<Tooltip arrow placement="right" title={t("profile.view_profile_screen_reader_button")}>
				<IconButton
					onClick={onOpen}
					aria-label={t("profile.view_profile_screen_reader_button")}
					aria-describedby={displayNameId}
					sx={{ p: 0 }}
				>
					<PresenceBadge
						account={user_short}
						overlap="rectangular"
						anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
					>
						<ProfileAvatar
							variant="square"
							account={user_short}
							sx={(theme) =>
								hasThumbnail
									? {
											width: theme.spacing(7),
											height: theme.spacing(7),
										}
									: {
											width: theme.spacing(5),
											height: theme.spacing(5),
										}
							}
						/>
						<div className="sr-only" id={displayNameId}>
							{user_short.display_name}
						</div>
					</PresenceBadge>
				</IconButton>
			</Tooltip>
		);
	}

	if (ownUser?.uuid === user_short.uuid) {
		return <UserProfileDialog profile={ownUser} renderOpener={renderOpener} source="feed" />;
	}

	return (
		<FriendProfileDialog
			renderOpener={renderOpener}
			uuid={user_short.uuid}
			hideChatButton={false}
			onBlock={() => undefined}
			source="feed"
		/>
	);
}
