import SwiperZoomTarget from "@components/Swiper/SwiperZoomTarget";
import { ProfilePictureType } from "@lib/util/getProfilePictures";
import ProfilePictureGradientOverlay from "./ProfilePictureGradientOverlay";

type ProfileImageProps = {
	profileImage: ProfilePictureType;
	alt: string;
	blurred: boolean;
	onClick?: () => void;
};

export default function InnerProfileImage({ profileImage, alt, blurred, onClick }: ProfileImageProps) {
	return (
		<SwiperZoomTarget onClick={onClick}>
			{profileImage.render(
				alt,
				{
					sx: {
						display: "block",
						overflow: "hidden",
						"& > img,& > video": { objectFit: "contain" },
					},
				},
				blurred,
			)}
			<ProfilePictureGradientOverlay className="profile-picture-gradient-overlay" />
		</SwiperZoomTarget>
	);
}
