import { useTranslation } from "next-i18next";
import React from "react";
import { Button } from "./Button";
import EyeSlashIcon from "./icons/EyeSlashIcon";

export default function TapToUnblurButtonRebrand(props: Omit<React.ComponentProps<typeof Button>, "icon" | "variant">) {
	const { t } = useTranslation("common");

	return (
		<Button variant="secondary ghost" size="xxs" {...props}>
			<EyeSlashIcon />
			{t("profile.unblur_button_label_rebrand")}
		</Button>
	);
}
