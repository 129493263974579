import { AnyAccount } from "@lib/models";
import AboutMe from "./AboutMe";
import BasicInfo from "./BasicInfo";
import CompatibilityBox from "./CompatibilityBox";
import ProfileSections from "./ProfileSections";

type ProfileInfoProps = {
	profile: AnyAccount;
};

export default function ProfileInfo({ profile }: ProfileInfoProps) {
	return (
		<>
			<CompatibilityBox profile={profile} />
			<BasicInfo profile={profile} />
			<AboutMe profile={profile} />
			<ProfileSections profile={profile} />
		</>
	);
}
