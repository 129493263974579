import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { useTranslation } from "next-i18next";
import { Field } from "react-final-form";

const reasons = [
	"offensive_inappropriate_photo",
	"drugs",
	"spam_scammer",
	"advertising_solicitation",
	"underage",
	"abuse_harassment",
	"impersonation",
];

export default function ReasonsField() {
	const { t } = useTranslation("common");
	return (
		<FormGroup>
			{reasons.map((reasonKey) => {
				return (
					<Field
						key={reasonKey}
						name="reasons"
						type="checkbox"
						value={reasonKey}
						render={({ input }) => {
							return (
								<FormControlLabel control={<Checkbox {...input} />} label={t(`report_dialog.reason.${reasonKey}`)} />
							);
						}}
					/>
				);
			})}
		</FormGroup>
	);
}
