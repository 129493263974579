import { ShinyAlert } from "@lib/feature/alert/ShinyAlert";
import { NotificationProvider, useUpdateAlert } from "@lib/feature/alert/notificationContext";
import { RenderableAlertType } from "@lib/feature/alert/types";
import useAlertState from "@lib/feature/alert/useAlertState";
import useAutoDismissValid from "@lib/feature/alert/useAutoDismissValid";
import { styled } from "@mui/material/styles";
import { ReactNode, createContext, useContext } from "react";

const alertContext = createContext<RenderableAlertType | null | undefined>(undefined);
const AlertProvider = alertContext.Provider;

type AppAlertProviderProps = {
	children: ReactNode;
};

export function AppAlertProvider({ children }: AppAlertProviderProps) {
	const [alert, updateAlert] = useAutoDismissValid(useAlertState());
	return (
		<AlertProvider value={alert}>
			<NotificationProvider value={updateAlert}>{children}</NotificationProvider>
		</AlertProvider>
	);
}

const AlertContainer = styled("div")(({ theme }) => ({
	width: "100%",
	height: "0px",
	position: "relative",
	maxWidth: "480px",
	alignSelf: "center",
	zIndex: theme.zIndex.snackbar,
}));

export function AppLayoutAlert() {
	const alert = useContext(alertContext);
	const updateAlert = useUpdateAlert();
	if (alert === undefined) {
		throw new Error("AppLayoutAlert must be used a descendent of AppAlertProvider");
	}
	if (alert === null) {
		return null;
	}
	return (
		<AlertContainer>
			<ShinyAlert
				key={alert.key}
				severity={alert.severity}
				sx={{
					mx: 2.5,
					my: 2,
				}}
				variant="filled"
				onClose={() => {
					updateAlert();
				}}
			>
				{alert.children}
			</ShinyAlert>
		</AlertContainer>
	);
}
