import { accountRegisterPath } from "@api/account";
import DialogButton from "@components/DialogButton";
import useIdify from "@hooks/useIdify";
import useFeatureFlags from "@lib/feature/featureFlags/useFeatureFlags";
import SaveSwitch from "@lib/feature/settings/SaveSwitch";
import { track } from "@lib/util/trackAnalytics";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem } from "@mui/material";
import { useTranslation } from "next-i18next";

type AppMenuNSFWSwitch = {
	onClose: () => void;
};

export default function AppMenuNSFWSwitch({ onClose }: AppMenuNSFWSwitch) {
	const { t } = useTranslation("common");
	const nsfwModeOn = useFeatureFlags()?.blur_map_pins ?? false;
	const idify = useIdify();
	const labelId = idify("blur_map_pins");

	return (
		<DialogButton
			renderDialog={(open, onClose) => {
				return (
					<Dialog open={open} onClose={onClose} PaperProps={{ elevation: 0 }}>
						<DialogTitle sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
							<span id={labelId}>{t("nsfw_mode.dialog_title")}</span>
							<SaveSwitch
								accountSetting="blur_map_pins"
								labelId={labelId}
								extraMutationKeys={[accountRegisterPath]}
								onChangeFinished={(savedValue) => {
									track("NSFW_tapped", { state: savedValue });
								}}
							/>
						</DialogTitle>
						<DialogContent
							sx={(theme) => ({
								mt: 2,
								pb: 2,
								display: "flex",
								flexDirection: "column",
								gap: theme.spacing(2),
							})}
						>
							<DialogContentText>{t("nsfw_mode.dialog_body_1")}</DialogContentText>
							<DialogContentText>{t("nsfw_mode.dialog_body_2")}</DialogContentText>
						</DialogContent>
						<DialogActions sx={{ justifyContent: "flex-start" }}>
							<Button onClick={onClose}>{t("close_button")}</Button>
						</DialogActions>
					</Dialog>
				);
			}}
			renderButton={(onOpen) => (
				<MenuItem
					component="span"
					onClick={() => {
						onClose();
						onOpen();
					}}
				>
					{nsfwModeOn ? t("app_bar.nsfw_switch_on") : t("app_bar.nsfw_switch")}
				</MenuItem>
			)}
			dialogRoutingName="nsfw-switch"
		/>
	);
}
