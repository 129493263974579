import { Delete } from "@mui/icons-material";
import { ButtonProps, IconButton } from "@mui/material";

type TrashButtonRequiredProps = {
	"aria-label": string;
	onClick: () => void;
};

export default function TrashButton(props: ButtonProps & TrashButtonRequiredProps) {
	return (
		<IconButton
			color="secondary"
			size="small"
			{...props}
			sx={{
				color: "common.white",
				...props.sx,
			}}
		>
			<Delete />
		</IconButton>
	);
}
