export function mToFeetIn(length: number) {
	const feetFull = length * 3.28084;
	const feet = Math.trunc(feetFull);
	const feetDecimal = feetFull - feet;
	const inches = Math.round(feetDecimal * 12);
	if (inches === 12) {
		return {
			height_ft: feet + 1,
			height_in: 0,
		};
	}
	return {
		height_ft: feet,
		height_in: inches,
	};
}
