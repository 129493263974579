import CloseIcon from "@mui/icons-material/Close";
import { Button, ButtonProps } from "@mui/material";
import { useTranslation } from "next-i18next";

export default function CloseButton(props: ButtonProps) {
	const { t } = useTranslation("common");
	return (
		<Button
			size="small"
			variant="contained"
			aria-label={t("close_button_label")}
			{...props}
			sx={{
				position: "absolute",
				right: 8,
				top: 8,
				padding: "5px",
				height: "auto",
				width: "auto",
				minWidth: "0px",
				borderRadius: "50%",
				zIndex: "modal",
				...props.sx,
			}}
		>
			<CloseIcon />
		</Button>
	);
}
