import { Alert } from "@mui/material";
import { FieldMetaState } from "react-final-form";

type FieldSubmitError<FieldValue> = {
	id: string;
	meta: FieldMetaState<FieldValue>;
};

export default function FieldSubmitError<FieldValue>({ id, meta }: FieldSubmitError<FieldValue>) {
	if (meta.submitError != null) {
		if (!Array.isArray(meta.submitError)) {
			throw new Error(`Expected meta.submitError to be an array of strings, got ${JSON.stringify(meta.submitError)}`);
		}
		const errorMessages = meta.submitError.join(" ");
		return (
			<Alert
				id={id}
				role="presentation"
				variant="outlined"
				sx={{
					borderTopLeftRadius: "0px",
					borderTopRightRadius: "0px",
					borderStyle: "dashed",
					borderTop: "none",
					pt: 0,
				}}
				severity="error"
			>
				{errorMessages}
			</Alert>
		);
	}
	return null;
}
