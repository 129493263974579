import BackButton from "@components/BackButton";
import { getMap } from "@lib/feature/map/mapbox";
import { OtherUser, UserAccount } from "@lib/models";
import { track } from "@lib/util/trackAnalytics";
import profileClickAccountInfo, { ProfileViewSource } from "@lib/util/trackingHelpers/profileClickAccountInfo";
import { Box, Card, CardActions, CardContent } from "@mui/material";
import { useEffect } from "react";
import FriendBottomActions from "./FriendBottomActions";
import ProfileInfo from "./ProfileInfo";
import ProfilePicture from "./ProfilePicture";
import UserBottomActions from "./UserBottomActions";

export type ProfileCardProps = {
	onClose: () => void;
	onBlock?: (successMessage: string) => void;
	source: ProfileViewSource;
} & (
	| {
			profileType: "user";
			profile: UserAccount;
			hideChatButton: false;
			onBlock?: never;
	  }
	| {
			profileType: "friend";
			profile: OtherUser;
			hideChatButton: boolean;
			onBlock: (successMessage: string) => void;
	  }
);

export default function ProfileCard({
	profile,
	profileType,
	onClose,
	hideChatButton,
	onBlock,
	source,
}: ProfileCardProps) {
	useEffect(() => {
		track(
			"profile_viewed",
			profileClickAccountInfo(profile, {
				source,
				ownProfile: profileType === "user",
				zoomLevel: source === "map" ? getMap()?.getZoom() : undefined,
			}),
		);
	}, [profile, profileType, source]);

	return (
		<Card
			sx={{
				overflowY: "auto",
				position: "relative",
				display: "flex",
				flexDirection: "column",
				gap: 3,
				backgroundImage: "none",
			}}
		>
			<Box
				sx={(theme) => ({
					position: "absolute",
					top: 0,
					left: 0,
					right: 0,
					display: "flex",
					height: theme.spacing(8),
					px: theme.spacing(1.25),
					justifyContent: "space-between",
					alignItems: "center",
					zIndex: 1,
				})}
			>
				<BackButton onClick={onClose} />
			</Box>
			<ProfilePicture account={profile} profileType={profileType} />
			<CardContent sx={{ py: 0, display: "flex", flexDirection: "column", gap: 3 }}>
				<ProfileInfo profile={profile} />
			</CardContent>
			<CardActions
				sx={{
					bgcolor: "black",
					mt: "auto",
					position: "sticky",
					bottom: "0px",
					height: (theme) => theme.spacing(7),
					justifyContent: "flex-end",
					alignItems: "center",
				}}
			>
				{profileType === "friend" ? (
					<FriendBottomActions profile={profile} hideChatButton={hideChatButton} onBlock={onBlock} />
				) : (
					<UserBottomActions />
				)}
			</CardActions>
		</Card>
	);
}
