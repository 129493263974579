import { SvgIcon, SvgIconProps } from "@mui/material";

export default function EyeSlashIcon(props: SvgIconProps) {
	return (
		<SvgIcon data-testid="BKDREyeSlashIcon" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
			<g clipPath="url(#clip0_1822_5017)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M6.66972 5.25551L2.00015 0.585938L0.585938 2.00015L22.0002 23.4144L23.4144 22.0002L19.0766 17.6624C20.8739 16.313 22.2435 14.4589 22.9499 12.3128L23.0528 12.0002L22.9499 11.6875C21.4755 7.2079 17.1112 4.00012 12 4.00012C10.08 4.00012 8.26535 4.45279 6.66972 5.25551ZM9.94254 8.52833L11.474 10.0597C11.6409 10.0209 11.8167 10.0001 11.9994 10.0001C13.2281 10.0001 14.1433 10.9392 14.1433 12.0001C14.1433 12.2146 14.1059 12.4241 14.0361 12.6219L15.5232 14.109C15.915 13.5013 16.1433 12.7811 16.1433 12.0001C16.1433 9.74734 14.2434 8.00012 11.9994 8.00012C11.2574 8.00012 10.553 8.19115 9.94254 8.52833Z"
					fill="currentColor"
				/>
				<path
					d="M3.4475 7.66164C2.37425 8.80918 1.5477 10.1759 1.05018 11.6874L0.947266 12.0001L1.05017 12.3127C2.52462 16.7924 6.88887 20.0001 12.0001 20.0001C13.1525 20.0001 14.267 19.837 15.3189 19.5331L11.7804 15.9946C9.6563 15.8865 7.89373 14.2157 7.85608 12.0702L3.4475 7.66164Z"
					fill="currentColor"
				/>
			</g>
			<defs>
				<clipPath id="clip0_1822_5017">
					<rect width="24" height="24" fill="white" />
				</clipPath>
			</defs>
		</SvgIcon>
	);
}
