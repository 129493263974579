import SwiperContainer from "@components/Swiper/SwiperContainer";
import SwiperSlideZoomContainer from "@components/Swiper/SwiperSlideZoomContainer";
import useIdify from "@hooks/useIdify";
import { ProfilePictureType, ProfilePicturesListType } from "@lib/util/getProfilePictures";
import { Box, useTheme } from "@mui/material";
import { useTranslation } from "next-i18next";
import { useCallback } from "react";
import InnerProfileImage from "./InnerProfileImage";
import { PhotoRotatorState } from "./usePhotoRotatorState";

function altKey(image: ProfilePictureType) {
	if (image.type === "in_the_moment") {
		return "photo_rotator.rotator_in_the_moment_photo_alt";
	} else if (image.type === "in_the_moment_video") {
		return "photo_rotator.rotator_in_the_moment_video_alt";
	} else {
		return "photo_rotator.rotator_image_alt";
	}
}

type PhotoRotatorProps = {
	images: ProfilePicturesListType;
	state: PhotoRotatorState;
	blurred: boolean;
	zoom?: boolean;
	onClick?: (imageIndex: number) => void;
};

export default function PhotoRotator({ images, state, blurred, zoom = false, onClick }: PhotoRotatorProps) {
	const { t } = useTranslation("common");
	const idify = useIdify();
	const theme = useTheme();

	const slideChangeHandler = useCallback(
		(e: SwiperEvent) => {
			if (state === null) {
				return;
			}
			const swiper = e.currentTarget?.swiper;
			if (swiper != null) {
				state.setForwardMediaIndex(swiper.realIndex);
			}
		},
		[state],
	);

	if (state === null) {
		return null;
	}

	return (
		<>
			<p id={idify("rotator_description")} className="sr-only">
				{t("photo_rotator.rotator_screen_reader_description")}
			</p>

			<Box
				sx={{
					position: "relative",
					zIndex: 0,
					height: zoom ? "100%" : "auto",
					"& swiper-container::part(button-prev), & swiper-container::part(button-next)": {
						filter: "drop-shadow(0 0 4px rgb(0 0 0 / 0.8))",
						// padding 1 matches theme.spacing(-1) in marginTop to maintain the center
						p: 1,
						marginTop: `calc(${theme.spacing(-1)} - ((var(--swiper-navigation-size)) / 2))`,
					},
					"& swiper-container": {
						"--swiper-theme-color": theme.palette.common.white,
						"--swiper-pagination-bottom": "auto",
						"--swiper-pagination-top": theme.spacing(2.5),
						"--swiper-navigation-size": theme.spacing(3),
						height: zoom ? "100%" : "auto",
					},
				}}
			>
				<SwiperContainer
					navigation="true"
					pagination="true"
					initial-slide={state.forwardMediaIndex}
					loop={images.length > 1}
					zoom={zoom}
					virtual={process.env.NODE_ENV === "test" ? "true" : "false"} // test behavior is weird
					a11y-prev-slide-message={t("previous_button")}
					a11y-next-slide-message={t("next_button")}
					onSlideChange={slideChangeHandler}
				>
					{images.map((image) => {
						const itemNumber = images.findIndex(({ uuid: currentKey }) => currentKey === image.uuid) + 1;
						const alt = t(altKey(image), {
							itemNumber,
							totalItems: images.length,
						});
						return (
							<SwiperSlideZoomContainer key={image.uuid} aria-label={alt}>
								<InnerProfileImage
									profileImage={image}
									alt={alt}
									blurred={blurred}
									onClick={
										onClick == null
											? undefined
											: () => {
													onClick(itemNumber);
												}
									}
								/>
							</SwiperSlideZoomContainer>
						);
					})}
				</SwiperContainer>
			</Box>
		</>
	);
}
