import MapProfileAvatar from "@components/MapProfileAvatar";
import { FriendPointFeature } from "@lib/feature/map/clustering/getClustersFromPoints";
import { UserShort } from "@lib/models";
import { IconButton } from "@mui/material";
import { useTranslation } from "next-i18next";

const LARGE_CLUSTER_SIZE = 72;
const MEDIUM_CLUSTER_SIZE = 56;
const SMALL_CLUSTER_SIZE = 40;

function getButtonSize(pointCount: number): number {
	if (pointCount <= 9) {
		return SMALL_CLUSTER_SIZE;
	}
	if (pointCount <= 100) {
		return MEDIUM_CLUSTER_SIZE;
	}
	return LARGE_CLUSTER_SIZE;
}

type ClusterButtonProps = {
	points: FriendPointFeature[];
	firstFriend: UserShort;
	onClick: () => void;
};

const ClusterButton = ({ points, firstFriend, onClick }: ClusterButtonProps) => {
	const { t } = useTranslation("common");
	const pointCount = points.length;
	const buttonSize = getButtonSize(pointCount);
	return (
		<IconButton
			onClick={onClick}
			size="small"
			sx={(theme) => ({
				position: "relative",
				backgroundColor: theme.palette.text.secondary,
				width: `${buttonSize}px`,
				height: `${buttonSize}px`,
				color: "common.black",
				fontSize: theme.spacing(1.75),
				fontWeight: 500,
				"&:hover": {
					color: "common.white",
				},
				"& > div": {
					position: "absolute",
					right: "77%",
				},
			})}
		>
			{t("cluster.point_count", { pointCount: pointCount - 1 })}
			<div>
				<MapProfileAvatar account={firstFriend} />
			</div>
		</IconButton>
	);
};

export default ClusterButton;
