import { FieldError, MaybeFieldErrors } from "../formErrors";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Validator = (value: any) => MaybeFieldErrors;

function emptyToUndefined(array: Array<FieldError>): MaybeFieldErrors {
	return array.length === 0 ? undefined : array;
}

function merge(validators: Validator[]) {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return function validate(value: any) {
		return emptyToUndefined(
			validators.flatMap((validator: Validator) => {
				return validator(value) ?? [];
			}),
		);
	};
}

export default merge;
