import { Chunk } from "@lib/models";

export const CHUNK_SIZE = 0.075;

interface ChunkBoundsValues {
	center: {
		x: number;
		y: number;
	};
	xmin: number;
	xmax: number;
	ymin: number;
	ymax: number;
}

export class ChunkBounds implements ChunkBoundsValues {
	center: {
		x: number;
		y: number;
	};
	xmin: number;
	xmax: number;
	ymin: number;
	ymax: number;
	constructor({ center, xmin, xmax, ymin, ymax }: ChunkBoundsValues) {
		this.center = center;
		this.xmin = xmin;
		this.xmax = xmax;
		this.ymin = ymin;
		this.ymax = ymax;
	}
	toLngLatCorners(): Chunk {
		return {
			sw: {
				lng: (this.xmin - 0.5) * CHUNK_SIZE,
				lat: (this.ymin - 0.5) * CHUNK_SIZE,
			},
			ne: {
				lng: (this.xmax + 0.5) * CHUNK_SIZE,
				lat: (this.ymax + 0.5) * CHUNK_SIZE,
			},
		};
	}
	toCenterLngLatCorners(): Chunk {
		return {
			sw: {
				lng: (this.center.x - 0.5) * CHUNK_SIZE,
				lat: (this.center.y - 0.5) * CHUNK_SIZE,
			},
			ne: {
				lng: (this.center.x + 0.5) * CHUNK_SIZE,
				lat: (this.center.y + 0.5) * CHUNK_SIZE,
			},
		};
	}
	equalTo(otherChunkBounds?: ChunkBounds) {
		return (
			otherChunkBounds != null &&
			this.center.x === otherChunkBounds.center.x &&
			this.center.y === otherChunkBounds.center.y &&
			this.xmax === otherChunkBounds.xmax &&
			this.xmin === otherChunkBounds.xmin &&
			this.ymax === otherChunkBounds.ymax &&
			this.ymin === otherChunkBounds.ymin
		);
	}
}
