import mapboxgl from "mapbox-gl";
import { useSyncExternalStore } from "react";

const UPDATE_EVENT = "update";

const globalEvents = new EventTarget();
const state = { current: false };

function subscribe(callback: () => void) {
	globalEvents.addEventListener(UPDATE_EVENT, callback);
	return () => {
		globalEvents.removeEventListener(UPDATE_EVENT, callback);
	};
}

function getState() {
	return state.current;
}

export function setMapStatus(mapbox: mapboxgl.Map | undefined) {
	const next = mapbox != null;
	if (state.current !== next) {
		state.current = next;
		globalEvents.dispatchEvent(new Event(UPDATE_EVENT));
	}
}

export default function useMapStatus(): boolean {
	return useSyncExternalStore(subscribe, getState);
}
