import { useEffect, useRef } from "react";
import { BehaviorSubject } from "rxjs";
import useFeatureFlags from "../featureFlags/useFeatureFlags";

const CLUSTER_DELAY_TIME = 1000;

export default function useClusteringEnabled(ready: boolean) {
	const featureFlags = useFeatureFlags();
	const enabled = featureFlags?.admin_clustering_enabled ?? false;

	const enabledBehaviorRef = useRef<BehaviorSubject<boolean>>();
	if (enabledBehaviorRef.current == null) {
		enabledBehaviorRef.current = new BehaviorSubject<boolean>(false);
	}
	const enabledBehaviorSubject = enabledBehaviorRef.current;

	useEffect(() => {
		if (enabled && ready && enabledBehaviorSubject.value === false) {
			const timeoutId = setTimeout(() => {
				enabledBehaviorSubject.next(true);
			}, CLUSTER_DELAY_TIME);
			return () => {
				clearTimeout(timeoutId);
			};
		} else if (enabled === false && enabledBehaviorSubject.value === true) {
			enabledBehaviorSubject.next(false);
		}
	}, [enabled, ready, enabledBehaviorSubject]);

	return enabledBehaviorSubject;
}
