import useLocation from "@hooks/useLocation";
import { useCallback, useState } from "react";
import { getSessionMapState, removeSessionMapState, setSessionMapState } from "./mapStateSessionStorage";
export type UseMapState = { lng: number; lat: number; zoom: number };

export default function useMapState() {
	const [mapState, setMapState] = useState<UseMapState | null>(() => getSessionMapState());
	const [status] = useLocation();
	const updateMapState = useCallback(
		(newMapState: UseMapState | null) => {
			if (status !== "success") {
				return;
			}
			setMapState(newMapState);
			if (newMapState != null) {
				setSessionMapState(newMapState);
				return;
			}
			removeSessionMapState();
		},
		[status],
	);
	return { mapState, updateMapState };
}
