import MessageSquareLinesIcon from "@components/icons/MessageSquareLinesIcon";
import useUnreadCount from "@lib/feature/chat/useUnreadCount";
import useRebrandGate from "@lib/feature/landing/hooks/useRebrandGate";
import ChatFilledIcon from "@mui/icons-material/Chat";
import { Badge, SvgIconProps } from "@mui/material";

export default function ChatsNotificationIcon(props: SvgIconProps) {
	const rebrandEnabled = useRebrandGate();
	const Icon = rebrandEnabled ? MessageSquareLinesIcon : ChatFilledIcon;
	const unreadCount = useUnreadCount();
	if (unreadCount === 0) {
		return <Icon {...props} />;
	}
	return (
		<Badge
			badgeContent={unreadCount}
			color="secondary"
			sx={rebrandEnabled ? { "& .MuiBadge-badge": { height: 16, minWidth: 16 } } : {}}
		>
			<Icon {...props} />
		</Badge>
	);
}
