import useImageLoadTransition, { imageLoadTransitionTime } from "@hooks/useImageLoadTransition";
import { styled } from "@mui/material";
import { BlurhashCanvas } from "react-blurhash";

type GenericMediaProps = {
	alt: string;
	className?: string;
	media: {
		imageUrl: string | null;
		videoUrl: string | null;
		blurhash: string;
	};
};

export default styled(function GenericMedia({ alt, className, media }: GenericMediaProps) {
	const { hideBlurhash, imageProps } = useImageLoadTransition(media.imageUrl);
	// This type isn't allowed, but it crashes if it happens
	if (media.blurhash == null) {
		return null;
	}
	if (typeof media.videoUrl === "string") {
		return (
			<>
				<video
					src={media.videoUrl}
					autoPlay
					muted
					loop
					aria-hidden
					disablePictureInPicture
					playsInline
					className={className}
				/>
				<p className="sr-only">{alt}</p>
			</>
		);
	}
	return (
		<div className={className}>
			{hideBlurhash ? null : (
				<BlurhashCanvas
					hash={media.blurhash}
					// width and height set to empty strings clears the inline styles so we can control
					// it via the class(Name).
					width={32}
					height={32}
				/>
			)}
			{typeof media.imageUrl === "string" ? (
				// eslint-disable-next-line @next/next/no-img-element
				<img alt={alt} {...imageProps} />
			) : (
				<p className="sr-only">{alt}</p>
			)}
		</div>
	);
})({
	height: "100%",
	width: "100%",
	aspectRatio: "1 / 1",
	position: "relative",
	"& > *": {
		position: "absolute",
		top: "0px",
		left: "0px",
		width: "100%",
		height: "100%",
		transition: `opacity ${imageLoadTransitionTime / 1000}s ease`,
	},
});
