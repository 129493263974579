import ChevronDownIcon from "@components/icons/ChevronDownIcon";
import useIdify from "@hooks/useIdify";
import useIsImperial from "@hooks/useIsImperial";
import useObservable from "@hooks/useObservable";
import { track } from "@lib/util/trackAnalytics";
import { MenuItem, Select, Theme } from "@mui/material";
import palette from "@styles/palette";
import { useTranslation } from "next-i18next";
import { memo } from "react";
import { findClosestUnitOption, imperialRadiusOptions, metricRadiusOptions } from "./RadiusOptionUtils";
import { radiusBehavior, setRadius } from "./feedModel";

export default memo(function RadiusSelector() {
	const { t } = useTranslation("common");
	const selectId = useIdify()("select");
	const isImperial = useIsImperial();
	const radius = useObservable(radiusBehavior);

	const labelTKey = isImperial ? "feed.radius_menu_item_miles" : "feed.radius_menu_item_kilometers";
	const selectedTKey = isImperial
		? "feed.radius_menu_item_miles_selected"
		: "feed.radius_menu_item_kilometers_selected";
	const options = isImperial ? imperialRadiusOptions : metricRadiusOptions;
	const units = isImperial ? "imperial" : "metric";
	const selection = findClosestUnitOption(radius, units);

	return (
		<>
			<label htmlFor={selectId} className="sr-only">
				{t("feed.radius_select_screen_reader_label")}
			</label>
			<Select
				labelId={selectId}
				renderValue={(label) => t(selectedTKey, { count: label })}
				size="small"
				value={selection.label}
				IconComponent={ChevronDownIcon}
				MenuProps={{ slotProps: { paper: { sx: { mt: "-1px" } } } }}
				sx={(theme) => ({
					color: palette.onSurface,
					fontFamily: "var(--heading-font)",
					fontSize: "16px",
					lineHeight: theme.spacing(3),
					border: "none",
					borderRadius: 0,
					"& .MuiOutlinedInput-input.MuiSelect-select.MuiSelect-outlined": {
						paddingRight: theme.spacing(6),
						paddingLeft: theme.spacing(2.5),
						py: theme.spacing(1.25),
					},
					"&.MuiInputBase-root": {
						"&.Mui-focused": {
							boxShadow: `inset 0 0 0px 1px ${palette.onSurface}`,
						},
						fieldset: {
							border: "none",
							borderRadius: 0,
						},
						"&:hover": {
							boxShadow: `inset 0 0 0px 1px ${palette.onSurface}`,
						},
					},
					"& .MuiSelect-select": {
						borderRadius: 0,
					},
					"& svg": {
						right: theme.spacing(2.5),
						width: theme.spacing(2.5),
						height: theme.spacing(2.5),
						top: "calc(50% - 0.38em)",
					},
				})}
			>
				{options.map((option) => (
					<MenuItem
						key={option.value}
						value={option.label}
						onClick={() => {
							track("feed_radius_changed", { radius: option.value, units });
							setRadius(option.value);
						}}
						sx={(theme: Theme) => ({
							height: theme.spacing(5),
						})}
					>
						{t(labelTKey, { count: option.label })}
					</MenuItem>
				))}
			</Select>
		</>
	);
});
