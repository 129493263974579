import { Checkbox, FormControlLabel } from "@mui/material";
import { useTranslation } from "next-i18next";
import { Field } from "react-final-form";

export default function BlockField() {
	const { t } = useTranslation("common");

	return (
		<Field
			name="block"
			type="checkbox"
			render={({ input, meta }) => {
				return (
					<FormControlLabel
						control={<Checkbox {...input} disabled={meta.submitting} />}
						label={t("report_dialog.block_user_label")}
					/>
				);
			}}
		/>
	);
}
