import { accountRegisterPath } from "@api/account";
import { UpdateLocationRandomizationRequest, updateLocationRandomization } from "@api/profile";
import DialogButton from "@components/DialogButton";
import { indicateLoading } from "@components/GlobalLoader";
import LocationRandomizationSlider from "@components/slider/LocationRandomizationSlider";
import useIdify from "@hooks/useIdify";
import useLocation from "@hooks/useLocation";
import useUser from "@hooks/useUser";
import { ShinyAlert } from "@lib/feature/alert/ShinyAlert";
import useAlertState from "@lib/feature/alert/useAlertState";
import useFeatureFlags from "@lib/feature/featureFlags/useFeatureFlags";
import { getMap } from "@lib/feature/map/mapbox";
import mapConfig from "@lib/feature/map/mapbox/mapConfig";
import reportError from "@lib/util/reportError";
import { track } from "@lib/util/trackAnalytics";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem } from "@mui/material";
import { useTranslation } from "next-i18next";
import { useState } from "react";
import { useSWRConfig } from "swr";

type AppMenuLocationRandomization = {
	onClose: () => void;
};

export default function AppMenuLocationRandomization({ onClose: menuOnClose }: AppMenuLocationRandomization) {
	const { t } = useTranslation("common");
	const idify = useIdify();
	const labelId = idify("location_randomization");
	const user = useUser();
	const { mutate } = useSWRConfig();
	const [value, setValue] = useState(user?.location_randomization ?? 0);
	const [alert, updateAlert] = useAlertState();
	const [, position] = useLocation();
	const [loading, setLoading] = useState(false);
	const featureFlags = useFeatureFlags();

	return (
		<DialogButton
			renderDialog={(open, onClose) => {
				async function handleSave() {
					setLoading(true);
					const stopLoading = indicateLoading("location randomization request");

					try {
						const params: UpdateLocationRandomizationRequest = { location_randomization: value };

						if (position != null) {
							params.lat = position.coords.latitude;
							params.lng = position.coords.longitude;
							params.source = "precise";
						} else if (featureFlags?.ip_geolocated_location != null) {
							params.lat = featureFlags.ip_geolocated_location.lat;
							params.lng = featureFlags.ip_geolocated_location.lng;
							params.source = "ip";
						}

						await updateLocationRandomization(params);
						track("location_randomized", { distance_selected: value });
						onClose();
					} catch (error) {
						reportError(error as Error);
						updateAlert(t("unknown_error"));
					} finally {
						await mutate(accountRegisterPath);
						stopLoading();
						setLoading(false);
						if (user?.lng != null && user?.lat != null) {
							const mapbox = getMap();
							mapbox?.easeTo({ center: [user.lng, user.lat], zoom: mapConfig.zoom });
						}
					}
				}

				return (
					<Dialog open={open} onClose={onClose} PaperProps={{ elevation: 0 }} sx={{ p: 0 }}>
						<DialogTitle sx={{ display: "flex", justifyContent: "space-between", alignItems: "left", px: 2 }}>
							<span id={labelId}>{t("location_randomization.dialog_title")}</span>
						</DialogTitle>
						<DialogContent
							sx={(theme) => ({
								display: "flex",
								flexDirection: "column",
								gap: theme.spacing(1),
								pb: 2,
								px: 2,
							})}
						>
							<DialogContentText variant="body2">
								<span id={labelId}>{t("location_randomization.dialog_body_1")}</span>
							</DialogContentText>
							<LocationRandomizationSlider
								value={value}
								onChange={(_event, value) => {
									if (typeof value == "number") {
										setValue(value);
									}
								}}
							/>
						</DialogContent>
						<DialogActions sx={{ justifyContent: "flex-start", p: 2 }}>
							<Button
								type="submit"
								fullWidth
								variant={"outlined"}
								sx={{
									mb: 2,
									bottom: (theme) => theme.spacing(2),
								}}
								onClick={handleSave}
								disabled={loading}
							>
								{t("save_button")}
							</Button>
						</DialogActions>
						{alert == null ? undefined : (
							<ShinyAlert
								key={alert.key}
								severity={alert.severity}
								variant="filled"
								onClose={() => {
									updateAlert();
								}}
							>
								{alert.children}
							</ShinyAlert>
						)}
					</Dialog>
				);
			}}
			renderButton={(onOpen) => (
				<MenuItem
					component="span"
					onClick={() => {
						menuOnClose();
						onOpen();
					}}
				>
					{t("app_bar.location_privacy")}
				</MenuItem>
			)}
			dialogRoutingName="location-randomization"
		/>
	);
}
