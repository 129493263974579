import { useLocaleWithCountry } from "@hooks/useLocale";
import { isImperialLocale } from "@lib/util/isImperialLocale";
import useUser from "./useUser";

export default function useIsImperial() {
	const locale = useLocaleWithCountry();
	const assumedImperial = isImperialLocale(locale);
	const userAccount = useUser();
	return userAccount?.units_override ? userAccount.units_override === "imperial" : assumedImperial;
}
