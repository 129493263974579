import { SvgIcon, SvgIconProps } from "@mui/material";

export default function MessageSquareLinesIcon(props: SvgIconProps) {
	return (
		<SvgIcon data-testid="BKDRMessageSquareLinesIcon" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M1 2H23V20H11.2868L6 23.3042V20H1V2ZM6 8H18V10H6V8ZM6 12H13V14H6V12Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
}
