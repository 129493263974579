import TrashButton from "@components/TrashButton";
import { UserAccount } from "@lib/models";
import { Box, Typography } from "@mui/material";
import { Trans, useTranslation } from "next-i18next";
import { CurrentImageElement } from "./CurrentMediaElement";
import CurrentVideo from "./CurrentVideo";
import ProcessingVideo from "./ProcessingVideo";

type CurrentStatusProps = {
	inTheMoment: NonNullable<UserAccount["in_the_moment_status"]>;
	onDelete: () => void;
	deleting: boolean;
};

export default function CurrentStatus({ inTheMoment, onDelete, deleting }: CurrentStatusProps) {
	const { t } = useTranslation("common");
	const noMedia = inTheMoment.full === null && inTheMoment.video === null && inTheMoment.processing !== true;
	return (
		<Box
			component="section"
			sx={{
				position: "relative",
				mt: 2,
				mx: -0.5,
				border: "solid 1px #aaa",
				borderRadius: "4px",
				p: 1.5,
				...(noMedia
					? {
							display: "flex",
							flexDirection: "row",
							alignItems: "flex-start",
							gap: "8px",
						}
					: null),
			}}
		>
			<h3 className="sr-only">{t("in_the_moment.current_screen_reader_section_heading")}</h3>
			<TrashButton
				aria-label={t("in_the_moment.screen_reader_label_delete_current_status_button")}
				onClick={onDelete}
				disabled={deleting}
				sx={
					noMedia
						? {
								flex: "0 0 auto",
							}
						: {
								position: "absolute",
								top: (theme) => theme.spacing(1),
								left: (theme) => theme.spacing(1),
							}
				}
			/>
			{inTheMoment.full === null ? null : (
				<CurrentImageElement sx={{ mx: "auto" }} alt={t("in_the_moment.current_photo_alt")} src={inTheMoment.full} />
			)}
			{inTheMoment.video === null ? null : <CurrentVideo videoUrl={inTheMoment.video} />}
			{inTheMoment.processing ? <ProcessingVideo /> : null}
			{inTheMoment.message === "" ? null : (
				<Typography variant="body2">
					<Trans
						t={t}
						i18nKey="in_the_moment.current_status"
						values={{ userStatusMessage: inTheMoment.message }}
						// it's not that kind of array
						// eslint-disable-next-line react/jsx-key
						components={[<Typography component="span" variant="body2" />]}
					/>
				</Typography>
			)}
		</Box>
	);
}
