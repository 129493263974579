import { VisibilityOffOutlined } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import { useTranslation } from "next-i18next";

type TapToUnblurButtonProps = {
	onClick: () => void;
};

export default function TapToUnblurButton({ onClick }: TapToUnblurButtonProps) {
	const { t } = useTranslation("common");

	return (
		<Button
			sx={(theme) => ({
				position: "absolute",
				color: theme.palette.common.white,
				fontSize: theme.typography.body1.fontSize,
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContents: "center",
				top: "50%",
				left: "50%",
				transform: "translate(-50%, -50%)",
				borderRadius: "4px",
				"&.MuiButton-root": {
					backgroundColor: "rgba(0, 0, 0, 0.25)",
					"&:hover": { backgroundColor: "rgba(0, 0, 0, 0.15)" },
				},
				py: 1,
				px: 2,
			})}
			onClick={onClick}
		>
			<VisibilityOffOutlined
				fontSize="large"
				sx={(theme) => ({
					color: theme.palette.common.white,
				})}
			/>
			<Typography sx={{ color: "common.white" }}>{t("profile.unblur_button_label")}</Typography>
		</Button>
	);
}
