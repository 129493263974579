import ImageFileInput from "@components/ImageFileInput";
import MediaButton from "@components/MediaButton";
import { UploadPhotoDataType } from "@lib/models";
import CameraIcon from "@mui/icons-material/CameraAlt";
import { Dialog } from "@mui/material";
import { ReactNode, useState } from "react";
import CropperView from "./CropperView";

type PhotoInputProps = {
	helperTextId: string;
	onUpload: (uploadData: UploadPhotoDataType) => void;
	onError: (error: string) => void;
	inputLabelText: string;
	cropButtonLabel: ReactNode;
	inTheMoment?: boolean;
	disabled?: boolean;
};

export default function PhotoInput({
	helperTextId,
	onUpload,
	onError,
	inputLabelText,
	cropButtonLabel,
	inTheMoment = false,
	disabled = false,
}: PhotoInputProps) {
	const [validFile, setValidFile] = useState<File | null>(null);

	return (
		<>
			<ImageFileInput
				renderLabel={(labelProps) => {
					return (
						<MediaButton component="label" {...labelProps}>
							<CameraIcon fontSize="large" />
						</MediaButton>
					);
				}}
				inputLabelText={inputLabelText}
				onFileSelected={setValidFile}
				onError={onError}
				disabled={disabled}
				isInTheMoment={inTheMoment}
				helperTextId={helperTextId}
			/>
			{validFile != null ? (
				<Dialog
					open={true}
					sx={{ backgroundColor: "common.black" }}
					PaperProps={{
						sx: {
							backgroundColor: "paper.background",
							backgroundImage: "none",
							pt: "env(safe-area-inset-top, 0)",
							pb: "env(safe-area-inset-bottom, 0)",
						},
					}}
					fullScreen
				>
					<CropperView
						inputFile={validFile}
						onCancel={() => {
							setValidFile(null);
						}}
						onCropFinish={(uploadData: UploadPhotoDataType) => {
							onUpload(uploadData);
							setValidFile(null);
						}}
						cropButtonLabel={cropButtonLabel}
					/>
				</Dialog>
			) : null}
		</>
	);
}
