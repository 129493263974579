import { LngLatLike } from "mapbox-gl";
import Supercluster, { PointFeature } from "supercluster";
import { ClusterPin, FriendPin, Pin } from "../pin";
import { ChunkBounds } from "../userLoading/chunks";
import getTopFriend from "./ClusterButton/getTopFriend";

export type FriendPointFeature = PointFeature<{ friendPin: FriendPin }>;

function geoJsonFeatureFromPoint(friendPin: FriendPin): FriendPointFeature {
	return {
		type: "Feature",
		properties: { friendPin },
		geometry: {
			type: "Point",
			coordinates: [friendPin.lng, friendPin.lat],
		},
	};
}

function clusterSort(a: Pin, b: Pin) {
	const aIsCluster = a instanceof ClusterPin;
	const bIsCluster = b instanceof ClusterPin;
	if ((aIsCluster && bIsCluster) || (!aIsCluster && !bIsCluster)) {
		return 0;
	}
	if (aIsCluster) {
		return 1;
	}
	return -1;
}

export type FlyToPoint = { center: LngLatLike; zoom: number };
export type SuperclusterConfig = { maxZoom: number; radius: number };

export default function getClustersFromPoints(
	points: FriendPin[],
	zoom: number,
	chunkBounds: ChunkBounds,
	clusterCallback: (flyToPoint: FlyToPoint) => void,
	clusterConfig: SuperclusterConfig,
): Pin[] {
	const supercluster = new Supercluster(clusterConfig);

	supercluster.load(points.map(geoJsonFeatureFromPoint));

	const { sw, ne } = chunkBounds.toLngLatCorners();
	const clustersAndPoints = supercluster.getClusters([sw.lng, sw.lat, ne.lng, ne.lat], zoom);

	return clustersAndPoints
		.map((clusterOrPoint) => {
			if (clusterOrPoint.properties.cluster != null) {
				const leaves = supercluster.getLeaves(clusterOrPoint.properties.cluster_id) as FriendPointFeature[];
				const firstFriend = getTopFriend(leaves);
				return new ClusterPin(
					{
						id: "friend_cluster:" + firstFriend.uuid,
						lng: clusterOrPoint.geometry.coordinates[0],
						lat: clusterOrPoint.geometry.coordinates[1],
					},
					leaves,
					firstFriend,
					() =>
						clusterCallback({
							center: [clusterOrPoint.geometry.coordinates[0], clusterOrPoint.geometry.coordinates[1]],
							zoom: supercluster.getClusterExpansionZoom(clusterOrPoint.properties.cluster_id),
						}),
				);
			}
			return clusterOrPoint.properties.friendPin;
		})
		.sort(clusterSort);
}
