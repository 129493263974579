import { useEffect, useState } from "react";
import { BehaviorSubject, Observable } from "rxjs";

function useObservable<T>(observable: BehaviorSubject<T>): T;
function useObservable<T>(observable: Observable<T>): T | undefined;
function useObservable<T>(observable: Observable<T> | BehaviorSubject<T>) {
	type R = typeof observable extends BehaviorSubject<T> ? T : T | undefined;

	const [value, setValue] = useState<R>(() => (observable instanceof BehaviorSubject ? observable.value : undefined));

	useEffect(() => {
		const subscription = observable.subscribe((value) => {
			setValue(value);
		});
		return () => {
			subscription.unsubscribe();
		};
	}, [observable]);

	return value;
}

export default useObservable;
