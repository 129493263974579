import { accountRegisterPath } from "@api/account";
import { ServerFormError } from "@api/commonErrors";
import { uploadMedia } from "@api/media";
import { AddInTheMomentStatusRequest, addInTheMomentStatus } from "@api/profile";
import useIdify from "@hooks/useIdify";
import useUser from "@hooks/useUser";
import * as feedModel from "@lib/feature/Feed/feedModel";
import { ShinyAlert } from "@lib/feature/alert/ShinyAlert";
import { useUpdateAlert } from "@lib/feature/alert/notificationContext";
import { updateAlertType } from "@lib/feature/alert/types";
import useAlertState from "@lib/feature/alert/useAlertState";
import { UploadPhotoDataType } from "@lib/models";
import assertType from "@lib/util/assertType";
import checkForMobile from "@lib/util/checkForMobile";
import { makeHandleFormSubmit } from "@lib/util/makeHandleFormSubmit";
import { Box, Button, DialogActions, DialogContent, DialogContentText, Link, Typography } from "@mui/material";
import { FORM_ERROR } from "final-form";
import createDecorator from "final-form-focus";
import { useTranslation } from "next-i18next";
import { Form } from "react-final-form";
import { useSWRConfig } from "swr";
import useAutoDismissValid from "../alert/useAutoDismissValid";
import PhotoField from "./PhotoField";
import StatusMessageField from "./StatusMessageField";
import VideoField from "./VideoField";

type InTheMomentFormValues = {
	message?: string;
	image?: UploadPhotoDataType;
	video?: Blob;
};

const focusOnError = createDecorator<InTheMomentFormValues>();
const formDecorators = [focusOnError];

const dialogChildrenPadding = { px: 2 };

type InTheMomentProps = {
	onClose: (reason: "cancel" | "success") => void;
	onOpenAnonRegister: () => void;
};

export default function InTheMomentForm({ onClose, onOpenAnonRegister }: InTheMomentProps) {
	const { t } = useTranslation("common");
	const idify = useIdify();
	const user = assertType(useUser());
	const { mutate } = useSWRConfig();
	const [, appUpdateAlert] = useAutoDismissValid([null, useUpdateAlert()]);
	const [alert, localUpdateAlert] = useAlertState();
	const updateAlert: updateAlertType = function updateAlert(message, variant) {
		if (variant === "success") {
			appUpdateAlert(message, variant);
		}
		localUpdateAlert(message, variant);
	};
	const isDesktop = !checkForMobile();

	const onSubmit = makeHandleFormSubmit<InTheMomentFormValues>({
		async submitter(values: InTheMomentFormValues) {
			const params: AddInTheMomentStatusRequest = { in_the_moment_status: { message: values.message } };

			if (values.image != null) {
				const mediaKey = await uploadMedia(values.image.file);

				params.in_the_moment_status.image = {
					id: mediaKey,
					metadata: {
						crop: {
							x: values.image.x,
							y: values.image.y,
							width: values.image.width,
							height: values.image.height,
						},
					},
				};
			} else if (values.video != null) {
				const mediaKey = await uploadMedia(values.video);

				params.in_the_moment_status.video = {
					id: mediaKey,
				};
			}

			await addInTheMomentStatus(params);
			if (values.video != null) {
				appUpdateAlert(t("in_the_moment.recording_upload_started"), "info");
			}
			if (values.image == null && values.video == null) {
				updateAlert(t("in_the_moment.status_posted_success_message"), "success");
			}
			await mutate(accountRegisterPath);
			feedModel.refresh();
			onClose("success");
			return undefined;
		},
		successMessage: null, // success message sent by server over realtime for photos
		unknownErrorMessage: t("unknown_error"),
		updateAlert,
		errorHandlers: [
			{
				errorType: ServerFormError,
				result: (error) => {
					// TODO find better errorHandlers type
					return (error as ServerFormError).data.error;
				},
			},
		],
	});

	return (
		<Form
			onSubmit={onSubmit}
			decorators={formDecorators}
			validate={(values) => {
				if (values.image == null && values.video == null && [undefined, ""].includes(values.message)) {
					// non display error, this is used for disabling submission
					return {
						[FORM_ERROR]: "empty",
					};
				}
				return {};
			}}
			render={({ handleSubmit, hasValidationErrors, error, submitting, values }) => {
				const formEmpty = error === "empty";
				return (
					<form
						onSubmit={
							formEmpty
								? (event) => {
										event.preventDefault();
										updateAlert(t("in_the_moment.must_include_message_or_photo_error"), "error");
									}
								: handleSubmit
						}
					>
						<DialogContent
							sx={{
								...dialogChildrenPadding,
								pb: 0,
								overflowY: "hidden",
							}}
						>
							<Typography component="h2" variant="h5">
								{user.is_anon ? t("anon_mode.info_modal_header") : t("in_the_moment.dialog_title")}
							</Typography>
							{user.is_anon ? (
								<DialogContentText variant="body2">{t("anon_mode.info_modal_body")}</DialogContentText>
							) : null}
						</DialogContent>
						<DialogContent
							sx={{
								...dialogChildrenPadding,
								display: "flex",
								pb: 0,
								flexDirection: "column",
								gap: "16px",
							}}
						>
							<DialogContentText variant="body2">{t("in_the_moment.add_dialog_subtitle")}</DialogContentText>
							<Box
								sx={{
									position: "relative",
									display: "flex",
									justifyContent: "center",
									gap: (theme) => theme.spacing(2),
								}}
							>
								{values.video == null && !isDesktop ? <PhotoField disabled={isDesktop} /> : null}
								{values.image == null ? <VideoField /> : null}
							</Box>
							<StatusMessageField />
							{alert == null ? null : (
								<ShinyAlert
									key={alert.key}
									severity={alert.severity}
									variant="filled"
									onClose={() => {
										updateAlert();
									}}
									sx={{ mx: 0, mt: 0, mb: 1 }}
								>
									{alert.children}
								</ShinyAlert>
							)}
						</DialogContent>
						{formEmpty ? (
							<p id={idify("empty_form_helper")} hidden>
								{t("in_the_moment.must_include_message_or_photo_error")}
							</p>
						) : null}
						<DialogActions sx={{ ...dialogChildrenPadding, justifyContent: "flex-start" }}>
							<Button
								size="small"
								variant="outlined"
								type="button"
								onClick={() => {
									onClose("cancel");
								}}
								disabled={submitting}
							>
								{t("cancel_button")}
							</Button>
							<Button
								size="small"
								variant={hasValidationErrors ? "outlined" : "contained"}
								type="submit"
								aria-describedby={formEmpty ? idify("empty_form_helper") : ""}
								disabled={submitting}
							>
								{t("save_button")}
							</Button>
						</DialogActions>
						{user.is_anon ? (
							<DialogActions sx={{ ...dialogChildrenPadding, justifyContent: "flex-start" }}>
								<Link component="button" type="button" onClick={onOpenAnonRegister}>
									{t("forgot_password.create_account_link")}
								</Link>
							</DialogActions>
						) : null}
					</form>
				);
			}}
		/>
	);
}
