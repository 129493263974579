import useNavModalState from "@hooks/useNavModalState";
import { useUpdateAlert } from "@lib/feature/alert/notificationContext";
import { Dialog, useMediaQuery, useTheme } from "@mui/material";
import { ReactNode, useCallback } from "react";

export type RenderOpener = (onOpen: () => void) => ReactNode;

type ProfileDialogProps = {
	renderOpener: RenderOpener;
	onBlock?: () => void;
	children: (handlers: { onClose: () => void; onBlock: (message: string) => void }) => ReactNode;
	uuid: string;
};

export default function ProfileDialog({
	renderOpener,
	uuid,
	onBlock: outerOnBlock = () => undefined,
	children,
}: ProfileDialogProps) {
	const { open: profileOpen, setOpen } = useNavModalState(`viewing-profile-${uuid}`);
	const updateAlert = useUpdateAlert();

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

	const onOpen = useCallback(() => {
		setOpen(true);
	}, [setOpen]);

	function onClose() {
		setOpen(false);
	}

	function onBlock(successMessage: string) {
		onClose();
		outerOnBlock();
		updateAlert(successMessage, "success");
	}

	return (
		<>
			{renderOpener(onOpen)}
			<Dialog
				fullWidth={!fullScreen}
				fullScreen={fullScreen}
				open={profileOpen}
				onClose={onClose}
				PaperProps={{
					sx: {
						background: "none",
						boxShadow: "none",
						pointerEvents: "none",
						justifyContent: "stretch",
						width: fullScreen ? undefined : "calc(100% - 48px)",
						mx: fullScreen ? undefined : 3,
						pt: fullScreen ? "env(safe-area-inset-top, 0)" : 0,
						pb: fullScreen ? "env(safe-area-inset-bottom, 0)" : 0,
						"> *": {
							pointerEvents: "auto",
							flex: "1 1 auto",
						},
					},
				}}
			>
				{children({ onBlock, onClose })}
			</Dialog>
		</>
	);
}
