import { styled } from "@mui/material";
const mediaStyles = {
	display: "block",
	width: "160px",
	height: "160px",
	marginBottom: "8px",
	borderRadius: "4px",
};

// all tested in CurrentStatus.test.tsx
export const CurrentVideoElement = styled("video")(mediaStyles);
export const CurrentVideoPlaceholder = styled("div")(mediaStyles);
export const CurrentImageElement = styled("img")(mediaStyles);
