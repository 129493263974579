import { DefaultLocaleCode, LocaleCode } from "@lib/models";
import notoSans from "./notoSans";
import rubik from "./rubik";
import vcrOsdMono from "./vcrOsdMono";

type FontModuleNames =
	| "notoSans"
	| "notoSansArabic"
	| "notoSansThai"
	| "notoSansKR"
	| "notoSansJP"
	| "notoSansSC"
	| "notoSansTC";

export function makeFontVariables(fontFamily: string | undefined = undefined) {
	return {
		dangerouslySetInnerHTML: {
			__html: `
				html {
					--main-font: ${rubik.style.fontFamily};
					--second-font: ${rubik.style.fontFamily};
					--heading-font: ${vcrOsdMono.style.fontFamily};
					--heading-font-fallback: ${fontFamily ?? notoSans.style.fontFamily};
				}
			`,
		},
	};
}

export const initialFontHeadStyleProps = makeFontVariables();

const localeToFontModule: Record<LocaleCode | "default", FontModuleNames> = {
	default: "notoSans",
	en: "notoSans",
	de: "notoSans",
	es: "notoSans",
	fr: "notoSans",
	it: "notoSans",
	nl: "notoSans",
	pt: "notoSans",
	vi: "notoSans",
	el: "notoSans",
	ru: "notoSans",
	ar: "notoSansArabic",
	th: "notoSansThai",
	ko: "notoSansKR",
	ja: "notoSansJP",
	"zh-CN": "notoSansSC",
	"zh-TW": "notoSansTC",
};

export async function getLocaleFont(locale: DefaultLocaleCode | undefined) {
	if (locale == null) {
		return initialFontHeadStyleProps;
	}

	const moduleName = localeToFontModule[locale];
	if (moduleName === "notoSans") {
		return initialFontHeadStyleProps;
	}

	const localeFont = await import(`@lib/fonts/${moduleName}.ts`);
	return makeFontVariables(localeFont.default.style.fontFamily);
}
