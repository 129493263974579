import { MapboxOptions } from "mapbox-gl";

const mapConfig: MapboxOptions = {
	container: "error - use real container",
	style: process.env.NEXT_PUBLIC_MAPBOX_STYLE_LAYER,
	projection: { name: "globe" as const },
	testMode: process.env.NEXT_PUBLIC_ENVIRONMENT === "test" ? true : false,
	doubleClickZoom: true,
	dragRotate: false,
	zoom: 12,
	minZoom: 8,
	maxZoom: 16,
	pitch: 0,
	minPitch: 0,
	maxPitch: 0,
	pitchWithRotate: false,
	touchPitch: false,
};

export default mapConfig;
