import { FieldTag } from "@components/MultiSelectField/configTypes";
import renderValueDefaultChips from "@components/MultiSelectField/renderValueDefaultChips";
import renderValueDefaultList from "@components/MultiSelectField/renderValueDefaultList";
import { isMatchableCategory } from "@lib/feature/profile/ProfileForm/getMatchingFriendCategories";
import { AnyAccount } from "@lib/models";
import { Box, Typography } from "@mui/material";
import clsx from "clsx";

// tested in l@ib/components/ProfileDialog/ProfileInfo/index.test.tsx
export function SectionField({
	field,
	label,
	values,
	labelId,
	sectionId,
}: {
	field: keyof AnyAccount;
	label: string;
	values: FieldTag[];
	labelId: string;
	sectionId: string;
}) {
	return (
		<Box
			component="li"
			sx={{
				listStyle: "none",
				mx: 0,
				my: 0,
				p: 0,
			}}
		>
			<Typography
				id={labelId}
				aria-describedby={sectionId}
				variant="caption"
				sx={{
					color: (theme) => theme.palette.text.secondary,
				}}
			>
				{label}
			</Typography>

			{isMatchableCategory(field) ? (
				renderValueDefaultChips(values, labelId)
			) : (
				<Typography aria-describedby={clsx(sectionId, labelId)} variant="body2">
					{renderValueDefaultList(values, labelId)}
				</Typography>
			)}
		</Box>
	);
}
