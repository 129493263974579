import { useLocaleWithCountry } from "@hooks/useLocale";
import { memo } from "react";
import { FieldTag } from "./configTypes";

type DefaultListProps = {
	values: FieldTag[];
	describedby: string;
};

const DefaultList = memo(function DefaultList({ values, describedby }: DefaultListProps) {
	const locale = useLocaleWithCountry();
	const formatter = new Intl.ListFormat(locale, { style: "narrow", type: "conjunction" });
	const list = formatter.format(values.map(({ translatedValue }) => translatedValue));
	return <span aria-describedby={describedby}>{list}</span>;
});

export default function renderValueDefaultList(values: FieldTag[], describedby: string) {
	return <DefaultList values={values} describedby={describedby} />;
}
