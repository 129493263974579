import CloseButton from "@components/CloseButton";
import useUser from "@hooks/useUser";
import assertType from "@lib/util/assertType";
import { Dialog } from "@mui/material";
import RegisterAnonModal from "../anon/RegisterAnonModal";
import InTheMomentForm from "./InTheMomentForm";
import Manager from "./Manager";

type InTheMomentProps = {
	open: boolean;
	onClose: () => void;
};

export default function InTheMoment({ open, onClose }: InTheMomentProps) {
	const user = assertType(useUser());

	return (
		<RegisterAnonModal
			renderButton={(onOpenRegisterAnon) => (
				<Dialog open={open} onClose={onClose} PaperProps={{ elevation: 0 }}>
					<CloseButton onClick={onClose} />
					{user.in_the_moment_status == null ? (
						<InTheMomentForm
							onClose={onClose}
							onOpenAnonRegister={() => {
								onClose();
								onOpenRegisterAnon();
							}}
						/>
					) : (
						<Manager
							inTheMoment={user.in_the_moment_status}
							onClose={onClose}
							onOpenAnonRegister={() => {
								onClose();
								onOpenRegisterAnon();
							}}
						/>
					)}
				</Dialog>
			)}
		/>
	);
}
