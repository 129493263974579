import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { MenuItem } from "@mui/material";
import { Trans, useTranslation } from "next-i18next";

type AppMenuSupportLinksProps = {
	href: string;
	i18nKey: string;
	onClose: () => void;
};

export default function AppMenuExternalLink({ href, i18nKey, onClose }: AppMenuSupportLinksProps) {
	const { t } = useTranslation("common");

	return (
		<MenuItem component="a" href={href} target="_blank" onClick={onClose}>
			<Trans t={t} i18nKey={i18nKey} components={[<span className="sr-only" key="0" />]} />
			<OpenInNewIcon />
		</MenuItem>
	);
}
