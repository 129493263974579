import useIdify from "@hooks/useIdify";
import useUser from "@hooks/useUser";
import getMatchingFriendCategories from "@lib/feature/profile/ProfileForm/getMatchingFriendCategories";
import { AnyAccount } from "@lib/models";
import { Typography } from "@mui/material";
import { useTranslation } from "next-i18next";
import { FieldValue, generateFieldWithTags } from "./ProfileSections";
import Section from "./Section";
import { SectionField } from "./Section/SectionField";

export default function AboutMe({ profile }: { profile: AnyAccount }) {
	const { t } = useTranslation("common");
	const idify = useIdify();
	const user = useUser();

	const sectionId = idify("about-me-section");
	const labelId = idify("about-me-id");
	const hasAboutMe = profile.about_me !== "";
	const hasLanguagesSpoken = profile.languages_spoken.length > 0;

	if (hasAboutMe === false && hasLanguagesSpoken === false) {
		return null;
	}

	const friendMatches = getMatchingFriendCategories(user, profile);

	const fields: FieldValue[] = [
		{
			label: t("profile.languages_spoken_field_label"),
			field: "languages_spoken",
			translationPrefix: "tag_languages_spoken_options",
		},
	];

	const languagesSpokenFieldWithTags = generateFieldWithTags({ profile, fields, friendMatches, t });

	return (
		<Section header={t("profile.section_about_me_heading")}>
			{hasAboutMe ? (
				<>
					<span className="sr-only">{t("profile.bio_field_label")}</span>
					<Typography variant="body2" sx={(theme) => ({ color: theme.palette.text.secondary })}>
						{profile.about_me}
					</Typography>
				</>
			) : null}
			{hasLanguagesSpoken
				? languagesSpokenFieldWithTags.map(({ field, label, values }) => (
						<SectionField
							key={field}
							field={field}
							label={label}
							values={values}
							labelId={labelId}
							sectionId={sectionId}
						/>
					))
				: null}
		</Section>
	);
}
