import { Observable, ObservableInputTuple } from "rxjs";

export default function mergeEndWithAny<A extends readonly unknown[]>(
	...sources: [...ObservableInputTuple<A>]
): Observable<A[number]> {
	return new Observable((subscriber) => {
		const subscriptions = (sources as Observable<unknown>[]).map((observable) =>
			observable.subscribe({
				next(value) {
					subscriber.next(value);
				},
				error(err) {
					subscriber.error(err);
				},
				complete() {
					subscriber.complete();
				},
			}),
		);
		return () => {
			subscriptions.forEach((subscription) => {
				subscription.unsubscribe();
			});
		};
	});
}
