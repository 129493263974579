import { SvgIcon, SvgIconProps } from "@mui/material";

export default function MenuIcon(props: SvgIconProps) {
	return (
		<SvgIcon data-testid="BKDRMenuIcon" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
			<rect x="0" y="1.33" width="24" height="5.33" fill="currentColor" />
			<rect y="17.33" width="24" height="5.33" fill="currentColor" />
		</SvgIcon>
	);
}
