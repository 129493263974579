import ProfileAvatar from "@components/ProfileAvatar";
import useUser from "@hooks/useUser";
import { Avatar, Box, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { useTranslation } from "next-i18next";
import { useRef, useState } from "react";
import AppMenuOptions from "./AppMenuOptions";

const avatarStyles = {
	width: "48px",
	height: "48px",
	"& .MuiSvgIcon-root": {
		height: "24px",
		width: "24px",
	},
};

export default function AvatarMenu() {
	const { t } = useTranslation("common");
	const user = useUser();
	// user can't be undefined here, because of parent page rendering
	const buttonRef = useRef<HTMLButtonElement | null>(null);
	const [menuOpen, setMenuOpen] = useState(false);
	return (
		<Box sx={{ flexGrow: 0 }}>
			<Tooltip arrow placement="left" title={t("app_bar.settings_menu_button_tooltip")}>
				<IconButton
					ref={buttonRef}
					onClick={() => {
						setMenuOpen(true);
					}}
					aria-label={t("app_bar.settings_menu_button_tooltip")}
					aria-controls="appLayout_avatarMenu_Menu"
					aria-haspopup="true"
					sx={{ p: 0 }}
				>
					{user != null ? <ProfileAvatar sx={avatarStyles} account={user} /> : <Avatar sx={avatarStyles} />}
				</IconButton>
			</Tooltip>
			<Menu
				sx={{ mt: "45px", zIndex: "tooltip" }}
				id="appLayout_avatarMenu_Menu"
				MenuListProps={{
					component: "div",
				}}
				anchorEl={buttonRef.current}
				anchorOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				keepMounted
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				open={menuOpen}
				onClose={() => {
					setMenuOpen(false);
				}}
			>
				<MenuItem
					component="span"
					onClick={() => {
						setMenuOpen(false);
					}}
					className="sr-only"
				>
					{t("screen_reader_close_menu_button")}
				</MenuItem>
				<AppMenuOptions setMenuOpen={setMenuOpen} />
			</Menu>
		</Box>
	);
}
