import { LocaleCode } from "@lib/models";
import { useRouter } from "next/router";

export function useLocaleWithCountry() {
	const language = useLocale();
	let country = "";
	if (typeof navigator !== "undefined") {
		const languageAndCountry = navigator.language.split("-");
		if (languageAndCountry.length > 1) {
			country = `-${languageAndCountry[1]}`;
		}
	}

	return `${language.slice(0, 2)}${country}`;
}

export default function useLocale(): LocaleCode {
	const { locale } = useRouter();
	return (locale as LocaleCode) ?? "en";
}
