import { MaybeFieldErrors } from "../formErrors";

type Validator<T> = (value: T | undefined) => MaybeFieldErrors;

function makeValidator<T>(cast: (value: T | undefined) => T, validate: (value: T) => boolean) {
	return function requiredValidator(value: T | undefined): MaybeFieldErrors {
		if (validate(cast(value))) {
			return undefined;
		}
		return [{ t: "field_required_error" }];
	};
}

function castString(value: undefined | null | string): string {
	if (value == null) {
		return "";
	}
	if (typeof value !== "string") {
		throw new TypeError("Expected string or undefined or null");
	}
	return value;
}

export const requiredStringValidator: Validator<string> = makeValidator<string>(castString, (value: string) => {
	return typeof value === "string" && value.length > 0;
});
