import isPWA from "@lib/util/isPWA";
import { track } from "@lib/util/trackAnalytics";

export default function onLoad() {
	if (isPWA()) {
		track("pwa_launched", undefined, { statsig: false });
	}
	window.addEventListener("beforeinstallprompt", () => {
		track("install_pwa_banner_shown", { native: true }, { statsig: false });
	});
}
