import { Button } from "@components/Button";
import LocationArrowIcon from "@components/icons/LocationArrowIcon";
import usePrevMapState from "@lib/feature/map/useMapState";
import FilterCenterFocusIcon from "@mui/icons-material/FilterCenterFocus";
import { Tooltip } from "@mui/material";
import palette from "@styles/palette";
import mapboxgl, { LngLatLike } from "mapbox-gl";
import { useTranslation } from "next-i18next";
import useMapboxConfig from "./useMapboxConfig";

type CenterControlProps = {
	mapbox: mapboxgl.Map;
	center: LngLatLike;
	useRebrand?: boolean;
};

export default function CenterControl({ mapbox, center, useRebrand = false }: CenterControlProps) {
	const { t } = useTranslation("common");
	const { updateMapState } = usePrevMapState();
	const mapConfig = useMapboxConfig();
	const tooltipTitle = useRebrand
		? t("map.recenter_map_button_hidden_label_rebrand")
		: t("map.recenter_map_button_hidden_label");
	return (
		<Tooltip arrow placement="left" title={tooltipTitle}>
			<Button
				icon
				variant="secondary ghost"
				aria-label={tooltipTitle}
				onClick={() => {
					mapbox.easeTo({
						center,
						zoom: mapConfig.zoom,
					});
					updateMapState(null);
				}}
				sx={{
					backdropFilter: "blur(4px)",
					backgroundColor: palette.SurfaceContainerOpacity15,
				}}
			>
				{useRebrand ? <LocationArrowIcon /> : <FilterCenterFocusIcon />}
			</Button>
		</Tooltip>
	);
}
