import { LocaleCode } from "@lib/models";

type SupportLinkLocales = "en" | "es" | "pt-br";

function mapLocale(locale: string): SupportLinkLocales {
	return (
		(
			{
				en: "en",
				es: "es",
				pt: "pt-br",
			} as const
		)[locale] ?? "en"
	);
}

type LinkType = "general" | "faq" | "feedback" | "suspended";

export default function getSupportLinks(link: LinkType, locale: LocaleCode): string {
	const mappedLocale = mapLocale(locale);
	if (link === "feedback") {
		return `https://beta.support.bkdr.com/portal/${mappedLocale}/newticket?departmentId=898632000000323173&layoutId=898632000000432649`;
	}
	if (link === "suspended") {
		return `https://beta.support.bkdr.com/portal/${mappedLocale}/newticket?departmentId=898632000000323173&layoutId=898632000000428861`;
	}
	// if (link === "general" || link === "faq")
	return `https://beta.support.bkdr.com/portal/${mappedLocale}/kb/bkdr-support`;
}
