import ClusterButton from "@lib/feature/map/clustering/ClusterButton";
import { FriendPointFeature } from "@lib/feature/map/clustering/getClustersFromPoints";
import { UserShort } from "@lib/models";
import mapboxgl from "mapbox-gl";
import { ReactElement } from "react";
import { Point } from "./MapTypes";
import ProfilePin from "./mapPoints/ProfilePin";
import UserMapMarker from "./mapbox/UserMapMarker";

export class Pin implements Point {
	readonly id: string;
	readonly lng: number;
	readonly lat: number;

	constructor(point: Point) {
		this.id = point.id;
		this.lng = point.lng;
		this.lat = point.lat;
	}

	render(): ReactElement {
		throw new Error("Don't use the base Pin class, use one of the children.");
	}
}

export class UserPin extends Pin {
	mapbox: mapboxgl.Map;
	constructor(point: Point, mapbox: mapboxgl.Map) {
		super(point);
		this.mapbox = mapbox;
	}
	render() {
		return <UserMapMarker mapbox={this.mapbox} />;
	}
}

export class FriendPin extends Pin {
	friend: UserShort;
	constructor(point: Point, friend: UserShort) {
		super(point);
		this.friend = friend;
	}
	render() {
		return <ProfilePin friend={this.friend} />;
	}
}

export class ClusterPin extends Pin {
	points: FriendPointFeature[];
	firstFriend: UserShort;
	onClick: () => void;
	constructor(point: Point, points: FriendPointFeature[], firstFriend: UserShort, onClick: () => void) {
		super(point);
		this.points = points;
		this.firstFriend = firstFriend;
		this.onClick = onClick;
	}
	render() {
		return <ClusterButton points={this.points} firstFriend={this.firstFriend} onClick={this.onClick} />;
	}
}
