import { LngLat } from "mapbox-gl";

const popularCities = [
	{ cityKey: "sao_paulo", coordinates: new LngLat(-46.62529, -23.533773) },
	{ cityKey: "mexico_city", coordinates: new LngLat(-99.133209, 19.432608) },
	{ cityKey: "bogota", coordinates: new LngLat(-74.063644, 4.624335) },
	{ cityKey: "new_york_city", coordinates: new LngLat(-73.935242, 40.73061) },
	{ cityKey: "rio_de_janeiro", coordinates: new LngLat(-43.196388, -22.908333) },
	{ cityKey: "chicago", coordinates: new LngLat(-87.623177, 41.881832) },
	{ cityKey: "atlanta", coordinates: new LngLat(-84.38633, 33.753746) },
	{ cityKey: "los_angeles", coordinates: new LngLat(-118.327759, 34.098907) },
	{ cityKey: "santiago", coordinates: new LngLat(-70.692039, 19.4708) },
	{ cityKey: "madrid", coordinates: new LngLat(-3.70256, 40.4165) },
];
export default popularCities;
