import { SvgIcon, SvgIconProps } from "@mui/material";

export default function StopLineIcon(props: SvgIconProps) {
	return (
		<SvgIcon data-testid="BKDRStopLineIcon" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3 3H4H20H21V4V20V21H20H4H3V20V4V3ZM5 5V19H19V5H5Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
}
