import BoltIcon from "@components/icons/BoltIcon";
import { BadgeProps, SxProps, Theme, styled } from "@mui/material";
import palette from "@styles/palette";
import { ReactNode, memo } from "react";

const Badge = styled("span")({
	backgroundColor: palette.surfaceContainer,
	display: "inline-flex",
	color: palette.onSurface,

	"span:first-of-type": {
		border: `solid 1px ${palette.Primary}`,
		padding: "1px 4px 1px 3px",
		fontSize: "12px",
		fontStyle: "italic",
		fontWeight: "500",
		lineHeight: "normal",
	},
	"span:last-of-type": {
		backgroundColor: palette.Primary,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		padding: "3px 2px",
	},
	svg: {
		width: "12px",
		height: "12px",
	},
});

type ITMCBadgeProps = {
	children: ReactNode;
	sx?: SxProps<Theme>;
} & BadgeProps;

export default memo(function ITMCBadge({ children, sx }: ITMCBadgeProps) {
	return (
		<Badge sx={sx}>
			<span>{children}</span>
			<span>
				<BoltIcon />
			</span>
		</Badge>
	);
});
