import useLocale from "@hooks/useLocale";
import useUser from "@hooks/useUser";
import { LocaleCode, UserAccount } from "@lib/models";
import { useEffect, useState } from "react";
import { getUnreadCount, subscribe } from "./chatClientSingleton";

function getUnreadCountWithUser(user: UserAccount | undefined, locale: LocaleCode) {
	if (user == null) {
		return 0;
	}
	return getUnreadCount(user.uuid, locale);
}

export default function useUnreadCount() {
	const user = useUser();
	const locale = useLocale();
	const [chatClientState, setChatClientState] = useState(() =>
		user == null ? 0 : getUnreadCountWithUser(user, locale),
	);

	useEffect(() => {
		if (user == null) {
			return;
		}
		return subscribe(() => {
			setChatClientState(getUnreadCountWithUser(user, locale));
		});
	});

	return chatClientState;
}
