import { post } from "../httpClient";
import { InputInvalid, ServerFormError } from "./commonErrors";
import { GenericSuccessResponse } from "./commonTypes";
export class BadLogin extends Error {}
export class Suspended extends Error {}

type LoginRequest = {
	login: string;
	password: string;
};

export async function login(body: LoginRequest) {
	return post<LoginRequest, GenericSuccessResponse>("/login", body).catch((error) => {
		if (error.response?.status === 401) {
			throw new BadLogin();
		}
		if (error.response?.status === 403 && error.response?.data?.["field-error"]?.join(", ") === "account, suspended") {
			throw new Suspended();
		}
		throw error;
	});
}

export async function logout() {
	await post("/logout", {});
}

type ForgotPasswordRequest = {
	login: string;
	locale: string;
};

export async function forgotPassword(body: ForgotPasswordRequest): Promise<GenericSuccessResponse> {
	return post<ForgotPasswordRequest, GenericSuccessResponse>("/reset-password-request", body).catch((error) => {
		if (error.response?.status === 422 || error.response?.status === 400) {
			if (typeof error.response.data === "object") {
				throw new ServerFormError(error.response.data);
			}
		}
		throw error;
	});
}

export class BadResetToken extends Error {}

async function resetForcedLogin(email: string, password: string) {
	return login({ login: email, password });
}

type ResetPasswordRequest = {
	key: string;
	password: string;
};

export async function resetPassword(body: ResetPasswordRequest): Promise<GenericSuccessResponse> {
	return post<ResetPasswordRequest, GenericSuccessResponse>("/reset-password", body).catch((error) => {
		if (error.response?.status === 422) {
			if (error.response?.data["field-error"]?.[1] === "invalid password, same as current password") {
				return resetForcedLogin(error.response?.data.email, body.password);
			}
			throw new InputInvalid();
		}
		if (error.response?.status === 401) {
			throw new BadResetToken();
		}
		throw error;
	});
}
