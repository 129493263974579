import reportError from "@lib/util/reportError";
import { useEffect, useSyncExternalStore } from "react";
import useEventCallback from "use-event-callback";
import UserLocation from "./UserLocation";
import {
	clearLocationShownStorage,
	getLocationShownFromStorage,
	saveLocationShownToStorage,
} from "./locationShownStorage";

export type UserLocationState =
	| "firstRender"
	| "requestView"
	| "querying"
	| "denied"
	| "failed"
	| "noSupport"
	| "success"
	| "resolvingGrant";

const {
	setStatus,
	subscribe,
	getStatus: statusSnapshot,
	getPosition: positionSnapshot,
	getCurrentPosition,
	setPermissionsArePrompted,
	subscribeToPermissionState,
} = UserLocation;

// Android supports checking geolocation permissions via the Permissions API.
// iOS does not. We show the information view once for iOS (really any platform
// that doesn't support Permissions), and then mark it "shown" in localStorage.
// The local storage state is cleared if the geolocation requests comes back with
// denied later on.
// Everywhere else we only show the information view if the permissions are in the
// "prompt" state.
export default function useLocation(): [UserLocationState, GeolocationPosition | null, () => void] {
	const status = useSyncExternalStore<UserLocationState>(subscribe, statusSnapshot);
	const position = useSyncExternalStore<GeolocationPosition | null>(subscribe, positionSnapshot);
	subscribeToPermissionState();
	const onClickRequest = useEventCallback(() => {
		setPermissionsArePrompted();
		getCurrentPosition();
		saveLocationShownToStorage();
	});

	useEffect(() => {
		if (status !== "firstRender") {
			return;
		}
		function handleLocationCheck() {
			if (getLocationShownFromStorage()) {
				getCurrentPosition();
			} else {
				setStatus("requestView");
			}
		}
		if ("geolocation" in navigator) {
			if ("permissions" in navigator) {
				setStatus("querying");
				navigator.permissions
					.query({ name: "geolocation" })
					.then((result) => {
						if (result.state === "granted") {
							getCurrentPosition();
						} else if (result.state === "denied") {
							setStatus("denied");
						} else {
							// iOS safari responds "prompt" even when permission was already granted
							// result.state === "prompt"
							handleLocationCheck();
						}
					})
					.catch((error) => {
						reportError(error);
						// the possible errors here are TypeError for not supporting geolocation
						// or InvalidStateError if the document state is bad. Either case we can
						// treat as a broken failed permission check.
						handleLocationCheck();
					});
			} else {
				handleLocationCheck();
			}
		} else {
			setStatus("noSupport");
		}
	}, [status]);

	useEffect(() => {
		if ("geolocation" in navigator && status === "success") {
			const clearWatch = UserLocation.watchPosition();
			return () => {
				clearWatch();
			};
		}
	}, [status]);

	useEffect(() => {
		if (status === "denied") {
			clearLocationShownStorage();
		}
	}, [status]);

	return [status, position, onClickRequest];
}
