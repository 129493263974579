import { SvgIcon, SvgIconProps } from "@mui/material";

export default function DotsVerticalIcon(props: SvgIconProps) {
	return (
		<SvgIcon data-testid="BKDRDotsVerticalIcon" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10 21V20V18V17H11H13H14V18V20V21H13H11H10ZM10 14V13V11V10H11H13H14V11V13V14H13H11H10ZM10 7L10 6V4V3H11H13H14V4V6V7H13H11H10Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
}
