import { Modal } from "@mui/material";
import { ReactNode, useCallback, useState } from "react";
import Recorder from "./Recorder";

type VideoRecorderProps = {
	children: (onOpen: () => void) => ReactNode;
	onFinish: (file: Blob) => void;
};

export default function VideoRecorder({ children, onFinish }: VideoRecorderProps) {
	const [open, setOpen] = useState(false);
	const onClose = useCallback(() => {
		setOpen(false);
	}, []);
	const onOpen = useCallback(() => {
		setOpen(true);
	}, []);

	return (
		<>
			{children(onOpen)}
			<Modal open={open}>
				<Recorder onClose={onClose} onFinish={onFinish} />
			</Modal>
		</>
	);
}
