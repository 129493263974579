import { Subject } from "rxjs";

type BlockEvent = {
	uuid: string;
};

const blockStream = new Subject<BlockEvent>();

export function blockUser(uuid: string) {
	blockStream.next({ uuid });
}

export { blockStream };
