import useRebrandGate from "@lib/feature/landing/hooks/useRebrandGate";
import { Bedtime, Stop } from "@mui/icons-material";
import { Badge, BadgeProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import palette from "@styles/palette";
import { ReactNode } from "react";
import { AvatarPinBadgeOption } from "./AvatarPin";

type VideoBadgeProps = {
	badge: AvatarPinBadgeOption;
	children: ReactNode;
};

const StyledBadge = styled(Badge, {
	shouldForwardProp: (prop) => prop !== "badge",
})<BadgeProps & { badge: AvatarPinBadgeOption }>(({ theme, badge }) => ({
	"& .MuiBadge-badge": {
		width: theme.spacing(1.5),
		minWidth: theme.spacing(1.5),
		height: theme.spacing(1.5),
		border: `2px solid ${theme.palette.common.black}`,
		borderRadius: theme.spacing(1),
		bottom: "14%",
		right: "14%",
		padding: 0,
		backgroundColor: badge === "online" ? theme.palette.success.main : theme.palette.common.black,
	},
}));

const StyledBadgeRebrand = styled(Badge)<BadgeProps>(({ theme }) => ({
	"& .MuiBadge-badge": {
		width: theme.spacing(1),
		minWidth: theme.spacing(1),
		height: theme.spacing(1),
		border: `1px solid ${palette.surfaceContainer}`,
		borderRadius: theme.spacing(1),
		padding: 0,
		bottom: theme.spacing(0.25),
		right: theme.spacing(0.25),
		backgroundColor: palette.indicatorActive,
	},
}));

const IdleBadgeContent = styled(Bedtime)(({ theme }) => ({
	width: "9px",
	height: "9px",
	fill: theme.palette.warning.main,
}));

const BreadcrumbBadgeContent = styled(Stop)(({ theme }) => ({
	width: "9px",
	height: "9px",
	fill: theme.palette.info.main,
}));

const badgeMap: Record<AvatarPinBadgeOption, ReactNode> = {
	none: undefined,
	online: "",
	idle: <IdleBadgeContent />,
	breadcrumb: <BreadcrumbBadgeContent />,
};

export default function OnlineStatusBadge({ badge, children }: VideoBadgeProps) {
	const rebrandEnabled = useRebrandGate();

	if (rebrandEnabled) {
		return (
			<StyledBadgeRebrand
				invisible={badge !== "online"}
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
				overlap="rectangular"
				badgeContent=""
			>
				{children}
			</StyledBadgeRebrand>
		);
	}

	return (
		<StyledBadge
			badge={badge}
			invisible={badge === "none"}
			anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
			overlap="circular"
			badgeContent={badgeMap[badge]}
		>
			{children}
		</StyledBadge>
	);
}
