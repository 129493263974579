import { locationReload } from "@lib/util/locationWrapper";
import { Box, Button, lighten, Typography } from "@mui/material";
import { useTranslation } from "next-i18next";
import Link from "next/link";

type ErrorPageProps = {
	error?: "500" | "404";
};

export default function ErrorPage({ error = "500" }: ErrorPageProps) {
	const { t } = useTranslation("common");

	const strings = {
		"500": {
			title: t("error_page_title"),
			description: t("error_page_description"),
			button: t("reload_button"),
		},
		"404": {
			title: t("404_page_title"),
			description: t("404_page_description"),
			button: t("go_to_home_page_button"),
		},
	}[error];

	return (
		<Box
			sx={{
				margin: "auto",
				maxWidth: "254px",
				textAlign: "center",
			}}
		>
			<Typography
				variant="body1"
				color="error.main"
				sx={{
					fontWeight: 500,
					lineHeight: "24px",
				}}
			>
				{strings.title}
			</Typography>
			<Typography
				variant="body2"
				sx={{
					color: (theme) => lighten(theme.palette.error.light, 0.6),
					mb: "40px",
				}}
			>
				{strings.description}
			</Typography>
			{error === "404" ? (
				<Button href="/" LinkComponent={Link} variant="outlined" color="error" fullWidth>
					{strings.button}
				</Button>
			) : (
				<Button
					variant="outlined"
					color="error"
					fullWidth
					onClick={() => {
						locationReload();
					}}
				>
					{strings.button}
				</Button>
			)}
		</Box>
	);
}
