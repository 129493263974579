// see from https://github.com/mui/material-ui/tree/master/examples/nextjs-with-typescript

import { AppAlertProvider } from "@components/AppLayout/appAlert";
import GlobalLoader from "@components/GlobalLoader";
import OuterErrorBoundary from "@components/OuterErrorBoundary";
import { CacheProvider, EmotionCache } from "@emotion/react";
import useFontHeadStyleProps from "@hooks/useFontHeadStyleProps";
import AuthenticationProvider from "@lib/auth/AuthenticationProvider";
import createEmotionCache from "@lib/createEmotionCache";
import CheckUpdateFailure from "@lib/feature/CheckUpdateFailure";
import GlobalFeed from "@lib/feature/Feed/GlobalFeed";
import InitialDataLoader from "@lib/feature/InitialDataLoader";
import StatsigWrapper from "@lib/feature/StatsigWrapper";
import onLoad from "@lib/feature/analytics/onLoad";
import FeatureFlagsProvider from "@lib/feature/featureFlags/FeatureFlagsProvider";
import GlobalMap from "@lib/feature/map/GlobalMap";
import PrivacyCheckRouter from "@lib/feature/privacyCheck/PrivacyCheckRouter";
import RealtimeHandler from "@lib/feature/realtimeHandling/RealtimeHandler";
import { ActionCableProvider } from "@lib/feature/realtimeHandling/useActionCable";
import { LocaleCode } from "@lib/models";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { NextPage } from "next";
import { appWithTranslation } from "next-i18next";
import nextI18nextConfig from "next-i18next.config.mjs";
import type { AppProps } from "next/app";
import Head from "next/head";
import { ReactElement, ReactNode, useEffect } from "react";
import "../styles/global.css";
import "../styles/themes/chat.scss";
import darkThemeOptions from "../styles/themes/darkThemeOptions";

export type NextPageWithLayout = NextPage & {
	getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
	Component: NextPageWithLayout;
	emotionCache?: EmotionCache;
};

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();
const darkTheme = createTheme(darkThemeOptions);

function MyApp({ Component, pageProps, router, emotionCache = clientSideEmotionCache }: AppPropsWithLayout) {
	useEffect(() => {
		onLoad();
	}, []);

	const fontHeadStyleProps = useFontHeadStyleProps(router.locale as LocaleCode);

	const getLayout = Component.getLayout ?? ((page) => page);
	return (
		<>
			{/* This is here and not _document because https://nextjs.org/docs/messages/no-document-viewport-meta */}
			<Head>
				<meta
					name="viewport"
					content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
				/>
				<title>BKDR</title>
				<style {...fontHeadStyleProps} />
			</Head>
			<CacheProvider value={emotionCache}>
				<ThemeProvider theme={darkTheme}>
					<CssBaseline />

					<OuterErrorBoundary>
						<AppAlertProvider>
							<InitialDataLoader>
								<FeatureFlagsProvider>
									<AuthenticationProvider>
										<StatsigWrapper>
											<ActionCableProvider>
												<RealtimeHandler />
												<GlobalMap route={router.pathname} />
												{getLayout(<Component {...pageProps} />)}
												<GlobalFeed route={router.pathname} />
												<PrivacyCheckRouter />
											</ActionCableProvider>
										</StatsigWrapper>
									</AuthenticationProvider>
								</FeatureFlagsProvider>
							</InitialDataLoader>
						</AppAlertProvider>
						<CheckUpdateFailure />
						<GlobalLoader />
					</OuterErrorBoundary>
				</ThemeProvider>
			</CacheProvider>
		</>
	);
}

export default appWithTranslation(MyApp, nextI18nextConfig);
