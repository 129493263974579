import getTimeDifference from "@lib/util/getTimeDifference";
import { useState } from "react";
import { useInterval } from "react-timers-hooks";

export default function useTimeSinceITMCreated(createdAt: string) {
	const [timeSinceCreation, setTimeSinceCreation] = useState<number>(() => {
		return getTimeDifference({ endTime: createdAt, inMinutes: true });
	});

	useInterval(() => {
		setTimeSinceCreation(getTimeDifference({ endTime: createdAt, inMinutes: true }));
	}, 60000);

	return timeSinceCreation;
}
