import { SvgIcon, SvgIconProps } from "@mui/material";

export default function CornerUpLeftIcon(props: SvgIconProps) {
	return (
		<SvgIcon data-testid="BKDRCornerUpLeftIcon" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
			<path
				d="M2.58582 7.00015L8.00003 12.4144L9.41424 11.0002L6.41424 8.00015L19 8.00015L19 22.0002H21L21 6.00015L6.41424 6.00015L9.41424 3.00015L8.00003 1.58594L2.58582 7.00015Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
}
