import { useLocaleWithCountry } from "@hooks/useLocale";
import { UserShort } from "@lib/models";
import { useTranslation } from "next-i18next";
import { memo } from "react";

type PronounsProps = {
	profile: UserShort;
};

export default memo(function Pronouns({ profile }: PronounsProps) {
	const { t } = useTranslation("common");
	const locale = useLocaleWithCountry();
	const pronouns = (() => {
		const formatter = new Intl.ListFormat(locale, { style: "narrow", type: "conjunction" });
		return formatter.format(profile.pronouns.map((translationKey) => t(`tag_pronoun_options.${translationKey}`)));
	})();
	return (
		<>
			<span className="sr-only">
				{pronouns.length > 0 ? t("profile.pronouns_field_label") : t("profile.pronouns_empty_screenreader_label")}
				<br />
			</span>
			{pronouns}
		</>
	);
});
