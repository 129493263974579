import { SvgIcon, SvgIconProps } from "@mui/material";

export default function BoltIcon(props: SvgIconProps) {
	return (
		<SvgIcon data-testid="BKDRBoltIcon" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.5891 0.681641L13.1878 8.77771H22.2127L9.41071 23.3182L10.812 15.2222H1.78711L14.5891 0.681641Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
}
