import FieldSubmitError from "@components/FieldSubmitError";
import useIdify from "@hooks/useIdify";
import errorHelper from "@lib/util/errorHelper";
import { MaybeFieldErrors } from "@lib/util/formErrors";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { FormHelperText, SxProps, Theme } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput, { OutlinedInputProps } from "@mui/material/OutlinedInput";
import clsx from "clsx";
import { useTranslation } from "next-i18next";
import { useState } from "react";
import { Field } from "react-final-form";

type PasswordFieldProps = {
	sx?: SxProps<Theme>;
	fullWidth?: boolean;
	label?: string;
	name?: string;
	id?: string;
	required?: boolean;
	autoComplete?: string;
} & OutlinedInputProps;

export default function PasswordField({
	sx,
	fullWidth = true,
	label,
	id,
	required = true,
	name = "password",
	autoComplete = "current-password",
}: PasswordFieldProps) {
	const { t } = useTranslation("common");
	const idify = useIdify();
	const [showPassword, setShowPassword] = useState(false);
	return (
		<Field
			name={name}
			type="password"
			validate={(value: string | undefined): MaybeFieldErrors => {
				if ((value?.length ?? 0) < 6) {
					return [{ t: "password.validation_too_short" }];
				}
				return undefined;
			}}
			render={({ input, meta }) => {
				const { error, helperText } = errorHelper(t, t("password.field_helper"), meta);
				const helperTextId = idify("helper_text");
				const submitErrorId = idify(`${name}_submit_error`);
				const uniqueId = id ?? idify("password");
				return (
					<FormControl required={required} sx={sx} fullWidth={fullWidth} error={error} variant="outlined">
						<InputLabel htmlFor={uniqueId}>{label ?? t("password.field_label")}</InputLabel>
						<OutlinedInput
							id={uniqueId}
							label={label ?? t("password.field_label")}
							required={required}
							error={error}
							autoComplete={autoComplete}
							aria-describedby={clsx(helperTextId, submitErrorId)}
							{...input}
							type={showPassword ? "text" : "password"}
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										aria-label={t("auth_password_input_visibility_toggle_sr_label")}
										onClick={() => {
											setShowPassword((current) => !current);
										}}
										onMouseDown={(event) => {
											event.preventDefault();
										}}
										edge="end"
									>
										{showPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							}
						/>
						{helperText == null ? null : <FormHelperText id={helperTextId}>{helperText}</FormHelperText>}
						<FieldSubmitError id={submitErrorId} meta={meta} />
					</FormControl>
				);
			}}
		/>
	);
}
