import { SvgIcon, SvgIconProps } from "@mui/material";

export default function ChevronUpIcon(props: SvgIconProps) {
	return (
		<SvgIcon data-testid="BKDRChevronUpIcon" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
			<path
				d="M4.58578 15.0002L12 7.58594L19.4142 15.0002L18 16.4144L12 10.4144L6 16.4144L4.58578 15.0002Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
}
