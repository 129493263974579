import { SvgIcon, SvgIconProps } from "@mui/material";

export default function ChevronLeftIcon(props: SvgIconProps) {
	return (
		<SvgIcon data-testid="BKDRChevronLeftIcon" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
			<path
				d="M15 4.58594L7.58582 12.0002L15 19.4144L16.4142 18.0002L10.4142 12.0002L16.4142 6.00015L15 4.58594Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
}
