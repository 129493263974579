import { useEffect, useState } from "react";
import { throttle } from "throttle-debounce";

function getWindowSizeId() {
	return `${window.innerWidth}_${window.innerHeight}`;
}

export default function useWindowResize() {
	const [windowSize, setWindowSize] = useState<string | null>(null);
	useEffect(() => {
		const resizeHandler = throttle(200, () => {
			setWindowSize(getWindowSizeId());
		});
		resizeHandler();

		window.addEventListener("resize", resizeHandler);

		return () => {
			window.removeEventListener("resize", resizeHandler);
		};
	}, []);
	return windowSize;
}
