import { Severity } from "@lib/models";
import { Alert, keyframes, styled, Theme } from "@mui/material";

function alertAnimation(theme: Theme, severity: Severity) {
	return keyframes({
		"0%": {
			boxShadow: `0 0 4px 4px ${theme.palette[severity].main}`,
		},
		"50%": {
			boxShadow: `0 0 4px 4px ${theme.palette[severity].main}`,
		},
		"100%": {
			boxShadow: "0 0 4px 1px transparent",
		},
	});
}

export const ShinyAlert = styled(Alert)<{ severity: Severity }>(({ theme, severity }) => {
	return {
		zIndex: theme.zIndex.snackbar,
		animation: `1s ease 0s ${alertAnimation(theme, severity ?? "info")}`,
	};
});
