import useLocale from "@hooks/useLocale";
import getSupportLinks from "@lib/util/getSupportLinks";
import AppMenuExternalLink from "./AppMenuExternalLink";

export default function AppMenuFeedbackLink({ onClose }: { onClose: () => void }) {
	const locale = useLocale();
	return (
		<AppMenuExternalLink href={getSupportLinks("feedback", locale)} i18nKey="app_bar.feedback_link" onClose={onClose} />
	);
}
