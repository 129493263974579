import usePresence from "@hooks/usePresence";
import { AnyAccount, UserShort } from "@lib/models";
import { Badge, BadgeOrigin } from "@mui/material";
import { useTranslation } from "next-i18next";
import { ReactElement } from "react";

type PresenceBadgeProps = {
	account: AnyAccount | UserShort;
	anchorOrigin?: BadgeOrigin;
	children: ReactElement | ReactElement[];
	overlap?: "circular" | "rectangular";
};

export default function PresenceBadge({ account, anchorOrigin, children, overlap = "circular" }: PresenceBadgeProps) {
	const presence = usePresence(account);
	const { t } = useTranslation("common");
	const breadcrumb = account.is_breadcrumb;
	return (
		<Badge
			anchorOrigin={anchorOrigin}
			variant="dot"
			overlap={overlap}
			invisible={presence === "offline" && !breadcrumb}
			color={presence === "online" ? "success" : breadcrumb ? "info" : "warning"}
			sx={(theme) => ({
				"& .MuiBadge-badge": {
					width: "12px",
					height: "12px",
					border: `2px solid ${theme.palette.common.black}`,
					borderRadius: "8px",
				},
			})}
		>
			{children}
			<span className="sr-only">{t(`presence.presence_${presence}`)}</span>
		</Badge>
	);
}
