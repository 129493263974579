import { useEffect, useRef } from "react";
import { register } from "swiper/element/bundle";

export default function NewSwiperContainer({
	onSlideChange,
	children,
	...props
}: JSX.IntrinsicElements["swiper-container"] & { onSlideChange?: () => void }) {
	const swiperElRef = useRef<JSX.IntrinsicElements["swiper-container"]>(null);

	useEffect(() => {
		register();
		const swiperEl = swiperElRef.current;
		if (swiperEl !== null && onSlideChange != null) {
			swiperEl.addEventListener("swiperslidechange", onSlideChange);
			return () => {
				swiperEl.removeEventListener("swiperslidechange", onSlideChange);
			};
		}
	}, [onSlideChange]);

	return (
		<swiper-container {...props} ref={swiperElRef}>
			{children}
		</swiper-container>
	);
}
