import ICU from "i18next-icu";

export default {
	i18n: {
		defaultLocale: "default",
		locales: [
			"default",
			// this list is alphabetized by language name
			"de",
			"en",
			"es",
			"fr",
			"it",
			"nl",
			"pt",
			"vi",
			"el",
			"ru",
			"ar",
			"th",
			"ko",
			"ja",
			"zh-CN",
			"zh-TW",
		],
		localeDetection: false,
	},
	serializeConfig: false,
	use: [ICU],
};
