import { Location } from "@lib/models";
import { LngLatBounds } from "mapbox-gl";
import { CHUNK_SIZE, ChunkBounds } from "./chunks";

function getNearest(input: number): number {
	return Math.round(input / CHUNK_SIZE);
}

export default function chunksFromBounds(center: Location, bounds: LngLatBounds): ChunkBounds {
	const { lng: lngMin, lat: latMin } = bounds.getSouthWest();
	const { lng: lngMax, lat: latMax } = bounds.getNorthEast();

	const centerChunk = {
		x: getNearest(center.lng),
		y: getNearest(center.lat),
	};

	return new ChunkBounds({
		center: centerChunk,
		xmin: getNearest(lngMin),
		xmax: getNearest(lngMax),
		ymin: getNearest(latMin),
		ymax: getNearest(latMax),
	});
}
