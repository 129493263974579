import { FieldTag } from "@components/MultiSelectField/configTypes";
import useUser from "@hooks/useUser";
import getMatchingFriendCategories, {
	TagMatchMap,
	isMatchableCategory,
} from "@lib/feature/profile/ProfileForm/getMatchingFriendCategories";
import { AnyAccount } from "@lib/models";
import { TFunction, useTranslation } from "next-i18next";
import Section from "./Section";

type KeyOfType<T, V> = keyof {
	[P in keyof T as T[P] extends V ? P : never]: unknown;
};

type ProfileField = KeyOfType<AnyAccount, string | string[] | null>;

type Field = {
	label: string;
	field: ProfileField;
};

export type FieldValue = Field & {
	translationPrefix?: string;
};

type SectionValue = {
	header: string;
	fields: FieldValue[];
};

type FieldWithTags = Field & {
	values: FieldTag[];
};

type SectionWithTags = {
	header: string;
	fields: FieldWithTags[];
};

type GenerateFieldTag = {
	field: KeyOfType<AnyAccount, string | string[] | null>;
	fieldValues: string[];
	friendMatches: TagMatchMap;
	translationPrefix?: string;
	t: TFunction;
};

function generateFieldTags({ field, fieldValues, friendMatches, translationPrefix, t }: GenerateFieldTag): FieldTag[] {
	return fieldValues.map((fieldValue: string) => {
		return {
			tag: fieldValue,
			isMatch: isMatchableCategory(field) ? friendMatches[field].includes(fieldValue) : false,
			translatedValue: translationPrefix != null ? t(`${translationPrefix}.${fieldValue}`) : t(fieldValue),
		};
	});
}

type GenerateSectionFields = {
	fields: FieldValue[];
	friendMatches: TagMatchMap;
	profile: AnyAccount;
	t: TFunction;
};

export function generateFieldWithTags({ profile, fields, friendMatches, t }: GenerateSectionFields): FieldWithTags[] {
	return fields.flatMap(({ label, field, translationPrefix }) => {
		let fieldValues = profile[field] ?? [];
		if (fieldValues.length === 0) {
			return [];
		}
		if (typeof fieldValues === "string") {
			if (fieldValues === "") {
				return [];
			}
			fieldValues = [fieldValues];
		}
		const values = generateFieldTags({ field, fieldValues, friendMatches, translationPrefix, t });
		return {
			field,
			label,
			values,
		};
	});
}

function generateProfileSections({
	sections,
	profile,
	friendMatches,
	t,
}: {
	sections: SectionValue[];
	profile: AnyAccount;
	friendMatches: TagMatchMap;
	t: TFunction;
}): SectionWithTags[] {
	return sections.flatMap(({ header, fields }) => {
		const nextFields = generateFieldWithTags({ profile, fields, friendMatches, t });
		if (nextFields.length === 0) {
			return [];
		}
		return {
			header,
			fields: nextFields,
		};
	});
}

export default function ProfileSections({ profile }: { profile: AnyAccount }) {
	const { t } = useTranslation("common");
	const user = useUser();

	const sections: SectionValue[] = [
		{
			header: t("profile.section_details_heading"),
			fields: [
				{
					label: t("profile.sexualities_field_label"),
					field: "sexualities",
					translationPrefix: "tag_sexuality_options",
				},
				{
					label: t("profile.gender_identities_field_label"),
					field: "gender_identities",
					translationPrefix: "tag_gender_options",
				},
				{ label: t("profile.roles_field_label"), field: "my_roles", translationPrefix: "tag_role_options" },
				{
					label: t("profile.identities_field_label"),
					field: "my_identities",
					translationPrefix: "tag_identity_options",
				},
				{ label: t("profile.traits_field_label"), field: "my_traits", translationPrefix: "tag_trait_options" },
			],
		},
		{
			header: t("profile.section_looking_for_heading"),
			fields: [
				{ label: t("profile.roles_field_label"), field: "looking_for_roles", translationPrefix: "tag_role_options" },
				{
					label: t("profile.identities_field_label"),
					field: "looking_for_identities",
					translationPrefix: "tag_identity_options",
				},
				{ label: t("profile.traits_field_label"), field: "looking_for_traits", translationPrefix: "tag_trait_options" },
			],
		},
		{
			header: t("profile.section_turn_ons_heading"),
			fields: [
				{ label: t("profile.scenarios_field_label"), field: "scenarios", translationPrefix: "tag_scenario_options" },
				{ label: t("profile.into_field_label"), field: "into", translationPrefix: "tag_into_options" },
				{ label: t("profile.kinks_field_label"), field: "kinks", translationPrefix: "tag_kink_options" },
			],
		},
		{
			header: t("profile.section_health_safety_heading"),
			fields: [
				{ label: t("profile.safety_label"), field: "safety", translationPrefix: "safety" },
				{ label: t("profile.hiv_status_label"), field: "hiv_status", translationPrefix: "hiv_status" },
				{ label: t("profile.vaccinations_field_label"), field: "vaccinations", translationPrefix: "vaccinations" },
			],
		},
	];
	const friendMatches = getMatchingFriendCategories(user, profile);
	const sectionsWithValues = generateProfileSections({ profile, sections, friendMatches, t });

	return sectionsWithValues.map(({ header, fields }) => {
		return <Section key={header} header={header} fields={fields} />;
	});
}
