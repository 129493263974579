import { SvgIcon, SvgIconProps } from "@mui/material";

export default function LocationPinIcon(props: SvgIconProps) {
	return (
		<SvgIcon data-testid="BKDRLocationPinIcon" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12 1C7.02944 1 3 5.02944 3 10C3 12.5262 4.14683 14.7402 5.70772 16.767C7.01205 18.4607 8.68147 20.1156 10.3492 21.7689C10.6646 22.0816 10.98 22.3942 11.2929 22.7071L12 23.4142L12.7071 22.7071C13.02 22.3943 13.3354 22.0816 13.6508 21.7689C15.3185 20.1156 16.988 18.4606 18.2923 16.767C19.8532 14.7402 21 12.5262 21 10C21 5.02944 16.9706 1 12 1ZM15 10C15 11.6569 13.6569 13 12 13C10.3431 13 9 11.6569 9 10C9 8.34315 10.3431 7 12 7C13.6569 7 15 8.34315 15 10Z"
			/>
		</SvgIcon>
	);
}
