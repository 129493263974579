import { InTheMomentStatus } from "@lib/models";
import { ProfilePictureType } from "../getProfilePictures";

/**
 * A helper function to pull off the relevant tracking information regarding a clicked media item.
 *
 * @remarks
 * This method is part of the {@link core-library#Statistics | Statistics subsystem}.
 *
 * @param media - the ProfilePictureType or InTheMomentStatus that was clicked on
 * @param source - where the picture was clicked
 * @returns the trackable information from clicked media
 */
export default function getClickedMediaInfo(media: ProfilePictureType | InTheMomentStatus, source: "feed" | "profile") {
	// if media is a ProfilePictureType
	if ("type" in media) {
		return {
			source,
			// we know if the media is not a video it's a photo
			mediaType: media.type === "in_the_moment_video" ? "video" : "photo",
			// the only media type that is NOT itmc is 'profile'
			itmc: media.type !== "profile",
		};
	}
	// otherwise we can assume the media is ITMC then check the media type for photo or video
	return { source, mediaType: media.video === null ? "photo" : "video", itmc: true };
}
