import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { memo, useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import useMakeMarkersFromPins from "../mapbox/useMakeMarkersFromPins";
import { Pin, UserPin } from "../pin";
import useClusteringEnabled from "../useClusteringEnabled";
import pinsLoaderSetup from "../userLoading/pinsLoaderSetup";

type MapPinsProps = {
	mapbox: mapboxgl.Map | undefined;
	userPin: UserPin | null;
};

export default memo(function MapPins({ mapbox, userPin }: MapPinsProps) {
	const [pins, setPins] = useState<Pin[]>([]);
	const [pinsLoaderError, setPinsLoaderError] = useState<Error | null>(null);

	if (pinsLoaderError != null) {
		throw pinsLoaderError;
	}

	const clusteringEnabledBehaviorSubject = useClusteringEnabled(pins.length > 0);

	useEffect(() => {
		if (mapbox != null) {
			return pinsLoaderSetup(mapbox, setPins, setPinsLoaderError, clusteringEnabledBehaviorSubject);
		}
	}, [mapbox, clusteringEnabledBehaviorSubject]);

	// Get markers for all pins
	const pinElementMap = useMakeMarkersFromPins(
		mapbox,
		useMemo(() => {
			return userPin == null ? pins : pins.concat(userPin);
		}, [userPin, pins]),
	);

	// Render friends pins
	const renderedPins = useMemo(() => {
		return pins.map((pin) => {
			const element = pinElementMap.get(pin.id);
			if (element == null) {
				return null;
			}
			return createPortal(pin.render(), element, pin.id);
		});
	}, [pins, pinElementMap]);

	// Render own pin
	const renderedUserPin = (() => {
		if (userPin == null) {
			return null;
		}
		const userPinElement = pinElementMap.get(userPin.id);
		if (userPinElement == null) {
			return null;
		}
		return createPortal(userPin.render(), userPinElement, "user-pin");
	})();

	return (
		<>
			{renderedPins}
			{renderedUserPin}
		</>
	);
});
