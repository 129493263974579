export default function getTimeDifference({
	startTime = new Date().toISOString(),
	endTime = new Date().toISOString(),
	inMinutes,
}: {
	startTime?: string;
	endTime?: string;
	inMinutes?: boolean;
}) {
	return Math.floor((Date.parse(startTime) - Date.parse(endTime)) / (inMinutes ? 1000 * 60 : 1));
}
