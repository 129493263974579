import { Creator } from "./Creator";

export default function makeQueue<I>(setInstance: (instance: I | null) => void, setError: (error: Error) => void) {
	const queue: ("clean" | (() => Promise<() => Promise<void>>))[] = [];
	let processing = false;
	let nextCleanup: null | (() => Promise<void>) = null;
	async function process() {
		processing = true;
		if (queue.length === 0) {
			processing = false;
			return;
		}
		setInstance(null);
		if (nextCleanup != null) {
			await nextCleanup();
			nextCleanup = null;
		}
		const nextItem = queue.shift();
		if (nextItem === "clean" || nextItem == null) {
			process();
			return;
		}
		nextCleanup = await nextItem().catch((error) => {
			setError(error);
			return null;
		});
		process();
	}
	function startProccessing() {
		if (processing) {
			return;
		}
		process();
	}
	return {
		push(creator: Creator<I>) {
			queue.push(async () => {
				return creator(setInstance);
			});
			startProccessing();
		},
		clean() {
			queue.push("clean");
			startProccessing();
		},
	};
}
