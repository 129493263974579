import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import assertType from "./util/assertType";

const headers: Readonly<Record<string, string | boolean>> = {
	Accept: "application/json",
	"Content-Type": "application/json; charset=utf-8",
};

// not currently used, but could be useful
// type APIError = {
//   error: string;
// };
// function isAPIError(data: unknown): data is APIError {
//   return (data as APIError).error !== undefined;
// }

function dataExtractorInterceptor<T, D>({ data }: AxiosResponse<T, D>): T {
	return data;
}

function errorInterceptor(error: AxiosError) {
	const response = error.response;

	if (response) {
		const { status } = response;

		switch (status) {
			case 500: {
				// Handle InternalServerError
				break;
			}
			case 401: {
				// Handle Forbidden
				break;
			}
			case 400: {
				break;
			}
		}
	}

	return Promise.reject(error);
}

function initHttp(baseURL: string) {
	const http = axios.create({
		baseURL,
		headers,
		xsrfCookieName: "CSRF-TOKEN",
	});

	http.interceptors.response.use(dataExtractorInterceptor, errorInterceptor);

	return http;
}

const browserHttp = initHttp(assertType(process.env.NEXT_PUBLIC_BACKEND_URL));
const serverHttp = initHttp(assertType(process.env.NEXT_PUBLIC_BACKEND_SERVER_URL));

export function request<T, R = AxiosResponse<T>>(config: AxiosRequestConfig): Promise<R> {
	return browserHttp.request(config);
}

export function get<T, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
	return browserHttp.get<T, R>(url, config);
}

export function post<T, R = AxiosResponse<T>>(url: string, data?: T, config?: AxiosRequestConfig): Promise<R> {
	return browserHttp.post<T, R>(url, data, config);
}

export function put<T, R = AxiosResponse<T>>(url: string, data?: T, config?: AxiosRequestConfig): Promise<R> {
	return browserHttp.put<T, R>(url, data, config);
}

export function patch<T, R = AxiosResponse<T>>(url: string, data?: T, config?: AxiosRequestConfig): Promise<R> {
	return browserHttp.patch<T, R>(url, data, config);
}

export function deleteRequest<T, R = AxiosResponse<T>>(url: string, data?: T, config?: AxiosRequestConfig): Promise<R> {
	return browserHttp.delete<T, R>(url, {
		...config,
		data,
	});
}

export function serverPost<T, R = AxiosResponse<T>>(url: string, data?: T, config?: AxiosRequestConfig): Promise<R> {
	return serverHttp.post<T, R>(url, data, config);
}
