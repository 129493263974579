import { Button } from "@components/Button";
import CornerUpLeftIcon from "@components/icons/CornerUpLeftIcon";
import { Box } from "@mui/material";
import palette from "@styles/palette";
import { useTranslation } from "next-i18next";
import { useEffect, useRef } from "react";
import { useField } from "react-final-form";

type ReplyInputProps = {
	disabled: boolean;
};

export default function ReplyInput({ disabled }: ReplyInputProps) {
	const { t } = useTranslation("common");
	const { input } = useField("message");
	const inputRef = useRef<HTMLInputElement | null>(null);

	useEffect(() => {
		inputRef.current?.focus();
	}, []);

	return (
		<Box
			sx={(theme) => ({
				padding: theme.spacing(1.25, 1.5),
				display: "flex",
				gap: theme.spacing(1),
			})}
		>
			<Box
				sx={(theme) => ({
					flex: "1 1 auto",
					border: `1px solid ${palette.OnSurfaceSecondary}`,
					display: "flex",
					justifyContent: "flex-start",
					alignItems: "stretch",
					height: theme.spacing(5.5),
				})}
			>
				<Box
					{...input}
					component="input"
					ref={inputRef}
					placeholder={t("in_the_moment.itm_reply_input_label")}
					aria-label={t("in_the_moment.itm_reply_input_label")}
					type="text"
					autoComplete="off"
					sx={(theme) => ({
						backgroundColor: "transparent",
						border: "none",
						color: "inherit",
						outline: "none",
						minWidht: "0px",
						flex: "1 1 auto",
						padding: theme.spacing(1.25, 2, 1.25, 1.25),
						fontSize: "16px",
					})}
				/>
			</Box>
			{disabled ? null : (
				<Button
					icon
					variant={disabled ? "tertiary ghost" : "primary"}
					aria-label={t("send_button_label")}
					type="submit"
				>
					<CornerUpLeftIcon />
				</Button>
			)}
		</Box>
	);
}
