import { AnyAccount, PresenceType, UserShort } from "@lib/models";
import { getOnlineStatus } from "@lib/util/getOnlineStatus";
import { useCallback, useEffect, useState } from "react";
import { useInterval } from "react-timers-hooks";

export default function usePresence(user: AnyAccount | UserShort) {
	const [onlineStatus, setOnlineStatus] = useState<PresenceType>(() => getOnlineStatus(user));
	useEffect(() => {
		setOnlineStatus(getOnlineStatus(user));
	}, [user]);
	const callback = useCallback(() => {
		setOnlineStatus(getOnlineStatus(user));
	}, [user]);
	useInterval(callback, 1000);
	return onlineStatus;
}
