import localFont from "next/font/local";

const vcrOsdMono = localFont({
	src: "../../public/fonts/VCR_OSD_MONO_REGULAR.woff2",
	weight: "400",
	style: "normal",
	fallback: ["var(--heading-font-fallback)"],
	adjustFontFallback: false,
});

export default vcrOsdMono;
