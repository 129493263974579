import { ServerFormError } from "@api/commonErrors";
import { indicateLoading } from "@components/GlobalLoader";
import { updateAlertType } from "@lib/feature/alert/types";
import reportError from "@lib/util/reportError";
import { FORM_ERROR } from "final-form";
import { ReactNode } from "react";

// TODO find better errorHandlers type
type ErrorHandlerDefinition = {
	// eslint-disable-next-line @typescript-eslint/ban-types
	errorType: Function;
	result: string | ((error: Error) => string);
};

type FormErrorsObject = Partial<Record<string, ReactNode[] | ReactNode>> & {
	[FORM_ERROR]: ReactNode;
};

function makeFormMessageError(message: ReactNode) {
	return {
		[FORM_ERROR]: message,
	};
}

function formErrorFromServerError(
	errorHandlers: ErrorHandlerDefinition[],
	unknownErrorMessage: string,
	error: unknown,
): FormErrorsObject {
	if (error instanceof Error) {
		if (error instanceof ServerFormError) {
			const { error: errorMessage, fields } = error.data;
			return {
				[FORM_ERROR]: errorMessage,
				...fields,
			};
		}
		const handledMatcher = errorHandlers.find(({ errorType }) => {
			return error instanceof errorType;
		});
		if (handledMatcher != null) {
			if (typeof handledMatcher.result === "function") {
				return makeFormMessageError(handledMatcher.result(error));
			}
			return makeFormMessageError(handledMatcher.result);
		}
		reportError(error);
	} else {
		reportError(new Error(`Received non-Error object error. ${error}`));
	}
	return makeFormMessageError(unknownErrorMessage);
}

type MakeHandleFormSubmitConfigType<FormValuesType> = {
	submitter: (values: FormValuesType) => Promise<undefined>;
	updateAlert: updateAlertType;
	successMessage: string | null;
	unknownErrorMessage: string;
	errorHandlers?: ErrorHandlerDefinition[];
};

export function makeHandleFormSubmit<FormValuesType>({
	submitter,
	updateAlert,
	successMessage,
	unknownErrorMessage,
	errorHandlers = [],
}: MakeHandleFormSubmitConfigType<FormValuesType>) {
	return async function handleSubmit(values: FormValuesType) {
		updateAlert();

		const stopLoading = indicateLoading("handle form submit");
		const error = await submitter(values).catch((error) => error);
		stopLoading();
		if (error == null) {
			if (successMessage != null) {
				updateAlert(successMessage, "success");
			}
			return undefined;
		}

		const formErrorObject = formErrorFromServerError(errorHandlers, unknownErrorMessage, error);

		updateAlert(formErrorObject[FORM_ERROR], "error");
		return formErrorObject;
	};
}
