import CircleArrowNextButton from "@components/CircleArrowNextButton";
import { Box } from "@mui/material";

type PreviewerProps = {
	videoUrl: string | null;
	onFinish: () => void;
};

export default function Previewer({ videoUrl, onFinish }: PreviewerProps) {
	// video element should not be shown until object URL is available
	if (videoUrl == null) {
		return null;
	}
	return (
		<>
			<Box
				component="video"
				disablePictureInPicture
				controls
				muted
				playsInline
				autoPlay
				src={videoUrl}
				sx={{
					position: "absolute",
					top: "0px",
					bottom: "0px",
					left: "0px",
					right: "0px",
					margin: "auto",
					aspectRatio: "1/1",
					width: "100%",
				}}
			/>
			<Box
				sx={{
					position: "absolute",
					bottom: (theme) => theme.spacing(2.5),
					left: "0px",
					right: "0px",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					height: "90px",
				}}
			>
				<CircleArrowNextButton
					onClick={() => {
						onFinish();
					}}
				/>
			</Box>
		</>
	);
}
