import useAccountRegisterData from "@hooks/useAccountRegisterData";
import { FeatureFlags } from "@lib/models";
import { ReactNode } from "react";
import { FlagProvider } from "./featureFlagsContext";

export default function FeatureFlagsProvider({ children }: { children: ReactNode }) {
	const accountRegisterData = useAccountRegisterData();
	const featureFlags: FeatureFlags | undefined =
		accountRegisterData.error != null ? undefined : accountRegisterData.data?.flags;
	return <FlagProvider value={featureFlags ?? null}>{children}</FlagProvider>;
}
