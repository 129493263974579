import CheckSquareIcon from "@components/icons/CheckSquareIcon";
import { Box, Typography } from "@mui/material";
import palette from "@styles/palette";
import { useTranslation } from "next-i18next";

export default function FeedEnd() {
	const { t } = useTranslation("common");
	return (
		<Box
			sx={{
				mt: 5.5,
				mb: 31,
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				gap: 1.5,
				px: 2.5,
			}}
		>
			<CheckSquareIcon sx={{ width: "52px", height: "52px", color: palette.Primary }} />
			<Typography variant="h6" textAlign="center" sx={{ fontFamily: "var(--heading-font)" }}>
				{t("feed.scroll_end_heading")}
			</Typography>
			<Typography variant="body1" textAlign="center">
				{t("feed.scroll_end_message")}
			</Typography>
		</Box>
	);
}
