import { useEffect, useState } from "react";
import { Creator } from "./Creator";
import makeAsyncCleanupQueue from "./asyncCleanupQueue";

export default function useAsyncInstance<I>(create: Creator<I>) {
	const [current, setCurrent] = useState<I | null>(null);
	const [error, setError] = useState<Error | null>(null);
	const [queue] = useState(() => makeAsyncCleanupQueue<I>(setCurrent, setError));
	useEffect(() => {
		queue.push(create);
		return () => {
			queue.clean();
		};
	}, [queue, create]);

	return {
		error,
		instance: current,
	};
}
