import PlayIcon from "@components/icons/PlayIcon";
import useRebrandGate from "@lib/feature/landing/hooks/useRebrandGate";
import { PlayArrow } from "@mui/icons-material";
import { Badge, BadgeProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import palette from "@styles/palette";
import { ReactNode } from "react";

const StyledBadge = styled(Badge, {
	shouldForwardProp: (prop) => prop !== "desaturate",
})<BadgeProps & { desaturate: boolean }>(({ theme, desaturate }) => ({
	"& .video-badge.MuiBadge-badge": {
		width: theme.spacing(1.5),
		height: theme.spacing(1.5),
		minWidth: theme.spacing(1.5),
		top: "14%",
		right: "14%",
		padding: 0,
		backgroundColor: desaturate ? theme.palette.grey[400] : theme.palette.secondary.main,
	},
}));

const StyledBadgeRebrand = styled(Badge)(({ theme }) => ({
	"& .video-badge.MuiBadge-badge": {
		width: theme.spacing(1.5),
		height: theme.spacing(1.5),
		minWidth: theme.spacing(1.5),
		bottom: theme.spacing(0.75),
		left: theme.spacing(0.75),
		borderRadius: "unset",
		padding: 0,
		backgroundColor: palette.Primary,
	},
}));

const StyledPlayArrow = styled(PlayArrow)(({ theme }) => ({
	fill: theme.palette.common.black,
	width: theme.spacing(1.5),
	height: theme.spacing(1.5),
}));

type VideoBadgeProps = {
	children: ReactNode;
	videoBadge: boolean;
	desaturate: boolean;
};
export default function VideoBadge({ videoBadge, desaturate, children }: VideoBadgeProps) {
	const rebrandEnabled = useRebrandGate();

	if (rebrandEnabled) {
		return (
			<StyledBadgeRebrand
				invisible={videoBadge !== true}
				badgeContent={<PlayIcon sx={{ width: "8px", height: "8px" }} />}
				anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
				slotProps={{ badge: { className: "video-badge" } }}
			>
				{children}
			</StyledBadgeRebrand>
		);
	}

	return (
		<StyledBadge
			desaturate={desaturate}
			invisible={videoBadge !== true}
			badgeContent={<StyledPlayArrow />}
			anchorOrigin={{ vertical: "top", horizontal: "right" }}
			slotProps={{ badge: { className: "video-badge" } }}
		>
			{children}
		</StyledBadge>
	);
}
