import { curryN } from "ramda";
import { Observable, combineLatest, map } from "rxjs";
import { FriendPin } from "../pin";
import { ChunkBounds } from "./chunks";
import { ChunkFriendMap } from "./mapChunkBoundsToFriendChunkMap";

interface ToFriendPins {
	(
		chunkBoundsStream: Observable<ChunkBounds>,
		friendChunkMapStream: Observable<ChunkFriendMap>,
	): Observable<FriendPin[]>;
	(
		chunkBoundsStream: Observable<ChunkBounds>,
	): (friendChunkMapStream: Observable<ChunkFriendMap>) => Observable<FriendPin[]>;
}

export default curryN(
	2,
	function toFriendPins(chunkBoundsStream: Observable<ChunkBounds>, friendChunkMapStream: Observable<ChunkFriendMap>) {
		return combineLatest([chunkBoundsStream, friendChunkMapStream]).pipe(
			map(([chunkBounds, friendChunkMap]) => {
				const usersList: FriendPin[] = [];
				friendChunkMap.forEach(({ friends }, chunkId) => {
					const [x, y] = chunkId.split(",").map((s) => Number(s));
					if (x >= chunkBounds.xmin && x <= chunkBounds.xmax && y >= chunkBounds.ymin && y <= chunkBounds.ymax) {
						usersList.push(...friends);
					}
				});
				return usersList;
			}),
		);
	},
) as ToFriendPins;
