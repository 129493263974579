import { SvgIcon, SvgIconProps } from "@mui/material";

export default function UserIcon(props: SvgIconProps) {
	return (
		<SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" data-testid="BKDRUserIcon" {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7 6C7 3.23858 9.23858 1 12 1C14.7614 1 17 3.23858 17 6C17 8.76142 14.7614 11 12 11C9.23858 11 7 8.76142 7 6Z"
				fill="currentColor"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M2 22C2 16.4772 6.47715 12 12 12C17.5228 12 22 16.4772 22 22V23H2V22Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
}
