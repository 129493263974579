import { getUsers, usersPath } from "@api/users";
import { indicateLoading } from "@components/GlobalLoader";
import { blockStream } from "@lib/feature/blocking";
import { Chunk } from "@lib/models";
import repeatAfter from "@lib/util/frp/repeatAfter";
import reportError from "@lib/util/reportError";
import { NEVER, Observable, filter, from, interval, map, merge, mergeMap, scan, timeout } from "rxjs";
import { FriendPin } from "../pin";
import { CHUNK_SIZE, ChunkBounds } from "./chunks";

type ChunkFriendMapItem = {
	updatedAt: number;
	friends: FriendPin[];
};

type ChunkFriendMapPartial = Record<string, ChunkFriendMapItem>;

export type ChunkFriendMap = Map<string, ChunkFriendMapItem>;

const LIFETIME_MS = 60000; // 60s * 1000ms/s

function makeGetUsersKey(chunk: Chunk): string {
	const queryString = new URLSearchParams();
	queryString.append("ne_lat", String(chunk.ne.lat));
	queryString.append("ne_lng", String(chunk.ne.lng));
	queryString.append("sw_lat", String(chunk.sw.lat));
	queryString.append("sw_lng", String(chunk.sw.lng));
	return `${usersPath}?${queryString.toString()}`;
}

function reduceFriendPins(map: { [key: string]: { updatedAt: number; friends: FriendPin[] } }, friendPin: FriendPin) {
	const x = Math.round(friendPin.lng / CHUNK_SIZE);
	const y = Math.round(friendPin.lat / CHUNK_SIZE);
	const chunkId = `${x},${y}`;
	let mapEntry = map[chunkId];
	if (mapEntry == null) {
		mapEntry = {
			updatedAt: Date.now(),
			friends: [],
		};
		map[chunkId] = mapEntry;
	}
	mapEntry.friends.push(friendPin);
	return map;
}

export default function mapChunkBoundsToFriendChunkMap(chunkBounds: Observable<ChunkBounds>) {
	const newChunksStream = chunkBounds
		// convert to coords bounds
		.pipe(map((chunkBounds) => chunkBounds.toLngLatCorners()))
		.pipe(repeatAfter(LIFETIME_MS))
		// retrieve users and group into { chunkId: { list, updatedAt } }
		.pipe(
			mergeMap((chunk: Chunk) => {
				const stopLoading = indicateLoading("mapChunkBoundsToFriendChunkMap request");
				return from(
					getUsers(makeGetUsersKey(chunk))
						.finally(() => {
							stopLoading();
						})
						.then((friendPins) => {
							return friendPins.reduce<ChunkFriendMapPartial>(reduceFriendPins, {});
						})
						.catch((error) => {
							reportError(error);
							return undefined;
						}),
				).pipe(filter((chunkOrNull): chunkOrNull is ChunkFriendMapPartial => chunkOrNull != null));
			}),
		)
		.pipe(map(Object.entries as <T>(obj: Record<string, T>) => [string, T][]))
		.pipe(
			map((entries) => (chunkFriendMap: ChunkFriendMap) => {
				entries.forEach(([chunkId, chunk]) => {
					chunkFriendMap.set(chunkId, chunk);
				});
				return chunkFriendMap;
			}),
		);

	const checkExpiredStream = timeout({
		each: LIFETIME_MS / 2,
		with: () => interval(LIFETIME_MS),
	})(NEVER).pipe(
		map(() => (chunkFriendMap: ChunkFriendMap) => {
			chunkFriendMap.forEach((chunk, chunkId) => {
				if (chunk.updatedAt < Date.now() - LIFETIME_MS) {
					chunkFriendMap.delete(chunkId);
				}
			});
			return chunkFriendMap;
		}),
	);

	const blockUpdateStream = blockStream.pipe(
		map((blockEvent) => (chunkFriendMap: ChunkFriendMap) => {
			chunkFriendMap.forEach((chunk) => {
				chunk.friends = chunk.friends.filter((friendPin) => friendPin.friend.uuid !== blockEvent.uuid);
			});
			return chunkFriendMap;
		}),
	);

	return merge(checkExpiredStream, newChunksStream, blockUpdateStream).pipe(
		scan((map, update) => update(map), new Map() as ChunkFriendMap),
	);
}
