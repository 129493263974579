import { useUpdateAlert } from "@lib/feature/alert/notificationContext";
import { FeedItem } from "@lib/models";
import { useTranslation } from "next-i18next";
import { useEffect, useState } from "react";
import * as feedModel from "./feedModel";

export default function useFeedItems() {
	const { t } = useTranslation("common");
	const updateAlert = useUpdateAlert();
	const [feed, setFeed] = useState<{ itemsListId: string; feedItems: FeedItem[]; loading: boolean }>({
		itemsListId: "",
		feedItems: [],
		loading: true,
	});

	useEffect(() => {
		const subscription = feedModel.feedStream.subscribe(({ itemsListId, feedItems, loading }) => {
			if (feedItems instanceof feedModel.FeedError) {
				updateAlert(t("unknown_error"));
				return;
			}
			setFeed({ itemsListId, feedItems, loading });
		});
		feedModel.refresh();
		return () => {
			subscription.unsubscribe();
		};
	}, [t, updateAlert]);

	return feed;
}
