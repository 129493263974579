import { TranslatedFieldError } from "@lib/util/formErrors";
import { TFunction } from "next-i18next";
import { FieldMetaState } from "react-final-form";

function errorHelper<T>(t: TFunction, helpMessage: string | null, meta: FieldMetaState<T>) {
	const error = Boolean(meta.error && meta.touched);
	if (error) {
		if (!Array.isArray(meta.error)) {
			throw new Error(`Expected meta.error to be an array of { t: string } objects, got ${JSON.stringify(meta.error)}`);
		}
		const errorMessages = meta.error.map((error: TranslatedFieldError) => t(error.t)).join(" ");
		return {
			error,
			helperText: helpMessage == null ? errorMessages : `${helpMessage} ${errorMessages}`,
		};
	}
	return {
		error,
		helperText: helpMessage ?? "",
	};
}
export default errorHelper;
