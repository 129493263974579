import errorHelper from "@lib/util/errorHelper";
import { TextField } from "@mui/material";
import { useTranslation } from "next-i18next";
import { useId } from "react";
import { Field } from "react-final-form";

export default function ExplanationField() {
	const { t } = useTranslation("common");
	const id = useId();
	return (
		<Field
			name="explanation"
			type="text"
			render={({ input, meta }) => {
				return (
					<TextField
						fullWidth
						id={id}
						type="text"
						autoComplete="off"
						label={t("report_dialog.explanation_label")}
						{...errorHelper(t, t("report_dialog.explanation_helper_text"), meta)}
						{...input}
						disabled={meta.submitting}
					/>
				);
			}}
		/>
	);
}
