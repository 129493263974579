import useUser from "@hooks/useUser";
import { Box } from "@mui/material";
import palette from "@styles/palette";
import { useEffect, useRef } from "react";
import useRebrandGate from "../landing/hooks/useRebrandGate";
import MapView from "./MapView";

export default function GlobalMap({ route }: { route: string }) {
	const user = useUser();
	const wrapperRef = useRef<HTMLDivElement>(null);
	const isMapRoute = route === "/" || route === "/anon-sign-up";
	const rebrandEnabled = useRebrandGate();

	useEffect(() => {
		if (wrapperRef.current == null) {
			return;
		}
		if (isMapRoute) {
			wrapperRef.current.removeAttribute("inert");
			wrapperRef.current.removeAttribute("aria-hidden");
			return;
		}
		wrapperRef.current.setAttribute("inert", "");
		wrapperRef.current.setAttribute("aria-hidden", "true");
	}, [isMapRoute]);

	if (user == null && route !== "/anon-sign-up") {
		return null;
	}

	const extendToBottom = rebrandEnabled ? false : user == null;

	return (
		<Box
			ref={wrapperRef}
			data-testid="map-wrapper"
			sx={(theme) => ({
				position: "absolute",
				top: rebrandEnabled ? 0 : `calc(${theme.spacing(7)} + env(safe-area-inset-top, 0))`,
				[theme.breakpoints.up("sm")]: {
					top: rebrandEnabled ? 0 : `calc(${theme.spacing(8)} + env(safe-area-inset-top, 0))`,
				},
				width: "100%",
				bottom: extendToBottom ? 0 : theme.spacing(7),
				left: isMapRoute ? 0 : -10000,
				flex: "1 1 auto",
				display: "flex",
				flexDirection: "column",
				zIndex: isMapRoute ? 0 : -1,
				visibility: isMapRoute ? undefined : "hidden",
			})}
		>
			<MapView />
			<Box
				data-testid="map-cover"
				sx={{
					position: "absolute",
					top: 0,
					right: 0,
					bottom: 0,
					left: 0,
					backgroundColor: rebrandEnabled ? palette.Surface : "background.default",
					zIndex: isMapRoute ? -1 : 2,
				}}
			/>
		</Box>
	);
}
