import { ShinyAlert } from "@lib/feature/alert/ShinyAlert";
import useAlertState from "@lib/feature/alert/useAlertState";
import FlipCameraAndroidIcon from "@mui/icons-material/FlipCameraAndroid";
import { Box, IconButton } from "@mui/material";
import { useTranslation } from "next-i18next";
import { useState } from "react";
import RecentVideoButton from "./RecentVideoButton";
import RecordButton from "./RecordButton";
import useMediaHandler from "./useMediaHandler";

type CaptureProps = {
	onRecorded: (file: Blob) => void;
	videoUrl: string | null;
	onUsePrevious: () => void;
};

export default function Capture({ onRecorded, videoUrl, onUsePrevious }: CaptureProps) {
	const { t } = useTranslation("common");

	const [cameraDirection, setCameraDirection] = useState<"user" | "environment">("user");
	const [recording, setRecording] = useState(false);

	const [alert, updateAlert] = useAlertState();

	const videoRef = useMediaHandler(cameraDirection, recording, onRecorded, (error) => {
		updateAlert(error.message, "error");
	});

	return (
		<>
			<Box
				component="video"
				ref={videoRef}
				disablePictureInPicture
				muted
				playsInline
				sx={{
					position: "absolute",
					top: "0px",
					bottom: "0px",
					left: "0px",
					right: "0px",
					margin: "auto",
					aspectRatio: "1/1",
					width: "100%",
				}}
			/>
			{alert?.severity === "error" ? null : (
				<Box
					sx={{
						position: "absolute",
						bottom: (theme) => theme.spacing(2.5),
						left: "0px",
						right: "0px",
						display: "grid",
						gridTemplateColumns: "33.3% 33.3% 33.3%",
						justifyItems: "center",
						alignItems: "center",
						height: "90px",
					}}
				>
					<RecentVideoButton videoUrl={videoUrl} onClick={onUsePrevious} />
					<RecordButton recording={recording} onRecord={setRecording} />
					<IconButton
						onClick={() => {
							setCameraDirection((prev) => (prev === "user" ? "environment" : "user"));
						}}
						aria-label={t("video_capture.flip_camera")}
						size="large"
						sx={{
							justifySelf: "right",
							fontSize: "2rem",
							zIndex: "10",
							mr: (theme) => theme.spacing(3),
						}}
					>
						<FlipCameraAndroidIcon fontSize="inherit" />
					</IconButton>
				</Box>
			)}
			{alert == null ? null : (
				<Box
					sx={{
						position: "absolute",
						top: "0px",
						bottom: "0px",
						left: "0px",
						right: "0px",
						zIndex: 5,
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<ShinyAlert
						key={alert.key}
						severity={alert.severity}
						sx={{
							margin: (theme) => theme.spacing(2),
						}}
						variant="filled"
					>
						{alert.children}
					</ShinyAlert>
				</Box>
			)}
		</>
	);
}
