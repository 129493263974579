import { updateLocation } from "@api/profile";
import { Location } from "@lib/models";
import reportError from "@lib/util/reportError";
import { setMixpanelLocation } from "@lib/util/trackAnalytics";
import { useEffect, useRef } from "react";

export const distanceUpdateThreshold = 0.01;

function distanceDiff(prev: Location, next: Location) {
	return Math.max(Math.abs(next.lat - prev.lat), Math.abs(next.lng - prev.lng));
}

export default function useReportLocation(position: Location | null, source: "ip" | "precise" | "default" = "default") {
	const lastLocationRef = useRef<Location | null>(null);
	const lastSource = useRef<"ip" | "precise" | "default" | null>(null);
	useEffect(() => {
		if (position == null) {
			return;
		}
		const lastLocation = lastLocationRef.current;
		const nextLocation = position;
		if (
			lastSource.current != source ||
			lastLocation == null ||
			distanceDiff(lastLocation, nextLocation) > distanceUpdateThreshold
		) {
			setMixpanelLocation(nextLocation);
			updateLocation({ ...nextLocation, source }).catch((error) => {
				reportError(error);
			});
			lastLocationRef.current = nextLocation;
			lastSource.current = source;
		}
	}, [position, source]);
}
