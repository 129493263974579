import { Button } from "@components/Button";
import GlobeIcon from "@components/icons/GlobeIcon";
import useNavModalState from "@hooks/useNavModalState";
import ExploreMode from "@lib/feature/exploreMode";
import { track } from "@lib/util/trackAnalytics";
import { Tooltip } from "@mui/material";
import palette from "@styles/palette";
import { useTranslation } from "next-i18next";
import { useCallback } from "react";

type ExplorePopularCitiesControlSource = "map";
type ExplorePopularCitiesControlType = {
	source: ExplorePopularCitiesControlSource;
	useRebrand?: boolean;
};

export default function ExplorePopularCitiesControl({ source, useRebrand }: ExplorePopularCitiesControlType) {
	const { t } = useTranslation("common");
	const { open: dialogOpen, setOpen: setDialogOpen } = useNavModalState("explore-mode-modal");
	const tooltipTitle = useRebrand
		? t("explore_popular_cities.explore_popular_cities_heading_rebrand")
		: t("explore_popular_cities.explore_popular_cities_heading");

	const onClick = useCallback(() => {
		track("explore_mode_button_tapped", { source });
		setDialogOpen(true);
	}, [setDialogOpen, source]);

	return (
		<>
			<Tooltip arrow placement="left" title={tooltipTitle}>
				<Button
					icon
					variant="secondary ghost"
					aria-label={tooltipTitle}
					onClick={onClick}
					sx={{
						backdropFilter: "blur(4px)",
						backgroundColor: palette.SurfaceContainerOpacity15,
					}}
				>
					<GlobeIcon />
				</Button>
			</Tooltip>
			<ExploreMode
				open={dialogOpen}
				onClose={() => {
					setDialogOpen(false);
				}}
			/>
		</>
	);
}
