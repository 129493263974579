import { useCallback, useState } from "react";
import { RenderableAlertType, updateAlertType } from "./types";

let alertId = 0;

export default function useAlertState(): [RenderableAlertType | null, updateAlertType] {
	const [alert, setAlert] = useState<RenderableAlertType | null>(null);
	const updateAlert: updateAlertType = useCallback((message, severity = "error") => {
		if (message == null) {
			setAlert(null);
		} else {
			alertId += 1;
			setAlert({
				key: alertId,
				severity,
				children: message,
			});
		}
	}, []);
	return [alert, updateAlert];
}
