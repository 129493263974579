import { locationReload } from "@lib/util/locationWrapper";
import makeSessionStorageHelper from "@lib/util/makeSessionStorageHelper";
import { useTranslation } from "next-i18next";
import { useEffect } from "react";

const STORAGE_KEY = "/lib/feature/CheckUpdateFailure";
const RECENT_TIME = 15000; // 15 seconds

const storage = makeSessionStorageHelper<number>(STORAGE_KEY);

function hasRecentReload() {
	const value = storage.get();
	if (typeof value !== "number" || Date.now() - value > RECENT_TIME) {
		return false;
	}
	return true;
}

function setReloadTime() {
	storage.set(Date.now());
}

export default function CheckUpdateFailure() {
	const { t } = useTranslation("common");

	useEffect(() => {
		if (t("brand_name") === "brand_name") {
			if (hasRecentReload()) {
				throw new Error("Already reloaded the page recently based on translation error!");
			}
			setReloadTime();
			locationReload();
		}
	}, [t]);

	return null;
}
