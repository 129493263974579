import { Box } from "@mui/material";
import { ReactNode } from "react";

export default function SwiperZoomTarget({ children, onClick }: { children: ReactNode; onClick?: () => void }) {
	return (
		<Box
			component={onClick == null ? undefined : "button"}
			onClick={onClick}
			className="swiper-zoom-target"
			sx={{
				position: "relative",
				height: "auto",
				width: "100%",
				p: 0,
				border: "none",
				"img, video": {
					objectFit: "contain",
				},
			}}
		>
			{children}
		</Box>
	);
}
