import reportError from "@lib/util/reportError";
import { MessageType, Notification } from "./types";

const storageKey = "/lib/feature/notification/storage";

function parseStorage(): Notification[] {
	const stringValue = localStorage.getItem(storageKey);
	if (stringValue == null) {
		return [];
	}
	try {
		const value = JSON.parse(stringValue);
		if (Array.isArray(value)) {
			return value;
		}
	} catch {
		reportError(new Error(`localStorage parse error: ${storageKey}: '${stringValue}'`));
	}
	localStorage.removeItem(storageKey);
	return [];
}

function writeStorage(notifications: Notification[]) {
	localStorage.setItem(storageKey, JSON.stringify(notifications));
}

export function addNotification(notification: Notification) {
	writeStorage([...parseStorage(), notification]);
}

export function getCurrentNotification() {
	return parseStorage().at(0) ?? null;
}

export function hasNotificationType(messageType: MessageType) {
	return parseStorage().find((notification) => notification.messageType === messageType) != null;
}

export function subscribe(onChange: () => void) {
	function handler(event: StorageEvent) {
		if (event.key === storageKey) {
			onChange();
		}
	}

	window.addEventListener("storage", handler);

	return () => {
		window.removeEventListener("storage", handler);
	};
}

export function releaseCurrentNotification() {
	const cutList = parseStorage().slice(1);
	writeStorage(cutList);
}

export function clearNotificationStorage() {
	writeStorage([]);
}
