import useWindowResize from "@hooks/useWindowResize";
import { UploadPhotoDataType } from "@lib/models";
import assertType from "@lib/util/assertType";
import { createObjectURL, revokeObjectURL } from "@lib/util/objectUrlWrapper";
import reportError from "@lib/util/reportError";
import { Box, Button } from "@mui/material";
import { useTranslation } from "next-i18next";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import ReactCrop, { PixelCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import CropImageChild from "./CropImageChild";

const minimumImageSize = 400;

type CropperViewProps = {
	inputFile: File;
	onCancel: () => void;
	onCropFinish: (uploadData: UploadPhotoDataType) => void;
	cropButtonLabel: ReactNode;
};

export function adjustCrop(image: HTMLImageElement, crop: PixelCrop) {
	const { width, height } = image.getBoundingClientRect();
	const xScaler = image.naturalWidth / width;
	const yScaler = image.naturalHeight / height;
	// because images can be displayed slightly squished, we need to get
	// the smaller scaler to use. In one dimension a couple pixels may be cut
	// off, but the opposite solution would require calculating the aspect
	// ratio difference and modifying the crop view to use a non-square.
	const scaler = Math.min(xScaler, yScaler);

	return {
		x: Math.floor(crop.x * scaler),
		y: Math.floor(crop.y * scaler),
		width: Math.min(Math.floor(crop.width * scaler), image.naturalWidth),
		height: Math.min(Math.floor(crop.height * scaler), image.naturalHeight),
	};
}

export default function CropperView({ inputFile, onCancel, onCropFinish, cropButtonLabel }: CropperViewProps) {
	const { t } = useTranslation("common");
	const [pictureBox, setPictureBox] = useState<HTMLDivElement | null>(null);
	const [imageEl, setImageEl] = useState<HTMLImageElement | null>(null);

	const windowSizeId = useWindowResize();

	const [crop, setCrop] = useState<PixelCrop>();

	const [thinnerImage, portraitImage, minCrop] = useMemo(() => {
		if (imageEl == null || pictureBox == null || windowSizeId == null) {
			return [null, null, null, null];
		}
		const boundBox = pictureBox.getBoundingClientRect();
		const boxAspect = boundBox.width / boundBox.height;
		const imageAspect = imageEl.naturalWidth / imageEl.naturalHeight;
		const portraitImage = imageAspect < 1;
		const minCrop = Math.ceil((minimumImageSize / imageEl.naturalWidth) * imageEl.getBoundingClientRect().width);
		return [boxAspect > imageAspect, portraitImage, minCrop];
	}, [pictureBox, imageEl, windowSizeId]);

	const makeMaxCrop = useCallback(() => {
		if (portraitImage != null && imageEl != null && windowSizeId != null) {
			const { width, height } = imageEl.getBoundingClientRect();
			if (portraitImage) {
				const offset = (height - width) / 2;
				return {
					unit: "px" as const,
					x: 0,
					y: offset,
					width: width,
					height: width,
				};
			} else {
				const offset = (width - height) / 2;
				return {
					unit: "px" as const,
					x: offset,
					y: 0,
					width: height,
					height: height,
				};
			}
		}
		throw new Error("Shouldn't call makeMaxCrop when needed values aren't available");
	}, [portraitImage, imageEl, windowSizeId]);

	useEffect(() => {
		if (portraitImage != null && imageEl != null && windowSizeId != null) {
			setCrop(makeMaxCrop());
		}
	}, [portraitImage, imageEl, windowSizeId, makeMaxCrop]);

	const [nextImageUrl, setNextImageUrl] = useState<string | undefined>(undefined);
	useEffect(() => {
		const url = createObjectURL(inputFile);
		setNextImageUrl(url);
		return () => {
			setNextImageUrl(undefined);
			revokeObjectURL(url);
		};
	}, [inputFile]);

	const boxRefHandler = useCallback((el: HTMLDivElement | null) => {
		setPictureBox(el);
	}, []);

	return (
		<>
			<Box
				ref={boxRefHandler}
				sx={{
					flex: "1 1 auto",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					height: "100%",
					mx: "30px",
					maxHeight: "calc(100% - 58.25px)",
					"& .ReactCrop": {
						width: "auto",
					},
				}}
			>
				<ReactCrop
					crop={crop}
					onChange={setCrop}
					aspect={1}
					minWidth={minCrop ?? undefined}
					minHeight={minCrop ?? undefined}
				>
					<CropImageChild
						sx={{
							height: thinnerImage ? `${pictureBox?.offsetHeight}px` : "auto",
							width: thinnerImage ? "auto" : `${pictureBox?.offsetWidth}px`,
						}}
						onLoad={(event) => {
							setImageEl(event.target as HTMLImageElement);
						}}
						alt={t("photo_input.crop_preview_photo_alt")}
						src={nextImageUrl}
					/>
				</ReactCrop>
			</Box>
			<Box
				sx={(theme) => ({
					flex: "0 0 auto",
					display: "flex",
					justifyContent: "flex-start",
					alignItems: "center",
					gap: theme.spacing(1),
					padding: theme.spacing(1),
				})}
			>
				<Button
					variant="outlined"
					onClick={() => {
						onCancel();
					}}
				>
					{t("cancel_button")}
				</Button>
				<Button
					variant="contained"
					onClick={() => {
						const image = assertType(imageEl);
						const pixelCrop = assertType(crop);
						let adjustedCrop = adjustCrop(image, pixelCrop);
						if (adjustedCrop.width < 400 || adjustedCrop.height < 400) {
							adjustedCrop = adjustCrop(image, makeMaxCrop());
						}
						if (
							adjustedCrop.x < 0 ||
							adjustedCrop.y < 0 ||
							adjustedCrop.x + adjustedCrop.width > image.naturalWidth ||
							adjustedCrop.y + adjustedCrop.height > image.naturalHeight
						) {
							const boundingBox = image.getBoundingClientRect();
							const cropOutOfBounds = new Error(`Crop out of bounds!
crop: x: ${adjustedCrop.x}, y: ${adjustedCrop.y}, width: ${adjustedCrop.width}, height: ${adjustedCrop.height}, endX: ${
								adjustedCrop.x + adjustedCrop.width
							}, endY: ${adjustedCrop.y + adjustedCrop.height}
image: naturalWidth: ${image.naturalWidth}, naturalHeight: ${image.naturalHeight}, boundingWidth: ${
								boundingBox.width
							}, boundingHeight: ${boundingBox.height}
window: screen.width: ${window.screen.width}, screen.height: ${window.screen.height}
maxCrop: ${JSON.stringify(makeMaxCrop())}
`);
							reportError(cropOutOfBounds);
						}
						onCropFinish({
							file: inputFile,
							...adjustedCrop,
						});
					}}
				>
					{cropButtonLabel}
				</Button>
			</Box>
		</>
	);
}
