import { AnyAccount, PresenceType, UserShort } from "@lib/models";
import { isAfter, isBefore, subHours, subMinutes } from "date-fns";

export function getOnlineStatus(account: AnyAccount | UserShort): PresenceType {
	const lastActiveDate = new Date(account.last_active);
	const now = Date.now();
	const thirtyMinutesAgo = subMinutes(now, 30);
	const sixHoursAgo = subHours(now, 6);
	const fifteenMinutesAgo = subMinutes(now, 15);
	const isBlank = account.display_name == "";

	if (isBlank && isBefore(lastActiveDate, fifteenMinutesAgo)) {
		return "offline";
	} else if (isAfter(lastActiveDate, thirtyMinutesAgo)) {
		return "online";
	} else if (isAfter(lastActiveDate, sixHoursAgo)) {
		return "idle";
	} else if (account.is_breadcrumb) {
		return "away";
	}
	return "offline";
}
