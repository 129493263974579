import { indicateLoading } from "@components/GlobalLoader";
import { useEffect } from "react";

export default function useGlobalLoader(isLoading: boolean, debugId: string) {
	useEffect(() => {
		if (isLoading) {
			return indicateLoading(debugId);
		}
	}, [isLoading, debugId]);
}

export function ShowGlobalLoader() {
	useGlobalLoader(true, "WrappedGlobalLoader");
	return null;
}
