import ITMCBadge from "@components/ITMCBadge";
import { InTheMomentStatus } from "@lib/models";
import { useTranslation } from "next-i18next";
import { memo, useEffect, useState } from "react";

const BADGE_TIME_LIMIT_15_MIN = 1000 * 60 * 15;

type NewContentBadgeProps = {
	itmc: InTheMomentStatus;
};

export default memo(function NewContentBadge({ itmc }: NewContentBadgeProps) {
	const { t } = useTranslation("common");
	const [showBadge, setShowBadge] = useState(
		() => Number(new Date(itmc.created_at)) + BADGE_TIME_LIMIT_15_MIN > Date.now(),
	);

	useEffect(() => {
		if (showBadge) {
			const showTime = Number(new Date(itmc.created_at)) + BADGE_TIME_LIMIT_15_MIN - Date.now();
			const timeoutId = setTimeout(() => {
				setShowBadge(false);
			}, showTime);

			return () => {
				clearTimeout(timeoutId);
			};
		}
	}, [showBadge, itmc]);

	if (!showBadge) {
		return null;
	}
	return <ITMCBadge>{t("feed.new_content_badge")}</ITMCBadge>;
});
