import MapProfileAvatar from "@components/MapProfileAvatar";
import FriendProfileDialog from "@components/ProfileDialog/FriendProfileDialog";
import { UserShort } from "@lib/models";

export default function ProfilePin({ friend }: { friend: UserShort }) {
	return (
		<FriendProfileDialog
			renderOpener={(onOpen) => <MapProfileAvatar account={friend} onClick={onOpen} />}
			uuid={friend.uuid}
			hideChatButton={false}
			onBlock={() => undefined}
			source="map"
		/>
	);
}
