import { useLocaleWithCountry } from "@hooks/useLocale";
import { useTranslation } from "next-i18next";
import InlineListItem from "./InlineListItem";

type RelationshipStatusProps = {
	relationships: string[];
};

export default function RelationshipStatus({ relationships }: RelationshipStatusProps) {
	const { t } = useTranslation("common");
	const locale = useLocaleWithCountry();
	const formatter = new Intl.ListFormat(locale, { style: "narrow", type: "conjunction" });
	const relationshipStatus = formatter.format(
		relationships.map((relationship) => t(`relationship_options.${relationship}`)),
	);
	return <InlineListItem label={t("profile.relationships_field_label")} value={relationshipStatus} />;
}
