import SnackbarProvider from "@lib/feature/alert/SnackbarProvider";
import ITMReply from "@lib/feature/inTheMoment/ITMReply";
import { AnyAccount, ProfileType } from "@lib/models";
import { Box } from "@mui/material";
import InTheMomentContentMessage from "./InTheMomentContentMessage";
import { InTheMomentContentTimestamp } from "./InTheMomentContentTimestamp";
import { PhotoRotatorState } from "./usePhotoRotatorState";

type ITMCOverlayProps = {
	account: AnyAccount;
	photoRotatorState: PhotoRotatorState;
	profileType: ProfileType;
};
export default function ITMCOverlay({ account, photoRotatorState, profileType }: ITMCOverlayProps) {
	const itmContent = account.in_the_moment_status;

	if (itmContent == null) {
		return null;
	}

	return (
		<>
			{(itmContent.full == null && itmContent.video == null) ||
			photoRotatorState?.forwardMediaType === "profile" ? null : (
				<InTheMomentContentTimestamp createdAt={itmContent.created_at} />
			)}
			<Box
				sx={(theme) => ({
					position: "absolute",
					bottom: 0,
					display: "flex",
					justifyContent: "space-between",
					alignItems: "end",
					p: theme.spacing(2.5),
					width: "100%",
				})}
			>
				{itmContent.message === "" ? <div></div> : <InTheMomentContentMessage inTheMomentStatus={itmContent.message} />}
				{profileType === "user" ||
				(photoRotatorState?.forwardMediaType === "profile" && itmContent.message === "") ? null : (
					<SnackbarProvider>
						<ITMReply profile={account} />
					</SnackbarProvider>
				)}
			</Box>
		</>
	);
}
