import CheckSquareIcon from "@components/icons/CheckSquareIcon";
import StopLineIcon from "@components/icons/StopLineIcon";
import { ThemeOptions } from "@mui/material/styles";
import palette from "@styles/palette";
import { createElement } from "react";

const darkThemeOptions: ThemeOptions = {
	typography: {
		fontFamily: "var(--main-font)",
	},
	iconSize: {
		small: "36px",
		medium: "40px",
		large: "56px",
	},
	palette: {
		mode: "dark",
		secondary: {
			main: "#FA0002",
			light: "#FC7376",
			dark: "#DC0006",
		},
		text: {
			primary: "#FFFFFF",
			secondary: "#FFFFFFB2",
			disabled: "#FFFFFF80",
		},
		error: {
			main: palette.Error,
			light: palette.Error,
			dark: palette.Error,
			contrastText: palette.onSurface,
		},
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: {
				":root": Object.fromEntries(Object.entries(palette).map(([property, value]) => [`--${property}`, value])),
			},
		},
		MuiBottomNavigationAction: {
			styleOverrides: {
				root: ({ theme }) => ({
					"&.Mui-selected": {
						color: theme.palette.common.white,
					},
				}),
			},
		},
		MuiButton: {
			defaultProps: {
				size: "large",
			},
			styleOverrides: {
				root: ({ theme }) => ({
					textTransform: "unset",
					"&.MuiButton-contained": {
						color: theme.palette.common.black,
						backgroundColor: theme.palette.common.white,
						"&:hover": {
							backgroundColor: theme.palette.text.secondary,
						},
						"&.Mui-disabled": {
							color: theme.palette.text.disabled,
							backgroundColor: theme.palette.text.disabled,
						},
					},
					"&.MuiButton-containedSecondary": {
						backgroundColor: theme.palette.secondary.main,
					},
					"&.MuiButton-outlined": {
						color: theme.palette.text.primary,
						border: `1px solid ${theme.palette.common.white}`,
						"&.Mui-disabled": {
							color: theme.palette.text.disabled,
							border: `1px solid ${theme.palette.text.disabled}`,
						},
					},
					"&.MuiButton-text": {
						color: theme.palette.text.primary,
						"&[disabled]": { color: theme.palette.text.disabled },
					},
				}),
			},
		},
		MuiCheckbox: {
			defaultProps: {
				icon: createElement(StopLineIcon),
				checkedIcon: createElement(CheckSquareIcon),
			},
			styleOverrides: {
				root: ({ theme }) => ({
					"&.Mui-checked": {
						color: theme.palette.common.white,
					},
				}),
			},
		},
		MuiChip: {
			styleOverrides: {
				root: ({ theme }) => ({
					"&.MuiChip-filledPrimary": {
						backgroundColor: theme.palette.common.white,
						"&:hover": {
							backgroundColor: theme.palette.text.secondary,
						},
					},
				}),
			},
		},
		MuiCircularProgress: {
			styleOverrides: {
				root: ({ theme }) => ({
					color: theme.palette.common.white,
				}),
			},
		},
		MuiDialog: {
			styleOverrides: {
				root: ({ theme, ownerState }) => ({
					"& .MuiDialog-paper": {
						margin: ownerState.fullScreen ? undefined : theme.spacing(2.5),
						width: "100%",
					},
				}),
			},
		},
		MuiIconButton: {
			defaultProps: {
				size: "large",
			},
			styleOverrides: {
				root: ({ theme }) => ({
					"&.MuiIconButton-colorSecondary": {
						backgroundColor: theme.palette.secondary.main,
						"&:hover": {
							backgroundColor: theme.palette.secondary.dark,
						},
					},
				}),
			},
		},
		MuiLink: {
			styleOverrides: {
				root: {
					color: palette.Primary,
					textDecoration: "none",
					textDecorationColor: palette.Primary,
					":hover": {
						textDecoration: "underline",
					},
				},
			},
		},
		MuiList: {
			styleOverrides: {
				root: {
					"& hr.MuiDivider-root": {
						margin: 0,
					},
				},
			},
		},
		MuiMenu: {
			defaultProps: {
				slotProps: {
					paper: {
						square: true,
						elevation: 0,
						sx: () => ({
							backgroundColor: palette.SurfaceContainerMid,
							backgroundImage: "unset",
						}),
					},
				},
			},
			styleOverrides: {
				list: { padding: 0, border: `solid 1px ${palette.onSurface}` },
			},
		},
		MuiMenuItem: {
			styleOverrides: {
				root: ({ theme }) => ({
					minHeight: theme.spacing(5),
					fontSize: "14px",
					padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
					gap: theme.spacing(1.5),
					svg: {
						height: theme.spacing(2),
						width: theme.spacing(2),
					},
				}),
			},
		},
		MuiTextField: {
			styleOverrides: {
				root: () => ({
					"[type='date']": {
						"::-webkit-calendar-picker-indicator": {
							filter: "invert(1)",
						},
					},
				}),
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: () => ({
					borderRadius: 0,
				}),
			},
		},
	},
};

export default darkThemeOptions;
