import { FriendPin } from "@lib/feature/map/pin";
import { OtherUser, ReportSource, UserShort } from "@lib/models";
import getPreloadThumbnailUrl from "@lib/util/getPreloadThumbnailUrl";
import { AxiosError } from "axios";
import { deleteRequest, get, post } from "../httpClient";
import { NotFoundError, ServerFormError } from "./commonErrors";

export const usersPath = "/users";

export type GetUsersResponse = (UserShort & { lat: number; lng: number })[];

export async function getUsers(path: string): Promise<FriendPin[]> {
	const users: GetUsersResponse = await get(path);
	return users.map((user) => {
		const url = getPreloadThumbnailUrl(user);
		if (url != null) {
			const image = new Image();
			image.src = url;
		}
		return new FriendPin(
			{
				id: user.uuid,
				lat: user.lat,
				lng: user.lng,
			},
			user,
		);
	});
}

export const otherUserPath = "/users";

export async function getOtherUser(path: string): Promise<OtherUser> {
	return get(path);
}

type BlockUserRequest = {
	block: { uuid: string };
};

export async function blockUser(body: BlockUserRequest): Promise<void> {
	return post<BlockUserRequest, void>("/blocks", body).catch((error) => {
		if (error instanceof AxiosError && error.response?.status === 422) {
			if (typeof error.response.data === "object") {
				throw new ServerFormError(error.response.data);
			}
		}
		throw error;
	});
}

type UnblockUserRequest = {
	block: { uuid: string };
};

export async function unblockUser(body: UnblockUserRequest): Promise<void> {
	return deleteRequest<UnblockUserRequest, void>("/blocks", body).catch((error) => {
		if (error instanceof AxiosError && error.response?.status === 404) {
			throw new NotFoundError();
		}
		if (error instanceof AxiosError && error.response?.status === 422) {
			if (typeof error.response.data === "object") {
				throw new ServerFormError(error.response.data);
			}
		}
		throw error;
	});
}

type ReportUserRequest = {
	report: {
		uuid: string;
		reasons: string[];
		explanation: string;
		block: boolean;
		source: ReportSource;
	};
};

export async function reportUser(body: ReportUserRequest): Promise<undefined> {
	return post<ReportUserRequest, undefined>("/reports", body).catch((error) => {
		if (error instanceof AxiosError && error.response?.status === 422) {
			if (typeof error.response.data === "object") {
				throw new ServerFormError(error.response.data);
			}
		}
		throw error;
	});
}

export const chatFriendPath = "/chat_channels";

type ChatFriendResponse = {
	channel_id: string;
	user: OtherUser;
};

export async function getChatFriend(pathWithUuid: string): Promise<ChatFriendResponse> {
	return await get<unknown, ChatFriendResponse>(pathWithUuid);
}

export const webPushSubscriptionPath = "/web_push_subscriptions";
type WebPushSubscriptionRequest = { endpoint: string; p256dh: string; auth: string };
export async function createWebPushSubscription(body: WebPushSubscriptionRequest) {
	return post<WebPushSubscriptionRequest, void>(webPushSubscriptionPath, body);
}

export async function statusReply(uuid: string, message: string) {
	return post<{ status_reply: { message: string } }, void>(`/users/${uuid}/status_reply`, {
		status_reply: { message: message },
	});
}
