export class InputInvalid extends Error {}
export class NotFoundError extends Error {}

type ServerFormErrorBody = {
	error: string;
	fields?: Record<string, string[]>;
};

export class ServerFormError extends Error {
	data;
	constructor(data: ServerFormErrorBody) {
		super(data.error ?? `Server Form Error with ${Array.from(Object.keys(data)).length}`);
		this.data = data;
	}
}
