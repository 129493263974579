import useAuth from "@hooks/useAuth";
import useUser from "@hooks/useUser";
import RegisterAnonModal from "@lib/feature/anon/RegisterAnonModal";
import useBrazilReleaseGate from "@lib/feature/landing/hooks/useBrazilReleaseGate";
import { MenuItem } from "@mui/material";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { useRouter } from "next/router";
import AnonExitButton from "./AnonExitButton";
import AppMenuFeedbackLink from "./AppMenuFeedbackLink";
import AppMenuLocationRandomization from "./AppMenuLocationRandomization";
import AppMenuNSFWSwitch from "./AppMenuNSFWSwitch";
import AppMenuSupportLink from "./AppMenuSupportLink";

export default function AppMenuOptions({ setMenuOpen }: { setMenuOpen: (value: boolean) => void }) {
	const { t } = useTranslation("common");
	const { onLogout } = useAuth();
	const user = useUser();
	const router = useRouter();
	const useBrazilRelease = useBrazilReleaseGate();

	const nsfwSwitchMenuItem = (
		<AppMenuNSFWSwitch
			key="nsfw_switch"
			onClose={() => {
				setMenuOpen(false);
			}}
		/>
	);

	const locationRandomizationMenuItem = useBrazilRelease ? (
		<AppMenuLocationRandomization
			key="location_randomization"
			onClose={() => {
				setMenuOpen(false);
			}}
		/>
	) : null;

	if (user?.is_anon) {
		return [
			locationRandomizationMenuItem,
			nsfwSwitchMenuItem,
			<RegisterAnonModal
				key="register-account-button"
				renderButton={(onOpen) => (
					<MenuItem
						component="span"
						onClick={() => {
							setMenuOpen(false);
							onOpen();
						}}
					>
						{t("app_bar.register_account_button")}
					</MenuItem>
				)}
			/>,
			<AppMenuSupportLink key="support-link" onClose={() => setMenuOpen(false)} />,
			<AnonExitButton
				key="anon-exit-button"
				onClose={() => {
					setMenuOpen(false);
				}}
			/>,
		];
	}

	return [
		locationRandomizationMenuItem,
		nsfwSwitchMenuItem,
		router.pathname === "/" ? null : (
			<MenuItem
				component={Link}
				href="/"
				key="/"
				onClick={() => {
					setMenuOpen(false);
				}}
			>
				{t("nav.map_link")}
			</MenuItem>
		),
		router.pathname === "/account/profile" ? null : (
			<MenuItem
				component={Link}
				href="/account/profile"
				key="/account/profile"
				onClick={() => {
					setMenuOpen(false);
				}}
			>
				{t("app_bar.edit_profile_button")}
			</MenuItem>
		),
		router.pathname === "/account/settings" ? null : (
			<MenuItem
				component={Link}
				href="/account/settings"
				key="/account/settings"
				onClick={() => {
					setMenuOpen(false);
				}}
			>
				{t("app_bar.account_settings_button")}
			</MenuItem>
		),
		<AppMenuSupportLink key="app-menu-support-link" onClose={() => setMenuOpen(false)} />,
		<AppMenuFeedbackLink key="app-menu-feedback-link" onClose={() => setMenuOpen(false)} />,
		<MenuItem
			component="span"
			key="logout-button"
			onClick={() => {
				setMenuOpen(false);
				onLogout();
			}}
		>
			{t("logout_button_label")}
		</MenuItem>,
	];
}
