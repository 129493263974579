import Distance from "@components/Distance";
import usePresence from "@hooks/usePresence";
import useRebrandGate from "@lib/feature/landing/hooks/useRebrandGate";
import { AnyAccount, PresenceType } from "@lib/models";
import getStatusColor from "@lib/util/getStatusColor";
import getStatusColorRebrand from "@lib/util/getStatusColorRebrand";
import { Theme, Typography, styled } from "@mui/material";
import { Box } from "@mui/system";
import palette from "@styles/palette";
import { useTranslation } from "next-i18next";

function getStatusDotStyles(onlineStatus: PresenceType, theme: Theme, rebrandEnabled: boolean) {
	const colorMap = rebrandEnabled
		? {
				online: { color: palette.indicatorActive, backgroundColor: palette.indicatorActive },
				idle: { color: palette.OnSurfaceSecondary, backgroundColor: palette.OnSurfaceSecondary },
				away: { color: palette.OnSurfaceSecondary, backgroundColor: palette.OnSurfaceSecondary },
				offline: { border: `${palette.OnSurfaceSecondary} 1px solid` },
			}
		: {
				online: { color: theme.palette.success.main, backgroundColor: theme.palette.success.main },
				idle: { color: theme.palette.warning.main, backgroundColor: theme.palette.warning.main },
				away: { color: theme.palette.info.main, backgroundColor: theme.palette.info.main },
				offline: { border: `${theme.palette.common.white} 1px solid` },
			};

	return colorMap[onlineStatus];
}

const Dot = styled("span", {
	shouldForwardProp: (prop) => prop !== "onlineStatus",
})<{ onlineStatus: PresenceType }>(({ onlineStatus, theme }) => {
	const rebrandEnabled = useRebrandGate();
	if (rebrandEnabled && onlineStatus !== "online") {
		return null;
	}

	return {
		...getStatusDotStyles(onlineStatus, theme, rebrandEnabled),
		width: "8px",
		height: "8px",
		marginRight: "10px",
		borderRadius: "4px",
	};
});

export default function OnlineStatus({ account }: { account: AnyAccount }) {
	const { t } = useTranslation("common");
	const accountPresence = usePresence(account);
	const rebrandEnabled = useRebrandGate();
	const statusMessage: string = rebrandEnabled
		? t(`presence.presence_${accountPresence}_rebrand`)
		: t(`presence.presence_${accountPresence}`);

	return (
		<Box sx={{ display: "flex", alignItems: "center", m: 0, mt: 1.5 }} component="p">
			<Dot onlineStatus={accountPresence} />
			<Typography
				component="span"
				sx={(theme) => {
					if (rebrandEnabled) {
						return {
							color: getStatusColorRebrand(accountPresence),
							mr: theme.spacing(3),
							fontSize: "12px",
							fontWeight: 500,
						};
					} else {
						return {
							color: getStatusColor(accountPresence)(theme),
							mr: theme.spacing(1),
						};
					}
				}}
			>
				{statusMessage}
			</Typography>
			<Distance account={account} presence={accountPresence} />
		</Box>
	);
}
