import { Videocam } from "@mui/icons-material";
import { Box, LinearProgress, Typography } from "@mui/material";
import { useTranslation } from "next-i18next";
import { CurrentVideoPlaceholder } from "./CurrentMediaElement";

export default function ProcessingVideo() {
	const { t } = useTranslation("common");

	return (
		<CurrentVideoPlaceholder
			sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "flex-end",
				alignItems: "center",
				margin: "0 auto 8px",
				bgcolor: "text.disabled",
			}}
		>
			<Videocam fontSize="large" />
			<Typography sx={{ textAlign: "center", fontSize: "16px" }}>
				{t("in_the_moment.video_loading_placeholder")}
			</Typography>
			<Box
				sx={(theme) => ({
					width: "100%",
					mt: theme.spacing(2),
					px: theme.spacing(1),
					height: theme.spacing(4.5),
					background: "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.70) 100%)",
					display: "flex",
					alignItems: "center",
				})}
			>
				<LinearProgress data-testid="video-upload-progress" sx={{ width: "100%", height: "6px" }} color="secondary" />
			</Box>
		</CurrentVideoPlaceholder>
	);
}
