// tested in ITMCOverlayRebrand.test.tsx
import ChevronDownIcon from "@components/icons/ChevronDownIcon";
import ChevronUpIcon from "@components/icons/ChevronUpIcon";
import { AnyAccount } from "@lib/models";
import { Box, Typography } from "@mui/material";
import palette from "@styles/palette";
import { useTranslation } from "next-i18next";
import { useEffect, useMemo, useRef, useState } from "react";
import { ITMCTimestamp } from "./ITMCTimestamp";

const SINGLE_ROW = 29;
const DOUBLE_ROW = 44;
const OVERLAY_MESSAGE_PADDING_Y = 5;

type ITMCTimestampAndMessageProps = {
	account: AnyAccount;
	showTimestamp: boolean;
};

export default function ITMCTimestampAndMessage({ account, showTimestamp }: ITMCTimestampAndMessageProps) {
	const [open, setOpen] = useState(false);
	const [height, setHeight] = useState(0);
	const [showButton, setShowButton] = useState(false);
	const { t } = useTranslation("common");

	const itmContent = account.in_the_moment_status;
	const itmcMessage = itmContent?.message;

	const maxHeight = useMemo(() => {
		return height == null ? 0 : height > SINGLE_ROW ? DOUBLE_ROW : SINGLE_ROW;
	}, [height]);

	const messageRef = useRef<HTMLParagraphElement>(null);

	useEffect(() => {
		if (messageRef?.current != null) {
			const newStatusHeight = messageRef.current?.clientHeight;
			if (newStatusHeight > DOUBLE_ROW) {
				setShowButton(true);
			} else {
				setShowButton(false);
			}
			setHeight(newStatusHeight);
		}
	}, [itmcMessage]);

	if (itmContent == null) {
		return null;
	}

	const firstRow = showTimestamp ? "auto" : "";
	return (
		<Box
			sx={{
				display: "grid",
				gridTemplateRows:
					height > 0 && open
						? `${firstRow} ${height + OVERLAY_MESSAGE_PADDING_Y * 2}px`
						: height > 0
							? `${firstRow} ${maxHeight}px`
							: `${firstRow} 0px`,
				gridTemplateColumns: "1fr",
				alignContent: "end",
				transition: "grid-template-rows 0.3s ease-out",
			}}
		>
			{showTimestamp ? <ITMCTimestamp createdAt={itmContent.created_at} /> : null}
			{itmcMessage !== "" ? (
				<Box
					onClick={showButton ? () => setOpen((prev) => !prev) : undefined}
					sx={(theme) => ({
						backgroundColor: palette.Surface,
						border: "none",
						color: palette.onSurface,
						px: theme.spacing(2),
						py: `${OVERLAY_MESSAGE_PADDING_Y}px`,
						display: "flex",
						justifyContent: "space-between",
						alignItems: "start",
						gap: theme.spacing(1.25),
						overflow: "hidden",
						width: "100%",
					})}
				>
					<Typography ref={messageRef} sx={{ fontSize: "12px", textAlign: "start" }}>
						<span className="sr-only">{t("profile.in_the_moment_status_screen_reader_label")}</span> {itmcMessage}
					</Typography>
					{showButton ? (
						<Box
							component="button"
							sx={{ background: "none", border: "none", color: palette.onSurface, padding: 0 }}
							aria-label={open ? t("hide_button") : t("more_button")}
						>
							{open ? <ChevronDownIcon /> : <ChevronUpIcon />}
						</Box>
					) : null}
				</Box>
			) : null}
		</Box>
	);
}
