import { useLocaleWithCountry } from "@hooks/useLocale";
import useTimeSinceITMCreated from "@hooks/useTimeSinceITMCreated";
import getTimeDistance from "@lib/util/getTimeDistance";
import BoltIcon from "@mui/icons-material/Bolt";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useMemo } from "react";

export function InTheMomentContentTimestamp({ createdAt }: { createdAt: string }) {
	const locale = useLocaleWithCountry();
	const timeSinceCreation: number = useTimeSinceITMCreated(createdAt);
	const timeDistance = useMemo(() => {
		return getTimeDistance(timeSinceCreation, locale);
	}, [timeSinceCreation, locale]);

	if (timeDistance == null) {
		return null;
	}

	return (
		<Box
			sx={(theme) => ({
				color: theme.palette.text.primary,
				backgroundColor: theme.palette.common.black,
				borderRadius: "100px",
				py: theme.spacing(0.5),
				pl: theme.spacing(1.5),
				pr: theme.spacing(0.5),
				display: "flex",
				alignItems: "center",
				position: "absolute",
				top: theme.spacing(2.5),
				right: theme.spacing(2.5),
			})}
		>
			<Typography variant="body2">{timeDistance}</Typography>
			<BoltIcon fontSize="medium" aria-hidden />
		</Box>
	);
}
