import { blockUser as blockUserApi } from "@api/users";
import CardContent from "@components/CardContent";
import CloseButton from "@components/CloseButton";
import useDisplayName from "@hooks/useDisplayName";
import useIdify from "@hooks/useIdify";
import { ShinyAlert } from "@lib/feature/alert/ShinyAlert";
import useAlertState from "@lib/feature/alert/useAlertState";
import { blockUser } from "@lib/feature/blocking";
import { UserShort } from "@lib/models";
import reportError from "@lib/util/reportError";
import { Button, Card, CardActions, CardHeader, Dialog, Typography } from "@mui/material";
import { useTranslation } from "next-i18next";
import { useState } from "react";

type BlockFriendDialogProps = {
	open: boolean;
	onClose: () => void;
	profile: UserShort;
	onBlock: (successMessage: string) => void;
};
export default function BlockFriendDialog({ open, onClose, profile, onBlock }: BlockFriendDialogProps) {
	const { t } = useTranslation();
	const idify = useIdify();
	const [alert, updateAlert] = useAlertState();
	const [loading, setLoading] = useState(false);
	const displayName = useDisplayName(profile);

	async function handleBlock() {
		setLoading(true);
		try {
			await blockUserApi({ block: { uuid: profile.uuid } });
			blockUser(profile.uuid);
			onClose();
		} catch (error) {
			reportError(error as Error);
			updateAlert(t("unknown_error"));
			return;
		} finally {
			setLoading(false);
		}
		onBlock(t("block_dialog.success_message"));
	}

	return (
		<Dialog
			open={open}
			sx={{
				"& .MuiDialog-paper": {
					width: "100%",
				},
			}}
			aria-labelledby={idify("dialog_heading")}
		>
			<Card elevation={0} sx={{ overflow: "auto" }}>
				<CloseButton onClick={onClose} />
				<CardHeader
					sx={{ paddingBottom: 0 }}
					titleTypographyProps={{ component: "h2", id: idify("dialog_heading") }}
					subheader={t("block_dialog.subheader")}
					subheaderTypographyProps={{ variant: "body2" }}
					title={t("block_dialog.header")}
				/>
				<CardContent>
					<Typography color="text.secondary" variant="body2">{`${t(
						"block_dialog.profile_name",
					)}: ${displayName}`}</Typography>
					<Typography color="text.secondary" variant="body2">
						{t("block_dialog.body")}
					</Typography>
					{alert == null ? undefined : (
						<ShinyAlert
							key={alert.key}
							severity={alert.severity}
							variant="filled"
							onClose={() => {
								updateAlert();
							}}
						>
							{alert.children}
						</ShinyAlert>
					)}
				</CardContent>
				<CardActions sx={{ paddingLeft: 2, paddingBottom: 3 }}>
					<Button variant="contained" sx={{ marginRight: "auto" }} onClick={handleBlock} disabled={loading}>
						{t("block_button")}
					</Button>
				</CardActions>
			</Card>
		</Dialog>
	);
}
