import { AnyAccount } from "@lib/models";

const allMatchableCategories = [
	"my_roles",
	"my_identities",
	"my_traits",
	"looking_for_roles",
	"looking_for_identities",
	"looking_for_traits",
	"scenarios",
	"into",
	"kinks",
	"languages_spoken",
] as const;

export type MatchableCategory = (typeof allMatchableCategories)[number];

export type TagMatchMap = Record<MatchableCategory, string[]>;

export function isMatchableCategory(interest: keyof AnyAccount): interest is MatchableCategory {
	return allMatchableCategories.includes(interest as MatchableCategory);
}

const comparableCategories: Record<MatchableCategory, MatchableCategory> = {
	my_roles: "looking_for_roles",
	my_identities: "looking_for_identities",
	my_traits: "looking_for_traits",
	looking_for_roles: "my_roles",
	looking_for_identities: "my_identities",
	looking_for_traits: "my_traits",
	scenarios: "scenarios",
	into: "into",
	kinks: "kinks",
	languages_spoken: "languages_spoken",
};

export default function getMatchingFriendCategories(user: AnyAccount | undefined, friend: AnyAccount) {
	const matchMap: TagMatchMap = {
		my_roles: [],
		my_identities: [],
		my_traits: [],
		looking_for_roles: [],
		looking_for_identities: [],
		looking_for_traits: [],
		scenarios: [],
		into: [],
		kinks: [],
		languages_spoken: [],
	};
	if (user == null || user.uuid === friend.uuid) {
		return matchMap;
	}
	allMatchableCategories.forEach((category) => {
		const correspondingCategory = comparableCategories[category];
		matchMap[category] = friend[category].filter((interest) => user[correspondingCategory].includes(interest));
	});
	return matchMap;
}
