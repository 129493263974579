import palette from "@styles/palette";

export type Variant =
	| "primary"
	| "primary ghost"
	| "secondary"
	| "secondary ghost"
	| "tertiary"
	| "tertiary ghost"
	| "quaternary";

export type VariantStyle = {
	backgroundColor: string;
	borderColor: string;
	color: string;

	hoverBG: string | undefined;
	hoverBorderColor: string | undefined;
	hoverColor: string | undefined;

	active: {
		backgroundColor: string;
		borderColor: string;
		color: string;

		hoverBG: string | undefined;
		hoverBorderColor: string | undefined;
		hoverColor: string | undefined;
	};

	disabledColor: string | undefined;
	disabledBorder: boolean;
	disabledTransparency: boolean;
};

export const variantStyles: Record<Variant, VariantStyle> = {
	primary: {
		backgroundColor: palette.Primary,
		borderColor: "none",
		color: palette.onPrimary,

		hoverBG: palette.Primary,
		hoverBorderColor: palette.onPrimary,
		hoverColor: undefined,

		active: {
			backgroundColor: palette.Primary,
			borderColor: "none",
			color: palette.Surface,

			hoverBG: undefined,
			hoverBorderColor: palette.onPrimary,
			hoverColor: palette.onPrimary,
		},

		disabledColor: palette.OnSurfaceSecondary,
		disabledBorder: true,
		disabledTransparency: false,
	},
	secondary: {
		backgroundColor: palette.Secondary,
		borderColor: "none",
		color: palette.onSecondary,

		hoverBG: undefined,
		hoverBorderColor: palette.Primary,
		hoverColor: palette.Primary,

		active: {
			backgroundColor: palette.Primary,
			borderColor: "none",
			color: palette.onPrimary,

			hoverBG: palette.Primary,
			hoverBorderColor: undefined,
			hoverColor: palette.Surface,
		},

		disabledColor: palette.OnSurfaceSecondary,
		disabledBorder: true,
		disabledTransparency: false,
	},
	tertiary: {
		backgroundColor: "transparent",
		borderColor: palette.Primary,
		color: palette.Primary,

		hoverBG: palette.Primary,
		hoverBorderColor: palette.Secondary,
		hoverColor: palette.onPrimary,

		active: {
			backgroundColor: palette.Primary,
			borderColor: "none",
			color: palette.Surface,

			hoverBG: undefined,
			hoverBorderColor: "none",
			hoverColor: palette.onPrimary,
		},

		disabledColor: palette.OnSurfaceSecondary,
		disabledBorder: true,
		disabledTransparency: false,
	},
	quaternary: {
		backgroundColor: "transparent",
		borderColor: palette.Secondary,
		color: palette.Secondary,

		hoverBG: palette.Primary,
		hoverBorderColor: palette.Secondary,
		hoverColor: palette.Secondary,

		active: {
			backgroundColor: palette.Primary,
			borderColor: "none",
			color: palette.Surface,

			hoverBG: undefined,
			hoverBorderColor: "none",
			hoverColor: palette.Secondary,
		},

		disabledColor: palette.OnSurfaceSecondary,
		disabledBorder: true,
		disabledTransparency: false,
	},
	"primary ghost": {
		backgroundColor: "transparent",
		borderColor: "none",
		color: palette.Primary,

		hoverBG: undefined,
		hoverBorderColor: palette.Secondary,
		hoverColor: undefined,

		active: {
			backgroundColor: "transparent",
			borderColor: palette.Primary,
			color: palette.Primary,

			hoverBG: palette.PrimaryOpacity25,
			hoverBorderColor: undefined,
			hoverColor: undefined,
		},

		disabledColor: palette.OnSurfaceSecondary,
		disabledBorder: false,
		disabledTransparency: false,
	},
	"secondary ghost": {
		backgroundColor: "transparent",
		borderColor: "none",
		color: palette.Secondary,

		hoverBG: undefined,
		hoverBorderColor: palette.Primary,
		hoverColor: undefined,

		active: {
			backgroundColor: "transparent",
			borderColor: palette.Secondary,
			color: palette.Secondary,

			hoverBG: palette.Secondary25,
			hoverBorderColor: undefined,
			hoverColor: undefined,
		},

		disabledColor: palette.OnSurfaceSecondary,
		disabledBorder: false,
		disabledTransparency: false,
	},
	"tertiary ghost": {
		backgroundColor: "transparent",
		borderColor: "none",
		color: palette.OnSurfaceSecondary,

		hoverBG: undefined,
		hoverBorderColor: palette.OnSurfaceSecondary,
		hoverColor: undefined,

		active: {
			backgroundColor: "transparent",
			borderColor: palette.OnSurfaceSecondary,
			color: palette.Secondary,

			hoverBG: palette.OnSurfaceSecondary25,
			hoverBorderColor: undefined,
			hoverColor: undefined,
		},

		disabledColor: palette.OnSurfaceSecondary,
		disabledBorder: false,
		disabledTransparency: true,
	},
};

export type SizeVariant = "xl" | "lg" | "md" | "sm" | "xs" | "xxs" | "xxxs";

export const sizeVariants: Record<
	SizeVariant,
	{
		paddingX: number; // used for left/right in regular buttons
		height: number; // used for regular button height
		iconButtonSize: number; // icon width and height
		fontSize: string; // text font size
		gap: number; // space between button children
		iconSize: number; // svg icon height/width in regular buttons
		iconButtonIconSize: number; // svg icon height/width in icon buttons
	}
> = {
	xl: {
		paddingX: 0, // There is no XL regular button!
		height: 0,
		iconButtonSize: 8.5,
		fontSize: "16px",
		gap: 0,
		iconSize: 0,
		iconButtonIconSize: 4,
	},
	lg: {
		paddingX: 3,
		height: 7,
		iconButtonSize: 7,
		fontSize: "16px",
		gap: 1.25,
		iconSize: 2.5,
		iconButtonIconSize: 3,
	},
	md: {
		paddingX: 2.5,
		height: 5.5,
		iconButtonSize: 5.5,
		fontSize: "16px",
		gap: 1.25,
		iconSize: 2.5,
		iconButtonIconSize: 2.5,
	},
	sm: {
		paddingX: 2.5,
		height: 5,
		iconButtonSize: 5,
		fontSize: "14px",
		gap: 1,
		iconSize: 2,
		iconButtonIconSize: 2.5,
	},
	xs: {
		paddingX: 2,
		height: 4.5,
		iconButtonSize: 4.5,
		fontSize: "14px",
		gap: 1,
		iconSize: 2,
		iconButtonIconSize: 2.5,
	},
	xxs: {
		paddingX: 1.5,
		height: 4,
		iconButtonSize: 4,
		fontSize: "14px",
		gap: 0.75,
		iconSize: 2,
		iconButtonIconSize: 2,
	},
	xxxs: {
		paddingX: 0.25,
		height: 2,
		iconButtonSize: 0, // There is no XXXS icon button!
		fontSize: "12px",
		gap: 0.5,
		iconSize: 1.5,
		iconButtonIconSize: 0, // There is no XXXS icon button!
	},
};
