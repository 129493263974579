import { SvgIcon, SvgIconProps } from "@mui/material";

export default function ChevronDownIcon(props: SvgIconProps) {
	return (
		<SvgIcon data-testid="BKDRChevronDownIcon" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12 16.4144L12.7071 15.7073L18.7071 9.70726L19.4142 9.00015L18 7.58594L17.2929 8.29304L12 13.5859L6.70714 8.29304L6.00003 7.58594L4.58582 9.00015L5.29292 9.70726L11.2929 15.7073L12 16.4144Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
}
