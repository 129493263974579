import useRebrandGate from "@lib/feature/landing/hooks/useRebrandGate";
import checkForMobile from "@lib/util/checkForMobile";
import { Avatar as AvatarBase, AvatarProps, IconButton, styled, Theme } from "@mui/material";
import palette from "@styles/palette";
import { ReactElement } from "react";
import NoteBadge from "./NoteBadge";
import OnlineStatusBadge from "./OnlineStatusBadge";
import VideoBadge from "./VideoBadge";

const avatarSizes: Record<AvatarPinSizeOption, { desktop: string; mobile: string }> = {
	"x-small": {
		desktop: "56px",
		mobile: "32px",
	},
	small: {
		desktop: "56px",
		mobile: "40px",
	},
	medium: {
		desktop: "64px",
		mobile: "56px",
	},
	large: {
		desktop: "80px",
		mobile: "64px",
	},
};

function getAvatarSize(avatarSize: AvatarPinSizeOption, rebrandEnabled: boolean) {
	const deviceType = checkForMobile() ? "mobile" : "desktop";
	// the only pin size difference in the rebrand is that 'large' 'mobile' is 72px
	if (!rebrandEnabled && deviceType === "mobile" && avatarSize === "large") {
		return "72px";
	}
	return avatarSizes[avatarSize][deviceType];
}

type CustomAvatarProps = { desaturate: boolean; highlight: boolean };
type StyledAvatarProps = AvatarProps & CustomAvatarProps;

const Avatar = styled(AvatarBase, {
	shouldForwardProp: (prop: string) => !["desaturate", "highlight"].includes(prop as string),
})<StyledAvatarProps>(({ theme, desaturate, highlight }: { theme: Theme } & CustomAvatarProps) => ({
	filter: desaturate ? "grayscale(100%)" : undefined,
	border: highlight ? `1px solid ${desaturate ? theme.palette.grey[400] : theme.palette.secondary.main}` : undefined,
	boxShadow: highlight ? `0px 0px 24px ${theme.palette.secondary.main}` : undefined,
}));

const AvatarRebrand = styled(AvatarBase, {
	shouldForwardProp: (prop: string) => !["desaturate", "highlight"].includes(prop as string),
})<StyledAvatarProps>(({ desaturate, highlight, theme }: { theme: Theme } & CustomAvatarProps) => ({
	filter: desaturate ? "grayscale(100%)" : undefined,
	border: `1px solid ${highlight ? palette.Primary : palette.surfaceContainer}`,
	backgroundColor: palette.SurfaceContainerMidOpacity15,
	backdropFilter: `blur(${theme.spacing(6)})`,
}));

export type AvatarPinSizeOption = "x-small" | "small" | "medium" | "large";
export type AvatarPinBadgeOption = "online" | "idle" | "breadcrumb" | "none";

export type AvatarPinProps = {
	size: AvatarPinSizeOption;
	badge: AvatarPinBadgeOption;
	videoBadge: boolean;
	highlight: boolean;
	desaturate: boolean;
	note: string | null;
	SRLabel: string;
	media: ReactElement | undefined;
	onClick: (() => void) | undefined;
};

export default function AvatarPin({
	size,
	badge,
	videoBadge,
	highlight,
	desaturate,
	note,
	SRLabel,
	media,
	onClick,
}: AvatarPinProps) {
	const rebrandEnabled = useRebrandGate();
	const avatarSize = getAvatarSize(size, rebrandEnabled);
	const pinContents = (
		<VideoBadge videoBadge={videoBadge} desaturate={desaturate}>
			<OnlineStatusBadge badge={badge}>
				{rebrandEnabled ? (
					<AvatarRebrand
						variant="square"
						desaturate={desaturate}
						highlight={highlight}
						sx={{ width: avatarSize, height: avatarSize }}
					>
						{media}
					</AvatarRebrand>
				) : (
					<Avatar
						variant={"circular"}
						desaturate={desaturate}
						highlight={highlight}
						sx={{ width: avatarSize, height: avatarSize }}
					>
						{media}
					</Avatar>
				)}
				{note == null ? null : <NoteBadge>{note}</NoteBadge>}
			</OnlineStatusBadge>
		</VideoBadge>
	);

	if (onClick == null) {
		return pinContents;
	}

	return (
		<IconButton onClick={onClick} aria-label={SRLabel} sx={{ width: "fit-content" }}>
			{pinContents}
		</IconButton>
	);
}
