import { useMediaQuery } from "@mui/material";

export const maxMobileWidth = 844;
export const maxTabletWidth = 1044;

export type Viewport = "mobile" | "tablet_landscape" | "tablet_portrait" | "desktop";

export default function useViewportSize(): Viewport {
	const isLandscape = useMediaQuery("@media (min-aspect-ratio: 1/1)");
	const isTablet = useMediaQuery(`@media (min-width: ${maxMobileWidth}px) and (max-width: ${maxTabletWidth}px)`);
	const isMobile = useMediaQuery(`@media (max-width: ${maxMobileWidth}px)`);

	if (isMobile === true) {
		return "mobile";
	}
	if (isTablet === true) {
		if (isLandscape) {
			return "tablet_landscape";
		}
		return "tablet_portrait";
	}
	return "desktop";
}
