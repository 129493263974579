import reportError from "@lib/util/reportError";
import { Component, ReactNode } from "react";
import ErrorPage from "./ErrorPage";

type OuterErrorBoundaryProps = {
	children: ReactNode;
};

type OuterErrorBoundaryState = {
	hasError: boolean;
};

export default class OuterErrorBoundary extends Component<OuterErrorBoundaryProps, OuterErrorBoundaryState> {
	state: OuterErrorBoundaryState = { hasError: false };

	static getDerivedStateFromError(/* error: Error */) {
		return { hasError: true };
	}

	componentDidCatch(error: Error /*, errorInfo: ErrorInfo */): void {
		reportError(error);
	}

	render() {
		if (this.state.hasError) {
			return <ErrorPage />;
		}
		return this.props.children;
	}
}
