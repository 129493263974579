import { SvgIcon, SvgIconProps } from "@mui/material";

export default function XSquareIcon(props: SvgIconProps) {
	return (
		<SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} data-testid="BKDRXSquareIcon">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M22 2H2V22H22V2ZM12.0002 10.5858L9.00015 7.58582L7.58594 9.00003L10.5859 12L7.58594 15L9.00015 16.4142L12.0002 13.4142L15.0002 16.4142L16.4144 15L13.4144 12L16.4144 9.00003L15.0002 7.58582L12.0002 10.5858Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
}
