import { Button } from "@components/Button";
import Link from "@components/Link";
import CheckSquareIcon from "@components/icons/CheckSquareIcon";
import vcrOsdMono from "@lib/fonts/vcrOsdMono";
import { Box, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import palette from "@styles/palette";
import { Trans, useTranslation } from "next-i18next";

const externalLinks = [
	// it's not that kind of array
	// eslint-disable-next-line react/jsx-key
	<Link href="https://www.perrystreet.com/privacy" />,
	// eslint-disable-next-line react/jsx-key
	<Link href="https://www.perrystreet.com/tos" />,
];

type ModalContentProps = {
	onClose: () => void;
};

export default function ModalContent({ onClose }: ModalContentProps) {
	const { t } = useTranslation("common");

	return (
		<Box
			sx={{
				width: "100%",
				maxWidth: "800px",
				margin: "auto",
			}}
		>
			<DialogTitle sx={{ fontFamily: vcrOsdMono.style.fontFamily }}>
				{t("privacy_check.drawer_heading_rebrand")}
			</DialogTitle>
			<DialogContent
				sx={(theme) => ({
					display: "flex",
					flexDirection: "column",
					gap: theme.spacing(2),
					pb: theme.spacing(2),
				})}
			>
				<Typography
					sx={{
						fontSize: "14px",
					}}
				>
					{t("privacy_check.drawer_body")}
				</Typography>
				<Typography sx={{ fontSize: "13px", color: palette.Primary }}>
					<Trans t={t} i18nKey="landing.links" components={externalLinks} />
				</Typography>
			</DialogContent>
			<DialogActions sx={{ display: "flex", alignItems: "stretch", flexDirection: "column" }}>
				<Button size="md" variant="primary" onClick={onClose}>
					<CheckSquareIcon sx={{ color: palette.onPrimary }} />
					{t("accept_button")}
				</Button>
			</DialogActions>
		</Box>
	);
}
