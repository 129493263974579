import { useEffect, useState } from "react";

export default function useObjectUrl(blob?: Blob | null) {
	const [outputUrl, setOutputUrl] = useState<string | null>(null);

	useEffect(() => {
		if (blob != null) {
			const url = URL.createObjectURL(blob);
			setOutputUrl(url);
			return () => {
				setOutputUrl(null);
				URL.revokeObjectURL(url);
			};
		}
	}, [blob]);

	return blob == null ? null : outputUrl;
}
