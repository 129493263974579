import ArrowUpward from "@mui/icons-material/ArrowUpward";
import { Button } from "@mui/material";
import { useTranslation } from "next-i18next";
import { useEffect, useMemo, useState } from "react";
import useChannel from "../realtimeHandling/useChannel";
import * as feedModel from "./feedModel";

export default function NewFeedItemsIndicator() {
	const { t } = useTranslation("common");
	const [radius, setRadius] = useState(50);
	const [visible, setVisible] = useState(false);

	useEffect(() => {
		const subscription = feedModel.radiusBehavior.subscribe(setRadius);
		return () => {
			subscription.unsubscribe();
		};
	}, []);

	useEffect(() => {
		const subscription = feedModel.feedStream.subscribe(() => {
			setVisible(false);
		});
		return () => {
			subscription.unsubscribe();
		};
	}, []);

	const channelConfig = useMemo(() => ({ channel: "FeedChannel" as const, radius }), [radius]);
	useChannel(channelConfig, () => {
		setVisible(true);
	});

	if (!visible) {
		return null;
	}

	return (
		<Button
			variant="contained"
			color="secondary"
			size="small"
			startIcon={<ArrowUpward />}
			sx={(theme) => ({
				position: "absolute",
				top: theme.spacing(9.5),
				left: "50%",
				transform: "translateX(-50%)",
				zIndex: "fab",
			})}
			onClick={() => {
				feedModel.refresh();
			}}
		>
			{t("feed.new_posts_button")}
		</Button>
	);
}
