import { useCallback, useId } from "react";

export default function useIdify() {
	const idBase = useId();
	return useCallback(
		(label: string) => {
			return `${idBase}_${label}`;
		},
		[idBase],
	);
}
