import InformationSquareIcon from "@components/icons/InformationSquareIcon";
import { Box, Typography } from "@mui/material";
import palette from "@styles/palette";
import { useTranslation } from "next-i18next";

export default function NoFeedItemsPlaceholder() {
	const { t } = useTranslation("common");
	return (
		<Box
			component="ol"
			sx={{
				m: 0,
				p: 0,
				flex: "1 1 auto",
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<Box
				component="li"
				sx={(theme) => ({
					listStyle: "none",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					gap: theme.spacing(1.5),
				})}
			>
				<InformationSquareIcon
					sx={(theme) => ({
						color: palette.Primary,
						width: theme.spacing(6.5),
						height: theme.spacing(6.5),
					})}
				/>
				<Typography component="h2" variant="h6" sx={{ fontFamily: "var(--heading-font)" }}>
					{t("feed.no_content_title")}
				</Typography>
				<Typography variant="body2">{t("feed.no_content_body")}</Typography>
			</Box>
		</Box>
	);
}
